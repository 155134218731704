import { DateTime } from "luxon";

class DateTimeService {
  modifyDate(date) {
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDate();
    return this.createJSDate(year, month, day);
  }

  createJSDate(year, month, day) {
    return this.createDate(year, month, day).toJSDate();
  }

  createDate(year, month, day) {
    return DateTime.utc(year, month + 1, day);
  }
}

export default new DateTimeService();
