import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Toolbar } from "primereact/toolbar";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { L } from "../../lib/abpUtility";
import registrationService from "../../services/registration/registrationService";
import { showFields } from "./registrationTableConfig";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { RegistrationContext } from "../../context/Registration/registrationContext";
import { useQuery } from "react-query";
import { EventContext } from "../../context/Event/eventContext";
import { InputNumber } from "primereact/inputnumber";
import { errorRef } from "../../App";
import storageService from "../../services/storage/storageService";
import { InputSwitch } from "primereact/inputswitch";

let abp = window.abp;

export const RegistrationTable = () => {
  const { lastEvent } = useContext(EventContext);
  const [keyword, setKeyword] = useState("");
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(1);
  const [filters, setFilters] = useState({});
  const [preFilters, setPreFilters] = useState({});
  const [dataRangeFilter, setDataRangeFilter] = useState(null);
  const {
    data,
    isLoading: loading,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: [
      "registrations",
      first,
      rows,
      keyword,
      filters,
      dataRangeFilter,
      { sortField, sortOrder },
    ],
    queryFn: () =>
      registrationService.getAll({
        SkipCount: first,
        MaxResultCount: rows,
        Sorting:
          sortField &&
          sortOrder !== 0 &&
          `Person.${sortField} ${sortOrder === -1 ? "DESC" : "ASC"}`,
        Keyword: keyword,
        "Filter.FromEventDay": dataRangeFilter?.[0],
        "Filter.ToEventDay": dataRangeFilter?.[1],
        ...filters,
      }),
  });

  let filterTimer;

  const { data: paymentTypesItems } = useQuery({
    queryKey: ["paymentTypes"],
    queryFn: () => registrationService.getPaymentTypes(),
  });

  let paymentTypes =
    paymentTypesItems?.map((i) => {
      return {
        ...i,
        text: L(i.text),
      };
    }) || [];

  let { data: activitiesItems } = useQuery({
    queryKey: ["activities"],
    queryFn: () => registrationService.getActivities(),
  });

  let activities = activitiesItems
    ? activitiesItems?.map((a) => ({ ...a, label: L(a.text) }))
    : [];

  const { data: daysData } = useQuery({
    queryKey: ["days", lastEvent?.id],
    queryFn: () => lastEvent && registrationService.getDays(lastEvent),
  });

  let days = daysData ? daysData : [];

  const { data: groupLeadersItems } = useQuery({
    queryKey: ["groupLeaders"],
    enabled: abp.auth.hasPermission("Pages.Payments.Pay") ? true : false,
    queryFn: () => registrationService.getAllGroupLeaders(),
  });

  let groupLeaders = groupLeadersItems?.items || [];

  const registrations = data ? data.items : [];
  const totalCount = data ? data.totalCount : 0;

  const [showTableFields, setShowTableFields] = useState(
    showFields.filter((fld) => !fld.hidden)
  );
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [totalDueSelection, setTotalDueSelection] = useState(0);
  const [payRegistrationDialogVisible, setPayRegistrationDialogVisible] =
    useState(false);
  const [managedPayRegistrationDialogId, setManagedPayRegistrationDialogId] =
    useState(null);
  useContext(RegistrationContext);
  const navigate = useNavigate();
  let timer;

  useEffect(() => {
    let locStorageFilters = JSON.parse(localStorage.getItem("filters"));
    let locStorageDataRangeFilter = JSON.parse(
      localStorage.getItem("dataRangeFilter")
    );
    if (locStorageFilters) {
      setFilters(locStorageFilters);
      setPreFilters(locStorageFilters);
    }
    if (locStorageDataRangeFilter) {
      setDataRangeFilter(locStorageDataRangeFilter);
    }

    let locStorageFields = JSON.parse(localStorage.getItem("showFields"));

    if (locStorageFields?.length > 0) {
      let fields = showFields.filter((i) =>
        locStorageFields?.find((e) => e?.field === i.field)
      );
      setShowTableFields(fields);
    } else {
      setShowTableFields(showFields.filter((fld) => !fld.hidden));
    }
  }, []);

  useEffect(() => {
    clearTimeout(filterTimer);

    filterTimer = setTimeout(() => {
      setFilters(preFilters);
    }, 300);

    return () => {
      clearTimeout(filterTimer);
    };
  }, [preFilters]);

  useEffect(() => {
    let newSelectedData = [];

    if (selectedData?.[0]) {
      selectedData.forEach((s) => {
        let newSelected = registrations?.find((r) => r.id === s.id);
        if (newSelected) {
          newSelectedData.push(newSelected);
        }
      });
    }
    setSelectedData(newSelectedData);
  }, [registrations]);

  useEffect(() => {
    let price = calculateTotalDue(selectedData);
    setTotalDueSelection(price);
  }, [selectedData]);

  useEffect(() => {
    storageService.setItem("filters", JSON.stringify(filters));
    storageService.setItem("dataRangeFilter", JSON.stringify(dataRangeFilter));
  }, [filters, dataRangeFilter]);

  const handlePageChange = (e) => {
    setFirst(e.first);
    setRows(e.rows);
  };

  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = showFields.filter((col) =>
      selectedColumns.some((sCol) => sCol.field === col.field)
    );
    setShowTableFields(orderedSelectedColumns);
    storageService.setItem(
      "showFields",
      JSON.stringify(
        orderedSelectedColumns.map((col) => {
          return { field: col.field };
        })
      )
    );
  };

  const onChangeFilter = (key, value) => {
    setPreFilters({ ...preFilters, [key]: value });
  };

  const openDeleteDialog = (rowData) => {
    setSelectedData([rowData]);
    setShowDeleteDialog(true);
  };

  const deleteRegistration = (data) => {
    registrationService.deleteRegistrations({ id: data?.id }).then(() => {
      refetch();
      setShowDeleteDialog(false);
    });
  };

  const DayButton = ({ day, data }) => {
    let currentDay = data.days.find((i) => i.date === day.date);
    let selected = selectedData?.[0]
      ? selectedData.find((i) => i?.id === data?.id)
      : selectedData?.id === data?.id;

    const info = !currentDay
      ? {
          icon: "fa fa-xmark",
          color: "secondary",
          outlined: selected,
          style: {
            backgroundColor: selected && "#FCFCFC",
            color: selected && "#607D8B",
          },
        }
      : currentDay?.accommodation?.id === 1
      ? {
          icon: "fa fa-check",
          color: "info",
          outlined: true,
        }
      : currentDay?.presence?.id === 2
      ? {
          icon: "fa fa-check",
          color: "danger",
          outlined: true,
        }
      : {
          icon: "fa fa-check",
          color: "success",
          outlined: true,
        };
    return (
      <Button
        className={`p-button p-button-rounded p-button-${info.color} ${
          !info?.outlined && "p-button-outlined"
        }`}
        style={info.style}
        label={new Date(day.date).getDate()}
      ></Button>
    );
  };

  const daysButtonsBody = (rowData) => {
    return (
      <div style={{ display: "flex", gap: 5, flexWrap: "wrap" }}>
        {days?.map((i) => (
          <DayButton day={i} key={i.date} data={rowData} />
        ))}
      </div>
    );
  };

  const deleteDialogFooter = (
    <>
      <Button
        label={L("No")}
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => setShowDeleteDialog(false)}
      />
      <Button
        label={L("Yes")}
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => {
          deleteRegistration(selectedData?.[0]);
          setShowDeleteDialog(false);
        }}
      />
    </>
  );

  const handleGlobalFilterChange = (e) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      setKeyword(e);
    }, 300);
  };
  const monthNavigatorTemplate = (e) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        style={{ lineHeight: 1 }}
      />
    );
  };
  const yearNavigatorTemplate = (e) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        className="p-ml-2"
        style={{ lineHeight: 1 }}
      />
    );
  };

  const handleClearFiltersClicked = () => {
    setFilters({});
    setPreFilters({});
    setDataRangeFilter(null);
    setShowTableFields(showFields.filter((fld) => !fld.hidden));
    storageService.removeItem("showFields");
    storageService.removeItem("filters");
    storageService.removeItem("dataRangeFilter");
    window.location.reload();
  };

  const calculateTotalDue = (data) => {
    if (data?.[0]) {
      let price = data?.reduce(
        (accumulator, current) =>
          accumulator + parseFloat(current.totalDue?.replace(",", ".")),
        0
      );
      return price;
    }
  };

  const handleSort = (e) => {
    setSortOrder(e?.sortOrder);
    setSortField(e.sortOrder === 0 ? null : e?.sortField);
    refetch();
  };

  const header = (
    <div className="table-header col-12">
      <h5 className="p-mx-0 p-my-1 col-12">
        {L("Manage") + " " + L("Registrations")}
      </h5>
      <div className="table-header-container">
        <div
          className="flex"
          style={{
            gap: "5px",
            flexWrap: "wrap",
          }}
        >
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e) => handleGlobalFilterChange(e.target.value)}
              placeholder={L("Search") + "..."}
            />
          </span>
          {abp.auth.hasPermission("Pages.Payments.Pay") && (
            <MultiSelect
              value={preFilters["Filter.GroupLeadersId"]}
              options={groupLeaders}
              optionLabel="fullName"
              placeholder={L("GroupLeader")}
              filter
              showSelectAll={false}
              optionValue="userId"
              onChange={(e) =>
                onChangeFilter("Filter.GroupLeadersId", e.target.value)
              }
            />
          )}

          {abp.auth.hasPermission("Pages.Payments.Pay") && (
            <MultiSelect
              value={preFilters["Filter.PersonActivities"]}
              options={activities}
              optionLabel="label"
              placeholder={L("Volunteering")}
              showSelectAll={false}
              optionValue="text"
              onChange={(e) =>
                onChangeFilter("Filter.PersonActivities", e.target.value)
              }
            />
          )}

          {/* <Calendar
            placeholder={L("DateRange")}
            selectionMode="range"
            value={dataRangeFilter}
            onChange={(e) => setDataRangeFilter(e.value)}
          /> */}

          <MultiSelect
            value={showTableFields.map((i) => {
              return { ...i, header: L(i.header) };
            })}
            options={showFields.map((i) => {
              return { ...i, header: L(i.header) };
            })}
            optionLabel="header"
            onChange={onColumnToggle}
          />
          <Button
            className="p-button p-button-secondary ml-2"
            label={L("ClearFilters")}
            onClick={handleClearFiltersClicked}
          ></Button>
        </div>
      </div>
      <Divider />
      <div className="table-header-container">
        <div
          className="flex"
          style={{
            gap: "5px",
            flexWrap: "wrap",
          }}
        >
          {showTableFields.find((i) => i.field === "firstName") && (
            <InputText
              placeholder={L("Name")}
              value={preFilters["Filter.FirstName"]}
              onChange={(e) =>
                onChangeFilter("Filter.FirstName", e.target.value)
              }
            />
          )}
          {showTableFields.find((i) => i.field === "lastName") && (
            <InputText
              placeholder={L("Surname")}
              value={preFilters["Filter.LastName"]}
              onChange={(e) =>
                onChangeFilter("Filter.LastName", e.target.value)
              }
            />
          )}

          {showTableFields.find((i) => i.field === "city") && (
            <InputText
              placeholder={L("City")}
              value={preFilters["Filter.City"]}
              onChange={(e) => onChangeFilter("Filter.City", e.target.value)}
            />
          )}
          {showTableFields.find((i) => i.field === "church") && (
            <InputText
              placeholder={L("Church")}
              value={preFilters["Filter.Church"]}
              onChange={(e) => onChangeFilter("Filter.Church", e.target.value)}
            />
          )}
          {showTableFields.find((i) => i.field === "address") && (
            <InputText
              placeholder={L("Address")}
              value={preFilters["Filter.Address"]}
              onChange={(e) => onChangeFilter("Filter.Address", e.target.value)}
            />
          )}
          {showTableFields.find((i) => i.field === "licensePlate") && (
            <InputText
              placeholder={L("LicensePlate")}
              value={preFilters["Filter.LicensePlate"]}
              onChange={(e) =>
                onChangeFilter("Filter.LicensePlate", e.target.value)
              }
            />
          )}
          {showTableFields.find((i) => i.field === "phoneNumber") && (
            <InputText
              placeholder={L("PhoneNumber")}
              value={preFilters["Filter.PhoneNumber"]}
              onChange={(e) =>
                onChangeFilter("Filter.PhoneNumber", e.target.value)
              }
            />
          )}
          {showTableFields.find((i) => i.field === "ucn") && (
            <InputText
              placeholder={L("UCN")}
              value={preFilters["Filter.Ucn"]}
              onChange={(e) => onChangeFilter("Filter.Ucn", e.target.value)}
            />
          )}
          {showTableFields.find((i) => i.field === "birthDate") && (
            <Calendar
              monthNavigator
              yearNavigator
              monthNavigatorTemplate={monthNavigatorTemplate}
              yearNavigatorTemplate={yearNavigatorTemplate}
              maxDate={new Date()}
              yearRange={"1940:" + new Date().getFullYear()}
              placeholder={L("FromBirthDate")}
              value={preFilters["Filter.FromBirthDate"]}
              onChange={(e) => onChangeFilter("Filter.FromBirthDate", e.value)}
            ></Calendar>
          )}
          {showTableFields.find((i) => i.field === "birthDate") && (
            <Calendar
              monthNavigator
              yearNavigator
              monthNavigatorTemplate={monthNavigatorTemplate}
              yearNavigatorTemplate={yearNavigatorTemplate}
              maxDate={new Date()}
              yearRange={"1940:" + new Date().getFullYear()}
              placeholder={L("ToBirthDate")}
              value={preFilters["Filter.ToBirthDate"]}
              onChange={(e) => onChangeFilter("Filter.ToBirthDate", e.value)}
            ></Calendar>
          )}
        </div>
        {/* <Button
          className="p-button p-button-danger"
          icon="pi pi-times"
          onClick={() => {
            var newFilters = filters;
            Object.keys(newFilters).forEach((key) => {
              newFilters[key] = undefined;
            });
            setFilters(newFilters);
            setKeyword("");
          }}
        />  */}
        {/* Commented untill fixed  */}
      </div>
      {abp.auth.hasPermission("Pages.Payments.Pay") && (
        <div style={{
          display: "flex",
          alignItems: "center",
          gap: "10px"

        }}>
          <InputSwitch
            checked={preFilters["Filter.IsDraft"]}
            onChange={(e) => onChangeFilter("Filter.IsDraft", e.target.value)}
          />
          <span>{L("IsDraft")}</span>
        </div>
      )}
    </div>
  );

  const rightToolbarTemplate = () => {
    return (
      <div>
        {data && selectedData?.length > 1 && (
          <RenderPayRegistrationsMultiple
            data={selectedData}
            totalDueSelection={totalDueSelection}
            paymentTypes={paymentTypes}
            selectedData={selectedData}
            refetch={refetch}
          />
        )}
      </div>
    );
  };

  const leftToolbarTemplate = () => {
    return (
      <div>
        <Button
          label={L("New")}
          icon="pi pi-plus"
          onClick={() => navigate("/registration/step1")}
          className="p-button-success p-mr-2"
        />
      </div>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div
        className="actions"
        style={{ display: "flex", alignItems: "center", gap: "5px" }}
      >
        <Link to={`/registrations/${rowData?.id}/details`}>
          <Button
            icon="fa-solid fa-magnifying-glass"
            className="p-button-rounded p-button-info"
          />
        </Link>
        {(rowData.isProcesseded === false ||
          abp.auth.hasPermission("Pages.Payments.Pay")) && (
          <Button
            icon="pi pi-pencil"
            className="p-button-rounded p-button-success p-mr-2"
            onClick={() => navigate(`/registrations/${rowData?.id}/edit`)}
          />
        )}

        {(rowData.isProcesseded === false ||
          abp.auth.hasPermission("Pages.Payments.Pay")) &&
          !rowData?.isGroupLeader && (
            <Button
              icon={`pi pi-trash`}
              className="p-button-rounded p-button-danger p-mr-2"
              onClick={() => openDeleteDialog(rowData)}
            />
          )}
      </div>
    );
  };

  const RenderPayRegistration = () => {
    const [rowData, setRowData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [paymentType, setPaymentType] = useState(2);
    const [chargePrice, setChargePrice] = useState(0);
    const [chargeComment, setChargeComment] = useState("");

    useEffect(() => {
      let managedRegistration = registrations.find(
        (i) => i.id === managedPayRegistrationDialogId
      );
      setRowData(managedRegistration);
      setChargePrice(parseFloat(managedRegistration?.totalDue));
    }, []);

    const charge = () => {
      setLoading(true);
      registrationService
        .payRegistration({
          registrationId: rowData?.id,
          paymentTypeId: paymentType,
          amount: chargePrice,
          comment: chargeComment,
        })
        .then(() => {
          setPayRegistrationDialogVisible(false);
          setLoading(false);
          errorRef.current.show({
            severity: "success",
            summary: "Success",
            detail: "You have successfully charged the registration",
            life: 4000,
          });
          refetch();
        })
        .catch((e) => {
          errorRef.current.show({
            severity: "error",
            summary: "Error",
            detail: L("ChargeError"),
            life: 4000,
          });
        });
    };

    return (
      <Dialog
        visible={payRegistrationDialogVisible}
        onHide={() => {
          setPayRegistrationDialogVisible(false);
        }}
        style={{ width: "450px" }}
        className="overlaypanel-demo"
        showHeader
        closeOnEscape
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          <InputText
            placeholder={L("Payment") + " " + L("Comment")}
            value={chargeComment}
            onChange={(e) => setChargeComment(e.target.value)}
          />
          <Dropdown
            value={paymentType}
            optionLabel="text"
            optionValue="id"
            options={paymentTypes}
            onChange={(e) => setPaymentType(e.value)}
            placeholder="Method"
          />
          <InputNumber
            value={chargePrice}
            onFocus={(e) => setChargePrice(0)}
            onValueChange={(e) => setChargePrice(e.value)}
            mode="currency"
            currency="BGN"
            locale="bg-BG"
            style={{ width: "min-content" }}
          />
          <Button
            label={L("Payment")}
            className="p-button-help"
            onClick={charge}
            loading={loading}
            disabled={loading}
            style={{ width: "min-content" }}
          />
        </div>
      </Dialog>
    );
  };

  return (
    <div
      className="datatable-crud-demo card crud-demo"
      style={{ maxWidth: "100vw", overflow: "hidden" }}
    >
      {payRegistrationDialogVisible && managedPayRegistrationDialogId && (
        <RenderPayRegistration />
      )}
      <Toolbar
        className="p-mb-4 p-toolbar"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      />
      <DataTable
        value={registrations}
        loading={loading || isFetching}
        rows={rows}
        sortOrder={sortOrder}
        sortField={sortField}
        removableSort
        paginator={true}
        selection={selectedData}
        onSelectionChange={(e) => {
          setSelectedData(e.value);
        }}
        className="datatable-responsive"
        lazy={true}
        first={first}
        totalRecords={totalCount}
        onPage={(e) => handlePageChange(e)}
        currentPageReportTemplate={L("ShowingFirstLast")}
        paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
        onSort={(e) => handleSort(e)}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        dataKey="id"
        header={header}
      >
        <Column
          selectionMode={"multiple"}
          headerStyle={{ width: "3rem" }}
        ></Column>
        {showTableFields.map((field) => (
          <Column
            key={field.field}
            field={field.field}
            sortable={field.sortable}
            header={L(field.header)}
            {...(field.body && { body: field.body })}
            {...field.otherProps}
          />
        ))}
        {showTableFields.find((i) => i.field === "days") && (
          <Column body={daysButtonsBody} header={L("Presence")} />
        )}
        {showTableFields.length > 0 && (
          <Column
            body={actionBodyTemplate}
            header={L("Actions")}
            field="id"
          ></Column>
        )}
        <Column
          body={(rowData) => (
            <Button
              label={L("Amount") + ": " + rowData?.totalDue + ` ${L("BGN")}`}
              className="p-button-help"
              disabled={abp.auth.isGranted("Pages.Dues.Charge") ? false : true}
              onClick={
                abp.auth.isGranted("Pages.Dues.Charge")
                  ? () => {
                      setPayRegistrationDialogVisible(true);
                      setManagedPayRegistrationDialogId(rowData?.id);
                    }
                  : () => {}
              }
            />
          )}
        />
      </DataTable>
      <Dialog
        visible={showDeleteDialog}
        style={{ width: "450px" }}
        header={L("Confirm")}
        modal
        footer={deleteDialogFooter}
        onHide={() => setShowDeleteDialog(false)}
      >
        <div className="confirmation-content flex align-items-center">
          <i
            style={{ fontSize: "20px" }}
            className="pi pi-exclamation-triangle mr-3 p-0"
          />
          <span>{L("DeleteRegistrationMessage")}</span>
        </div>
      </Dialog>
    </div>
  );
};

const RenderPayRegistrationsMultiple = ({
  data,
  totalDueSelection,
  paymentTypes,
  refetch,
  selectedData,
}) => {
  const [loading, setLoading] = useState(false);
  const [paymentType, setPaymentType] = useState(paymentTypes[1]?.id);
  const [chargePrice, setChargePrice] = useState(0);
  const [visible, setVisible] = useState(false);
  const [chargeComment, setChargeComment] = useState("");
  const chargeButtonClick = (e) => {
    setVisible(true);
  };

  useEffect(() => {
    setChargePrice(totalDueSelection);
  }, [totalDueSelection, selectedData, visible]);

  const charge = () => {
    setLoading(true);
    registrationService
      .bulkPay({
        registrationIds: data.map((i) => i.id),
        amount: chargePrice,
        paymentTypeId: paymentType,
        comment: chargeComment,
      })
      .then(() => {
        setVisible(false);
        setLoading(false);
        errorRef.current.show({
          severity: "success",
          summary: "Success",
          detail: "You have successfully charged the registrations",
          life: 4000,
        });
        refetch();
      })
      .catch((e) => {
        errorRef.current.show({
          severity: "error",
          summary: "Error",
          detail: L("ChargeError"),
          life: 4000,
        });
      });
  };

  return (
    <div
      id="buttons"
      className="flex"
      style={{
        gap: "10px",
        alignSelf: "center",
        flexWrap: "wrap",
      }}
    >
      <Button
        label={L("Amount") + ": " + totalDueSelection + ` ${L("BGN")}`}
        className="p-button-help"
        disabled={!abp.auth.isGranted("Pages.Dues.Charge")}
        onClick={
          abp.auth.isGranted("Pages.Dues.Charge") ? chargeButtonClick : () => {}
        }
      />
      <Dialog
        visible={visible}
        onHide={() => {
          setVisible(false);
        }}
        style={{ width: "450px" }}
        className="overlaypanel-demo"
        showHeader
        closeOnEscape
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          <InputText
            placeholder={L("Payment") + " " + L("Comment")}
            value={chargeComment}
            onChange={(e) => setChargeComment(e.target.value)}
          />
          <Dropdown
            value={paymentType}
            optionLabel="text"
            optionValue="id"
            options={paymentTypes}
            onChange={(e) => setPaymentType(e.value)}
            placeholder="Method"
          />
          {selectedData.length > 0 &&
            selectedData.map((i) => (
              <div
                key={i.id}
                style={{
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                }}
              >
                <Button
                  icon="pi pi-user"
                  className="pi-button p-button-outlined p-button-rounded p-button-danger"
                />
                <p style={{ fontSize: "1.1rem" }}>
                  {i.firstName} {i.lastName}
                </p>
              </div>
            ))}
          <InputNumber
            value={chargePrice}
            onFocus={(e) => setChargePrice(null)}
            onValueChange={(e) => setChargePrice(e.value)}
            mode="currency"
            currency="BGN"
            locale="bg-BG"
            style={{ width: "min-content" }}
          />
          <Button
            label={L("Payment")}
            className="p-button-help"
            onClick={charge}
            loading={loading}
            disabled={loading || !paymentType}
            style={{ width: "min-content" }}
          />
        </div>
      </Dialog>
    </div>
  );
};
