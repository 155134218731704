import React from "react";
import { Navigate } from "react-router-dom";
import storageService from "../../services/storage/storageService";

function GuardedRouteStepTwo({ redirectTo, children }) {
  let data = JSON.parse(storageService.getItem("days"));
  return (
    <div>
      {data?.find((day) => {
        const hasValidPresence =
          day.presence &&
          day.presence.id !== null &&
          day.presence.id !== 3 &&
          day.presence.id !== 4 &&
          day.presence.id !== undefined;
        const hasValidAccommodation =
          day.accommodation &&
          day.accommodation.id !== null &&
          day.accommodation.id !== undefined;
        return hasValidPresence || hasValidAccommodation;
      }) !== undefined ? (
        children
      ) : (
        <Navigate to={redirectTo} />
      )}
    </div>
  );
}

export default GuardedRouteStepTwo;
