import React, { useContext, useState } from "react";
import { InputSwitch } from "primereact/inputswitch";
import { ThemeContext } from "../../context/Theme/themeContext";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { L } from "../../lib/abpUtility";
import { AuthenticationContext } from "../../context/Authentication/authenticationContext";
import { Checkbox } from "primereact/checkbox";

function Profile() {
  const { setColorScheme, changeStyleSheetsColor, colorScheme } =
    useContext(ThemeContext);
  const [value, setValue] = useState(colorScheme === "dark");
  const { user } = useContext(AuthenticationContext);

  const handleChange = (value) => {
    if (value) {
      changeStyleSheetsColor("layout-css", "layout-dark.css", 1);
      setColorScheme("dark");
    } else {
      changeStyleSheetsColor("layout-css", "layout-light.css", 1);
      setColorScheme("light");
    }
    setValue(value);
    window.location.reload();
  };

  return (
    <div className="flex align-items-center justify-content-center profile-page">
      <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
        <div>
          <div className="flex align-items-center">
            <h4 htmlFor="email" className="m-0">
              {L("Email")}:{" "}
            </h4>
            <h4 className="m-0 ml-3">{user?.userName}</h4>
          </div>

          {/* <div className="flex align-items-center mt-4">
            <h4 htmlFor="email" className="m-0">
              {L("DarkTheme")}:{" "}
            </h4>
            <Checkbox
              className="ml-3"
              checked={value}
              onChange={(e) => handleChange(e.checked)}
            />
          </div> */}

          <div className="flex flex-wrap align-items-center justify-content-between">
            <Button className="mt-4">
              <Link className="text-white" to="/change-password">
                {L("ChangeYourPassword")}
              </Link>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
