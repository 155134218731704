import http from "../httpService";

class LanguageService {
    async create(createEventInput) {
        let result = await http.post(
            "api/services/app/Content/CreateContent",
            createEventInput
        );
        return result.data.result;
    }

    async update(updateEventInput) {
        let result = await http.put(
            "api/services/app/Content/UpdateContent",
            updateEventInput
        );
        return result.data.result;
    }

    async delete(entityDto) {
        let result = await http.delete("api/services/app/Content/DeleteContent", {
            params: entityDto,
        });
        return result.data;
    }

    async get(entityDto) {
        let result = await http.get("api/services/app/Content/GetContentByPageName", {
            params: entityDto,
        });
        return result.data;
    }
}

export default new LanguageService()