import { useQuery } from "react-query";
import ReportsMenu from "../../components/menus/ReportsMenu";
import reportsService from "../../services/reports/reportsService";
import { useMemo } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { L, capitalizeFirstLetter } from "../../lib/abpUtility";
import {
  RenderProductCorrespondingImage,
  excludedProperties,
} from "../RegistrationDetails/RegistrationDetails";
import { Button } from "primereact/button";
import { Skeleton } from "primereact/skeleton";
import { ProgressSpinner } from "primereact/progressspinner";
import "./shop-reports.scss";

const abp = window.abp;

export const ShopReports = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["reportsRegistrationItems"],
    queryFn: () =>
      reportsService.getAllRegistrationsWithItems({
        HasNoPaging: true,
      }),
  });

  const registrationSingleItems = useMemo(() => {
    return data?.filter((i) => !i.groupLeader).map(i => i.registrationItems).flat() || [];
  }, [data]);

  const registrationGroupItems = useMemo(() => {
    return data?.filter((i) => i.groupLeader) || [];
  }, [data]);

  const numberOfGroupItems = useMemo(() => {
    let items = registrationGroupItems.map((i) => i.registrationItems) || [];

    return items.flat().length;
  }, [registrationGroupItems]);

  if (isLoading) {
    return (
      <div className="p-grid">
        <div>
          <ReportsMenu></ReportsMenu>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ProgressSpinner />
        </div>
      </div>
    );
  }
  return (
    <div className="p-grid">
      <div>
        <ReportsMenu></ReportsMenu>
      </div>
      <div className="card shop-reports">
        <div className="grid mb-5 mt-5">
          <div className="col">
            <div className="card overview-box orange">
              <div className="overview-info">
                <h6>{abp.utils.toPascalCase(L("SingleProducts"))}</h6>
                {/* <h6>{L("Processed")}</h6> */}
                <h1>{registrationSingleItems.length}</h1>
              </div>
              <i className="pi pi-shopping-bag"></i>
            </div>
          </div>
          <div className="col">
            <div className="card overview-box gray">
              <div className="overview-info">
                <h6>{abp.utils.toPascalCase(L("GroupsCount"))}</h6>
                <h6>{L("Processed")}</h6>
                <h1>{registrationGroupItems.length}</h1>
              </div>
              <i className="pi pi-shopping-bag"></i>
            </div>
          </div>
          <div className="col">
            <div className="card overview-box blue">
              <div className="overview-info">
                <h6>{abp.utils.toPascalCase(L("GroupProducts"))}</h6>
                {/* <h6>{L("Processed")}</h6> */}
                <h1>{numberOfGroupItems}</h1>
              </div>
              <i className="pi pi-shopping-bag"></i>
            </div>
          </div>
        </div>
        <div className="groups-shop-report">
          <h3>{L("Groups")}:</h3>
          <Accordion>
            {registrationGroupItems.map((group) => (
              <AccordionTab
                key={group.groupLeader.id}
                header={group.groupLeader.fullName}
              >
                <div className="registration-products">
                  {group.registrationItems?.map((shopItem) => (
                    <SingleShopReportItem
                      key={shopItem.id}
                      shopItem={shopItem}
                    />
                  ))}
                </div>
              </AccordionTab>
            ))}
          </Accordion>
        </div>
        <div className="singles-shop-report">
          <h3>{L("Singles")}:</h3>
          <div className="items registration-products">
            {registrationSingleItems.map((shopItem) => (
              <SingleShopReportItem key={shopItem.id} shopItem={shopItem} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const SingleShopReportItem = ({ shopItem }) => {
  return (
    <div className="shop-product">
      <div className="top">
        <div className="category">
          <i className="pi pi-tag"></i>
          <h5 className="m-0 p-0">
            {`${shopItem.firstName} ${shopItem.lastName}`}
          </h5>
        </div>
      </div>
      <div className="image">
        {shopItem.itemDto.images?.[0]?.url ? (
          <RenderProductCorrespondingImage
            images={shopItem.itemDto.images || []}
            data={shopItem.specifications}
          />
        ) : (
          <Skeleton height="100%" />
        )}
      </div>
      <p className="title">{shopItem.itemDto.title}</p>
      <div className="shop-specifications">
        <div className="genders">
          {shopItem.itemDto.specifications?.genders?.map((gender) => (
            <div
              key={gender}
              style={{ cursor: "default" }}
              className={`gender ${
                shopItem.specifications.gender === gender && "selected"
              }`}
            >
              <span>{L(gender.toUpperCase())}</span>
            </div>
          ))}
        </div>
        <div className="sizes">
          {shopItem.itemDto.specifications?.sizes?.map((size) => (
            <div
              key={size}
              style={{ cursor: "default" }}
              className={`size ${
                shopItem.specifications?.size === size && "selected"
              }`}
            >
              <span>{size.toUpperCase()}</span>
            </div>
          ))}
        </div>
        <div className="colors">
          <div
            className={`color selected`}
            style={{
              backgroundColor: `#${shopItem.specifications?.color}`,
              cursor: "default",
            }}
          >
            <i className="fa-solid fa-check"></i>
          </div>
        </div>
      </div>
      <div className="bottom">
        <div className="information_bottons">
          <Button
            outlined
            label={"Price: " + shopItem.itemDto.price + " лв."}
            className="p-button-help"
            style={{ pointerEvents: "none" }}
          />
          <Button
            outlined
            label={`${L("Quantity")}:  ${shopItem.specifications?.quantity}`}
            className="p-button-info"
            style={{ pointerEvents: "none" }}
          />
        </div>
        <ul>
          {Object.keys(shopItem.specifications).map(
            (key) =>
              !excludedProperties.find((i) => i === key) && (
                <li key={key}>
                  <span>{L(capitalizeFirstLetter(key))}</span>:{" "}
                  {shopItem.specifications[key]}
                </li>
              )
          )}
        </ul>
      </div>
    </div>
  );
};
