import http from "../httpService";

class ShopService {
  async create(createItemsInput) {
    let result = await http.post(
      "api/services/app/Items/Create",
      createItemsInput
    );
    return result.data.result;
  }

  async update(updateItemsInput) {
    let result = await http.put(
      "api/services/app/Items/Update",
      updateItemsInput
    );
    return result.data.result;
  }

  async delete(entityDto) {
    let result = await http.delete("api/services/app/Items/Delete", {
      params: entityDto,
    });
    return result.data;
  }

  async get(entityDto) {
    let result = await http.get("api/services/app/Items/Get", {
      params: entityDto,
    });
    return result.data.result;
  }

  async getAll(pagedFilterAndSortedRequest) {
    let result = await http.get("api/services/app/Items/GetAll", {
      params: pagedFilterAndSortedRequest,
    });
    return result.data.result;
  }

  async uploadFile(file, fileName) {
    const formData = new FormData();
    formData.append("file", file, fileName);
    let result = await http.post(
      "api/services/app/Items/UploadFile",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return result.data.result;
  }
}

export default new ShopService();
