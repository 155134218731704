import React, { useEffect, useState, useContext } from "react";
import ReportsMenu from "../../components/menus/ReportsMenu";
import { L } from "../../lib/abpUtility";
import registrationService from "../../services/registration/registrationService";
import { ProgressSpinner } from "primereact/progressspinner";
import { Chart } from "primereact/chart";
import { EventContext } from "../../context/Event/eventContext";

export const ParticipantsReport = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { lastEvent } = useContext(EventContext);
  const [loading, setLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [tentRecords, setTentRecords] = useState(0);
  const [outsideRecords, setOutsideRecords] = useState(0);
  const [groupRecords, setGroupRecords] = useState(0);
  const [groupsCount, setGroupsCount] = useState(0);
  const [daysCount, setDaysCount] = useState(0);
  const [eveningsCount, setEveningsCount] = useState(0);
  const [isProcessedCount, setIsProcessedCount] = useState(0);
  const [ageChartData, setAgeChartData] = useState({});
  const [daysChartData, setDaysChartData] = useState({});

  const getLightTheme = () => {
    let chartOptions = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: {
            color: "#495057",
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
        },
        y: {
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
        },
      },
    };
    let stackedOptions = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
          tooltips: {
              mode: 'index',
              intersect: false
          },
          legend: {
              labels: {
                  color: '#495057'
              }
          }
      },
      scales: {
          x: {
              stacked: true,
              ticks: {
                  color: '#495057'
              },
              grid: {
                  color: '#ebedef'
              }
          },
          y: {
              stacked: true,
              ticks: {
                  color: '#495057'
              },
              grid: {
                  color: '#ebedef'
              }
          }
      }
    };

    return {
      chartOptions, stackedOptions
    };
  };
  const { chartOptions, stackedOptions } = getLightTheme();

  useEffect(() => {
    registrationService
      .getAll({ HasNoPaginator: true})
          .then((registrations) => {
            registrationService
              .getAllRegistrationsInGroups({ MaxResultCount: 600 })
              .then((regsInGroups) => {
                registrationService
                  .getAllGroupLeaders({ MaxResultCount: 100 })
                  .then((gl) => {
                    registrationService.getDays(lastEvent)
                    .then((days) => {
                      setGroupsCount(gl.totalCount);
                      setGroupRecords(regsInGroups.length);
                      setTotalRecords(registrations.totalCount);
                      setTentOrOutsideCount(registrations.items);
                      getDataForAgeChart(registrations.items, days);
                      getDataForDaysChart(registrations.items, days[0]?.id);
                      setLoading(false);
                    });
                  });
              });
          });
  }, [lastEvent]); // eslint-disable-line react-hooks/exhaustive-deps

  const setTentOrOutsideCount = (registrations) => {
    let countTent = 0;
    let countOutside = 0;
    let isProcessedCount = 0;
    registrations.forEach(function (e) {
      let isTent = false;
      e.days.forEach(function (day) {
        if (day.accommodation) {
          isTent = true;
        }
      });
      if (isTent) {
        countTent++;
      } else {
        countOutside++;
      }
      if (e.isProcesseded) {
        isProcessedCount++;
      }
    });
    setTentRecords(countTent);
    setOutsideRecords(countOutside);
    setIsProcessedCount(isProcessedCount);
  };

  function subtractYears(date, years) {
    date.setFullYear(date.getFullYear() - years);
    return date;
  }

  const getDataForAgeChart = (registrations, days) => {
    const maxAge = 84;
    let axis = [...Array(maxAge).keys()];
    let resultsPerYear = [...Array(maxAge).fill(0)];;
    // let resultsPerAgeGroup = [...Array(maxAge).fill(0)];

    registrations.forEach(function (e) {
      let birthDate = new Date(e.birthDate);
      // let bDayString = birthDate.toISOString().split("T")[0];

      // let controlDates = []; //TODO: Handled as in StepTwo - needs refactoring together
      // days.forEach(function (d) {
      //   controlDates.push(subtractYears(new Date(d.date), 7).toISOString().split("T")[0]);
      //   controlDates.push(subtractYears(new Date(d.date), 11).toISOString().split("T")[0]);
      //   controlDates.push(subtractYears(new Date(d.date), 18).toISOString().split("T")[0]);
      // });

      let age = calculateAge(birthDate);

      if (age <= maxAge) {
        // if (controlDates.includes(bDayString)) {
        //   resultsPerAgeGroup[age]++;
        // } else {
          resultsPerYear[age]++;
        // }
      }
    });

    let object = {
      labels: axis,
      datasets: [
        {
          label: L("RegisteredYears"),
          data: resultsPerYear,
          fill: false,
          borderColor: "#42A5F5",
          tension: 0.4,
        },
        // {
        // label: L("RegisteredAgeGroup"),
        // data: resultsPerAgeGroup,
        // fill: false,
        // tension: 0.4,
        // borderColor: "#FFA726",
        // },
      ],
    };
    setAgeChartData(object);
  };

  const getDataForDaysChart = (registrations, firstDayId) => {
    let axis = [
      "Неделя",
      "Понеделник",
      "Вторник",
      "Сряда",
      "Четвъртък",
      "Петък",
      "Събота",
    ];
    let daysOutside = new Array(7).fill(null); //TODO: hardcoded 6 days ZeroDAy + 5, with regular New Wave it can be Sunday as well => to be changed to count EventDays - 1 (no tent on last day)
    let evenings = new Array(7).fill(null); //TODO: hardcoded 6 days ZeroDAy + 5, with regular New Wave it can be Sunday as well => to be changed to count EventDays - 1 (no tent on last day)
    let daysInside = new Array(7).fill(null); //TODO: hardcoded 6 days ZeroDAy + 5, with regular New Wave it can be Sunday as well => to be changed to count EventDays - 1 (no tent on last day)
   
    let daysCounter = 0;
    let eveningsCounter = 0;

    registrations.forEach(function (e) {
      e.days.forEach(function (d) {
        switch (d.presence.text) {
          case "AccommodationWithoutService":
            d.accommodation ? daysInside[d.eventDayId - firstDayId]++ : daysOutside[d.eventDayId - firstDayId]++;
            break;
          case "Day":
            d.accommodation ? daysInside[d.eventDayId - firstDayId]++ : daysOutside[d.eventDayId - firstDayId]++;
            daysCounter++;
            break;
          case "Evening":
            evenings[d.eventDayId - firstDayId]++;
            eveningsCounter++;
            break;
          case "LastDayWithoutAccomodation":
            daysOutside[d.eventDayId - firstDayId]++;
            daysCounter++;
              break;
          default:
            break;
        }
      });
    });

    let object = {
      labels: axis,
      datasets: [
        {
          type: 'bar',
          label: L("Tent"),
          data: daysInside,
          fill: false,
          tension: 0.4,    
          backgroundColor: '#42A5F5',
        },
        {
          type: 'bar',
          label: L("Day"),
          data: daysOutside,
          fill: false,
          backgroundColor: '#66BB6A',
          tension: 0.4,
        },
        {
          type: 'bar',
          label: L("Evening"),
          data: evenings,
          fill: false,
          backgroundColor: '#E3403C',
          tension: 0.4,
        },
      ],
    };
    setDaysChartData(object);
    setDaysCount(Math.ceil(daysCounter / 6)); //TODO: hardcoded 5 days, with regular New Wave it can be Sunday as well => to be changed to count EventDays - 2 (no service on zero day and last day)
    setEveningsCount(Math.ceil(eveningsCounter / 5)); //TODO: hardcoded 5 days, with regular New Wave it can be Sunday as well => to be changed to count EventDays - 2 (no service on zero day and last day)
  };

  function calculateAge(birthday) {
    let ageDifMs = Date.now() - birthday;
    let ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  return (
    <div className="p-grid">
      <div>
        <ReportsMenu></ReportsMenu>
      </div>

      <div className="p-col-12">
        <div className="card lg:p-6">
          <h3>{L("ParticipantsInfo")}</h3>
          {!loading ? (
            <>
              <div className="grid mb-5 mt-5">
                <div className="col">
                  <div className="card overview-box orange">
                    <div className="overview-info">
                      <h6>{L("Registered")} /</h6>
                      <h6>{L("Processed")}</h6>
                      <h1>{totalRecords + " / " + isProcessedCount}</h1>
                    </div>
                    <i className="pi pi-users"></i>
                  </div>
                </div>
                <div className="col">
                  <div className="card overview-box blue">
                    <div className="overview-info">
                      <h6>{L("Tent")} /</h6>
                      <h6>{L("OutsideCamp")}</h6>
                      <h1>{tentRecords + " / " + outsideRecords}</h1>
                    </div>
                    <i className="pi pi-home"></i>
                  </div>
                </div>
                <div className="col">
                  <div className="card overview-box gray">
                    <div className="overview-info">
                      <h6>{L("InGroup")} /</h6>
                      <h6>{L("GroupsCount")}</h6>
                      <h1>{groupRecords + " / " + groupsCount}</h1>
                    </div>
                    <i className="pi pi-paperclip"></i>
                  </div>
                </div>
                <div className="col">
                  <div className="card overview-box white">
                    <div className="overview-info">
                      <h6>{L("Day") + " " + L("Average")} /</h6>
                      <h6>{L("Evening") + " " + L("Average")}</h6>
                      <h1>{daysCount + " / " + eveningsCount}</h1>
                    </div>
                    <i className="pi pi-ticket"></i>
                  </div>
                </div>
              </div>
              <div className="grid p-col-12">
                <div className="col">
                  <h3 className="p-3">{L("Presence") + " " + L("PerDay")}</h3>
                  <Chart
                    data={daysChartData}
                    options={stackedOptions}
                    style={{ maxHeight: "330px" }}
                  />
                </div>
                <div className="col">
                  <h3 className="p-3">{L("Age")}</h3>
                  <Chart
                    type="line"
                    data={ageChartData}
                    options={chartOptions}
                    style={{ maxHeight: "330px" }}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="flex align-items-center justify-content-center">
              <ProgressSpinner />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ParticipantsReport;
