import React, { useEffect } from "react";
import SubMenu from "../../components/menus/SubMenu";
import { Divider } from "primereact/divider";
import { Card } from "primereact/card";
var abp = window.abp;
export const Trainings = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="p-grid">
      <div>
        <SubMenu></SubMenu>
      </div>

      <div className="p-col-12">
        <div className="card lg:p-6">
          <div className="flex align-items-center flex-column lg:flex-row">
            <Card className="lg:col-6 col-12">
              <div style={{ position: "relative", paddingBottom: "56.25%" }}>
                <iframe
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                  }}
                  src="https://www.youtube.com/embed/Lhnc61OD0Q4"
                  title="YouTube video player Registration"
                  frameborder="0"
                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                  allowfullscreen={true}
                ></iframe>
              </div>
            </Card>
            <div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center ">
              <section>
                <span className="block text-4xl font-bold mb-1">
                  Регистрация
                </span>
                <div className="text-2xl text-primary font-bold mb-3">
                  с или без профил
                </div>
                <p className="mt-0 mb-4 text-700 line-height-3">
                  Можете да се регистрирате за лагера с или без профил в сайта,
                  но препоръчваме да създадете такъв, за да имате достъп до
                  вашите регистрации. За груповите ръководители, профилът е
                  задължителен.
                </p>
              </section>
            </div>
          </div>
          <Divider />
          <div className="flex align-items-center flex-column lg:flex-row">
            <Card className="lg:col-6 col-12">
              <div style={{ position: "relative", paddingBottom: "56.25%" }}>
                <iframe
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                  }}
                  src="https://www.youtube.com/embed/QXxASYDFuzA"
                  title="YouTube video player Group Leader"
                  frameborder="0"
                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                  allowfullscreen={true}
                ></iframe>
              </div>
            </Card>
            <div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center ">
              <section>
                <span className="block text-4xl font-bold mb-1">
                  Групови ръководители
                </span>
                <div className="text-2xl text-primary font-bold mb-3">
                  управление на група
                </div>
                <p className="mt-0 mb-4 text-700 line-height-3">
                  В това видео ще научите как да регистрирате и управлявате
                  регистрациите на вашата група. Изгледайте и горното видео за
                  въвеждаща информация относно процеса на самата регистрация.
                </p>
              </section>
            </div>
          </div>
          {abp.auth.hasPermission("Pages.Payments.Pay") && (
            <div>
            <Divider />
            <div className="flex align-items-center flex-column lg:flex-row">
              <Card className="lg:col-6 col-12">
                <div style={{ position: "relative", paddingBottom: "56.25%" }}>
                  <iframe
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                    }}
                    src="https://www.youtube.com/embed/mFL6oMIPeFU"
                    title="YouTube video player Payment Register"
                    frameborder="0"
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    allowfullscreen={true}
                  ></iframe>
                </div>
              </Card>
              <div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center ">
                <section>
                  <span className="block text-4xl font-bold mb-1">
                    Касов апарат (2022)
                  </span>
                  <div className="text-2xl text-primary font-bold mb-3">
                    основни функции
                  </div>
                  <p className="mt-0 mb-4 text-700 line-height-3">
                    Това видео е за регистратори. Заснето е с телефона в Датекс
                    при настройката на апарата и показва основен преглед на
                    функциите на касовия апарат. Но конкретният начин на работа
                    ще бъде изяснен със Стефан Петков на самата Вълна.
                  </p>
                </section>
              </div>
            </div>
            <Divider />
            <div className="flex align-items-center flex-column lg:flex-row">
              <Card className="lg:col-6 col-12">
                <div style={{ position: "relative", paddingBottom: "56.25%" }}>
                  <iframe
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                    }}
                    src="https://www.youtube.com/embed/RfJarZlzYIE"
                    title="YouTube video player Payment Register"
                    frameborder="0"
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    allowfullscreen={true}
                  ></iframe>
                </div>
              </Card>
              <div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center ">
                <section>
                  <span className="block text-4xl font-bold mb-1">
                    Касов апарат (2023)
                  </span>
                  <div className="text-2xl text-primary font-bold mb-3">
                    работа с ПОС терминал
                  </div>
                  <p className="mt-0 mb-4 text-700 line-height-3">
                    Това видео е за регистратори. Заснето е с телефона в Датекс
                    при настройката на апарата и показва преговор на
                    функциите на касовия апарат и работа с ПОС терминал.
                  </p>
                </section>
              </div>
            </div>
            <Divider />
            <div className="flex align-items-center flex-column lg:flex-row">
              <Card className="lg:col-6 col-12">
                <div style={{ position: "relative", paddingBottom: "56.25%" }}>
                  <iframe
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                    }}
                    src="https://www.youtube.com/embed/t5o1vaSmCDs"
                    title="YouTube video player Registrators Webinar"
                    frameborder="0"
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    allowfullscreen={true}
                  ></iframe>
                </div>
              </Card>
              <div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center ">
                <section>
                  <span className="block text-4xl font-bold mb-1">
                    Регистрационен процес (2022)
                  </span>
                  <div className="text-2xl text-primary font-bold mb-3">
                    запис от уебинар
                  </div>
                  <p className="mt-0 mb-4 text-700 line-height-3">
                    В този уебинар разгледахме основни процеси на работа на
                    регистрация. Настъпиха няколко промени - няма да имаме
                    възможност да приемаме плащания с карта. А някои неща
                    поправихме - стъпка 2. Могат да настъпят и още промени, но е
                    добро начало за запознаване със ситемата.
                  </p>
                </section>
              </div>
            </div>
            <Divider />
            <div className="flex align-items-center flex-column lg:flex-row">
              <Card className="lg:col-6 col-12">
                <div style={{ position: "relative", paddingBottom: "56.25%" }}>
                  <iframe
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                    }}
                    src="https://www.youtube.com/embed/Ld8CMLF5aNo"
                    title="YouTube video player Registrators Webinar"
                    frameborder="0"
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    allowfullscreen={true}
                  ></iframe>
                </div>
              </Card>
              <div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center ">
                <section>
                  <span className="block text-4xl font-bold mb-1">
                    Регистрационен процес (2023)
                  </span>
                  <div className="text-2xl text-primary font-bold mb-3">
                    запис от уебинар
                  </div>
                  <p className="mt-0 mb-4 text-700 line-height-3">
                    В този уебинар разгледахме основни процеси на работа на
                    регистрация. Настъпиха няколко промени - ще имаме
                    възможност да приемаме плащания с карта.
                  </p>
                </section>
              </div>
            </div>
          </div>)}
        </div>
      </div>
    </div>
  );
};

export default Trainings;
