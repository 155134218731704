import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Divider } from "primereact/divider";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReportsMenu from "../../components/menus/ReportsMenu";
import { L } from "../../lib/abpUtility";
import feesService from "../../services/fees/feesService";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { Controller } from "react-hook-form";

export const PaymentsReport = () => {
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date("July 01, 2023"));
  const [endDate, setEndDate] = useState(new Date("July 29, 2023"));
  const [reportData, setReportData] = useState();
  const [allPaymentsData, setAllPaymentsData] = useState();
  const [total, setTotal] = useState(0);
  const [cashTotal, setCashTotal] = useState(0);
  const [POSTotal, setPOSTotal] = useState(0);
  const [bankTotal, setBankTotal] = useState(0);
  const { handleSubmit, control } = useForm({});

  useEffect(() => {
    window.scrollTo(0, 0);
    feesService
      .getShiftReport({
        FromDateTime: startDate,
        ToDateTime: endDate,
      })
      .then((shiftReport) => {
        feesService
          .getAllPayments({
            FromDateTime: startDate,
            ToDateTime: endDate,
          })
          .then((allPayments) => {
            let total = 0;
            let cashTotal = 0;
            let POSTotal = 0;
            let bankTotal = 0;

            shiftReport.items.forEach((item) => {
              total += parseFloat(item.amount.replaceAll(",", "."));
              switch (item.payment) {
                case "Online":
                  bankTotal += parseFloat(item.amount.replaceAll(",", "."));
                  break;
                case "Cash":
                  cashTotal += parseFloat(item.amount.replaceAll(",", "."));
                  break;
                case "POS":
                  POSTotal += parseFloat(item.amount.replaceAll(",", "."));
                  break;
                default:
                  break;
              }
            });

            setTotal(total.toFixed(2));
            setCashTotal(cashTotal.toFixed(2));
            setPOSTotal(POSTotal.toFixed(2));
            setBankTotal(bankTotal.toFixed(2));

            shiftReport.items.map((item) => (item.payment = L(item.payment)));
            setReportData(shiftReport.items);
            allPayments.items.map((item) => (item.payment = L(item.payment)));

            allPayments.items.forEach((item) => {
              let url = "/registrations/" + item.registrationId + "/details";
              item.payment = L(item.payment);
              item.registrationFullName = (
                <Link to={url}>{item.registrationFullName}</Link>
              );
            });
            setAllPaymentsData(allPayments.items);
            setLoading(false);
          });
      });
  }, [startDate, endDate]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = (data) => {
    setStartDate(data.startDate);
    setEndDate(data.endDate);
    setLoading(false);
    return;
  };

  return (
    <div className="p-grid">
      <div>
        <ReportsMenu></ReportsMenu>
      </div>

      <div className="p-col-12">
        <div className="card lg:p-6">
          <h3>{L("PaymentsReport")}</h3>
          {!loading ? (
            <form className="p-3" onSubmit={handleSubmit(onSubmit)}>
              <div
                className="flex"
                style={{
                  gap: "20px",
                  flexWrap: "wrap",
                }}
              >
                <div
                  className="flex"
                  style={{
                    gap: "10px",
                    flexWrap: "wrap",
                  }}
                >
                  <h4 className="mt-1">{L("Start")}</h4>
                  <Controller
                    name="startDate"
                    control={control}
                    render={({ field }) => (
                      <Calendar
                        name="startDate"
                        style={{ height: "32px", width: "135px" }}
                        showTime
                        hourFormat="24"
                        yearRange={"2022:" + new Date().getFullYear()}
                        value={startDate}
                        {...field}
                      />
                    )}
                  />
                </div>
                <div
                  className="flex"
                  style={{
                    gap: "10px",
                    flexWrap: "wrap",
                  }}
                >
                  <h4 className="mt-1">{L("End")}</h4>
                  <Controller
                    name="endDate"
                    control={control}
                    render={({ field }) => (
                      <Calendar
                        name="endDate"
                        style={{ height: "32px", width: "135px" }}
                        showTime
                        hourFormat="24"
                        yearRange={"2022:" + new Date().getFullYear()}
                        value={endDate}
                        {...field}
                      />
                    )}
                  />
                </div>
                <div>
                  <Button label={L("Select")} type="submit" />
                </div>
              </div>
              <Divider />
              <div className="grid mb-5 mt-5">
                <div className="col">
                  <div className="card overview-box orange">
                    <div className="overview-info">
                      <h6>{L("Total")}</h6>
                      <h1>{total}</h1>
                    </div>
                    <i className="pi pi-wallet"></i>
                  </div>
                </div>
                <div className="col">
                  <div className="card overview-box blue">
                    <div className="overview-info">
                      <h6>{L("Cash")}</h6>
                      <h1>{cashTotal}</h1>
                    </div>
                    <i className="pi pi-money-bill"></i>
                  </div>
                </div>
                <div className="col">
                  <div className="card overview-box gray">
                    <div className="overview-info">
                      <h6>{L("POS")}</h6>
                      <h1>{POSTotal}</h1>
                    </div>
                    <i className="pi pi-credit-card"></i>
                  </div>
                </div>
                <div className="col">
                  <div className="card overview-box white">
                    <div className="overview-info">
                      <h6>{L("Online")}</h6>
                      <h1>{bankTotal}</h1>
                    </div>
                    <i className="pi pi-building"></i>
                  </div>
                </div>
              </div>
              <h4>{L("ShiftReport")}</h4>
              <DataTable value={reportData} responsiveLayout="scroll">
                <Column
                  field="userFullName"
                  header="Регистратор"
                  sortable
                ></Column>
                <Column field="payment" header="Тип плащане" sortable></Column>
                <Column field="amount" header="Сума (лв.)"></Column>
              </DataTable>
              <h4>{L("AllPayments")}</h4>
              <DataTable value={allPaymentsData} responsiveLayout="scroll">
                <Column field="userFullName" header="Регистратор"></Column>
                <Column field="payment" header="Тип плащане"></Column>
                <Column field="amount" header="Сума (лв.)"></Column>
                <Column field="comment" header="Коментар"></Column>
                <Column field="registrationFullName" header="Участник"></Column>
              </DataTable>
            </form>
          ) : (
            <div className="flex align-items-center justify-content-center">
              <ProgressSpinner />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentsReport;
