import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { errorRef } from "../../App";
import forgotPasswordService from "../../services/fogot-password/forgot-password-service";
import { L } from "../../lib/abpUtility";

function UserForgotPassword() {
  const [loading, setLoading] = useState(false);
  const history = useNavigate();
  const { resetCode } = useParams();
  const { handleSubmit, control } = useForm({
    defaultValues: {
      password: "",
      "repeat-password": "",
    },
  });

  const onSubmit = (data) => {
    setLoading(true);
    if (data.newPassword === data.repeatPassword) {
      forgotPasswordService
        .forgotPasswordReset(
          resetCode,
          data.newPassword,
          data.repeatPassword
        )
        .then(() => {
          history("/login");
        });
    } else {
      setLoading(false);
      errorRef.current.show({
        severity: "error",
        summary: L("Error"),
        detail: L("PassChangeError"),
        sicky: true,
      });
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={`service-div`}>
        {!loading ? (
          <React.Fragment>
            <h2>{L("PassChangeHeader")}</h2>
            <p style={{ margin: "0px 0px 14px 0px" }}>
              {L("PassChangeParagraph")}
            </p>

            <Controller
              control={control}
              name="newPassword"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Password
                  placeholder={L("NewPass")}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                ></Password>
              )}
            ></Controller>

            <Controller
              control={control}
              name="repeatPassword"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Password
                  placeholder={L("RepeatPass")}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                ></Password>
              )}
            ></Controller>

            <Button label={L("Change")} type="submit"></Button>
          </React.Fragment>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ProgressSpinner />
          </div>
        )}
      </div>
    </form>
  );
}

export default UserForgotPassword;
