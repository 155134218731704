let abp = window.abp;

class LocalizationService {
  localizePipe(key) {
    const cultureName = abp.localization.currentCulture?.name;

    if (key?.includes(" | ")) {
      let nameParts = key.split(" | ");
      if (cultureName === "bg") {
        return nameParts?.[0];
      } else if (cultureName === "en") {
        return nameParts?.[1];
      }
    }

    return key;
  }
}

export default new LocalizationService();
