import React from "react";
import dateTimeService from "../../services/dateTimeService";
import http from "../../services/httpService";
import registrationService from "../../services/registration/registrationService";
import storageService from "../../services/storage/storageService";
import userStorageService from "../../services/storage/userStorageService";

let abp = window.abp;

export const RegistrationContext = React.createContext();

const onStepTwoSubmit = (data) => {
  for (let item in data) {
    if (data[item] && item !== "days") {
      storageService.setItem(item, data[item]);
    }
  }
};

const hasAccomodation = () => {
  const result = storageService.getItem("hasAccommodation");
  return result ? true : false;
};

const getDaysStepTwo = (lastEvent) => {
  const days = registrationService.getDays(lastEvent);
  return days;
};

const onComplete = (lastEvent) => {
  const userId = !abp.auth.isGranted("Pages.Payments.Pay")
    ? window.abp.session.userId
    : null;
  const eventId = lastEvent?.id;
  const user = userStorageService.getUser();
  user.birthDate = dateTimeService.modifyDate(user.birthDate);
  user.activities = user.personActivities;
  user.days = removeInvalidPresences(user.days);
  return http.post("api/services/app/Registrations/Create", {
    ...user,
    userId,
    eventId,
    isGroupLeader: user.isGroupLeader === "true",
  });
};

const removeInvalidPresences = (presences) => {
  let result = presences
    .map((day) => {
      return {
        ...day,
        presence: day.presence?.id ? { id: day.presence?.id } : null,
        accommodation: day.accommodation?.id
          ? { id: day.accommodation?.id }
          : null,
      };
    })
    .filter((day) => day?.eventDayId !== undefined)
    .filter(
      (day) =>
        day.presence?.id !== undefined || day.accommodation?.id !== undefined
    );
  return result;
};

const onStepOneSubmit = (data) => {
  let hasAccomodation = data.days.find((i) => i?.accommodation?.id);

  storageService.setItem(
    "hasAccommodation",
    hasAccomodation ? "true" : "false"
  );

  const newDays = data.days.map((day) => {
    return {
      ...day,
      eventDayId: parseInt(day?.eventDayId),
    };
  });

  storageService.setItem("days", JSON.stringify(newDays));
};

export const registrationContextValues = {
  onStepTwoSubmit,
  hasAccomodation,
  onStepOneSubmit,
  getDaysStepTwo,
  onComplete,
};
