import React, { useContext, useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { useForm, Controller } from "react-hook-form";
import { Calendar } from "primereact/calendar";
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import { Link, useNavigate } from "react-router-dom";
import { RegistrationContext } from "../../../context/Registration/registrationContext";
import userStorageService from "../../../services/storage/userStorageService";
import storageService from "../../../services/storage/storageService";
import { L } from "../../../lib/abpUtility";
import ProtectedAuthLink from "../../routing/ProtectedAuthLink";
import { Dropdown } from "primereact/dropdown";
import { AuthenticationContext } from "../../../context/Authentication/authenticationContext";
import { useQuery } from "react-query";
import registrationService from "../../../services/registration/registrationService";

let abp = window.abp;

function StepTwo() {
  const {
    handleSubmit,
    reset,
    control,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: userStorageService.getUser(),
  });
  const { user } = useContext(AuthenticationContext);

  const { data: previousUserData } = useQuery({
    queryKey: ["previousData", user?.id],
    queryFn: () => registrationService.getPersonByUserId(user?.id),
    enabled: !!user?.id,
  });
  const [stayInCamp, setStayInCamp] = React.useState(
    storageService.getItem("ucn") ? true : false
  );

  const [isInGroup] = useState(
    storageService.getItem("assignToGroup") ? true : false
  );
  const [checkedDraft, setCheckedDraft] = useState(
    storageService.getItem("isDraft") === "true" ? true : false
  );
  const [checkedGroup, setCheckedGroup] = useState(
    storageService.getItem("isGroupLeader") === "true" ? true : false
  );
  let { setStep, onStepTwoSubmit } = useContext(RegistrationContext);
  const [popup, setPopup] = useState(false);
  let history = useNavigate();

  React.useEffect(() => {
    storageService.setItem("isDraft", checkedDraft);
    watch((e) => {
      onStepTwoSubmit(e);
    });
  }, [watch, onStepTwoSubmit, checkedDraft]);

  React.useEffect(() => {
    storageService.setItem("isGroupLeader", checkedGroup);
    watch((e) => {
      onStepTwoSubmit(e);
    });
  }, [watch, onStepTwoSubmit, checkedGroup]);

  React.useEffect(() => {
    setStep(1);
    let hasAccommodation = storageService.getItem("hasAccommodation");
    setStayInCamp(hasAccommodation === "true" ? true : false);
  }, []);

  const onSubmit = (data) => {
    onStepTwoSubmit(data);
    storageService.setItem("isDraft", checkedDraft);
    storageService.setItem("isGroupLeader", checkedGroup);
    if (!stayInCamp) {
      storageService.removeItem("ucn");
      storageService.removeItem("middleName");
      storageService.removeItem("address");
    }
    setStep(1);
    history("/registration/step3");
  };

  useEffect(() => {
    if (previousUserData) {
      setPopup(true);
    }
  }, [previousUserData]);

  const monthNavigatorTemplate = (e) => {
    const modifyMonths = e.options.map(month=>{
      return{
        "value":month?.value+1,
        "label":month?.label,
        "index":month?.index
      }
    })
    const modifyValue = e.value + 1
    
    return (
      <Dropdown
        value={modifyValue}
        options={modifyMonths}
        onChange={(event) => {e.onChange(event.originalEvent, event.value - 1)}}
        style={{ lineHeight: 1 }}
      />
    );
  };

  const yearNavigatorTemplate = (e) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        className="p-ml-2"
        style={{ lineHeight: 1 }}
      />
    );
  };

  const onContinue = () => {
    let values = getValues();
    setPopup(false);
    reset({
      ...values,
      firstName: previousUserData.firstName,
      lastName: previousUserData.lastName,
      middleName: previousUserData.middleName,
      birthDate: new Date(previousUserData.birthDate),
      address: previousUserData.address,
      ucn: previousUserData.ucn,
      city: previousUserData.city,
      church: previousUserData.church,
      phoneNumber: previousUserData.phoneNumber,
    });
  };

  function subtractYears(date, years) {
    date.setFullYear(date.getFullYear() - years);
    return date;
  }

  const setFakeBirthday = (category) => {
    switch (category) {
      case "kid":
        setValue("birthDate", subtractYears(new Date(), 7)); //TODO: Handled as in ParticipantsReport - needs refactoring together
        break;
      case "pre-teen":
        setValue("birthDate", subtractYears(new Date(), 11)); //TODO: make dependent on last day of event + 1 day
        break;
      case "teen-adult":
        setValue("birthDate", subtractYears(new Date(), 16)); //TODO: make dependent on last day of event + 1 day
        break;
      case "adult":
        setValue("birthDate", subtractYears(new Date(), 19)); //TODO: make dependent on last day of event + 1 day
        break;
      default:
        break;
    }
  };

  return (
    <div className="step">
      <form onSubmit={handleSubmit(onSubmit)}>
        {abp.auth.hasPermission("Pages.Registration.Helper") && (
          <div className={"flex"} style={{ gap: 15, marginBottom: "10px" }}>
            <InputSwitch
              checked={checkedDraft}
              onChange={(e) => setCheckedDraft(e.target.value)}
            />
            <p>{L("IsDraft")}</p>
          </div>
        )}
        <div className="step-input">
          <h4>{L("Name")}</h4>
          <Controller
            name="firstName"
            control={control}
            rules={{ minLength: 2, required: true }}
            render={({ field }) => <InputText {...field} />}
          />

          <span style={{ color: "red" }}>
            {errors.firstName && L("ThisFieldIsRequired")}
          </span>
        </div>

        {stayInCamp && (
          <div className="step-input">
            <h4>{L("MiddleName")}</h4>
            <Controller
              name="middleName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => <InputText {...field} />}
            />

            <span style={{ color: "red" }}>
              {errors.middleName && L("ThisFieldIsRequired")}
            </span>
          </div>
        )}
        <div className="step-input">
          <h4>{L("Surname")}</h4>
          <Controller
            name="lastName"
            control={control}
            rules={{ required: true, minLength: 2 }}
            render={({ field }) => <InputText {...field} />}
          />

          <span style={{ color: "red" }}>
            {errors.lastName && L("ThisFieldIsRequired")}
          </span>
        </div>
        {/* {abp.auth.hasPermission("Pages.Registration.Quick.Evening") && ( */}
        <div className="step-input">
          <h4>{L("Age") + " (" + L("QuickChoice") + ")"}</h4>
          <div
            className="flex p-2"
            style={{
              gap: "15px",
              flexWrap: "wrap",
              whiteSpace: "nowrap"
            }}
          >
            <Button
              type="button"
              label={L("upTo8Years")}
              className="p-button-success p-button-raised"
              style={{ width: "70px" }}
              onClick={() => setFakeBirthday("kid")}
            />
            <Button
              type="button"
              label={L("9to12years")}
              className="p-button-warning p-button-raised"
              style={{ width: "100px" }}
              onClick={() => setFakeBirthday("pre-teen")}
            />
            <Button
              type="button"
              label={L("13to17years")}
              className="p-button-info p-button-raised"
              style={{ width: "100px" }}
              onClick={() => setFakeBirthday("teen-adult")}
            />
            <Button
              type="button"
              label={L("over18years")}
              className="p-button-danger p-button-raised"
              style={{ width: "110px" }}
              onClick={() => setFakeBirthday("adult")}
            />
          </div>
        </div>
        {/* )} */}
        <div className="step-input">
          <h4>{L("BirthDate")}</h4>
          <Controller
            name={`birthDate`}
            control={control}
            rules={{
              required: true,
              validate: (value) => {
                let now = new Date();
                return now >= new Date(value);
              },
            }}
            render={({ field }) => (
              <Calendar
                monthNavigator
                yearNavigator
                monthNavigatorTemplate={monthNavigatorTemplate}
                yearNavigatorTemplate={yearNavigatorTemplate}
                maxDate={new Date()}
                yearRange={"1940:" + new Date().getFullYear()}
                {...field}
              />
            )}
          />
          <span style={{ color: "red" }}>
            {errors.birthDate && L("ThisFieldIsRequired")}
          </span>
        </div>
        <div className="step-input">
          <h4>{L("City")}</h4>
          <Controller
            name="city"
            control={control}
            rules={{ required: true, minLength: 2 }}
            render={({ field }) => <InputText {...field} />}
          />
          <span style={{ color: "red" }}>
            {errors.city && L("ThisFieldIsRequired")}
          </span>
        </div>

        <div className="step-input">
          <h4>{L("Church")}</h4>
          <Controller
            name="church"
            control={control}
            rules={{ required: false, minLength: 2 }}
            render={({ field }) => <InputText {...field} />}
          />
          <span style={{ color: "red" }}>
            {errors.city && L("ThisFieldIsRequired")}
          </span>
        </div>

        {stayInCamp && (
          <React.Fragment>
            <div className="step-input">
              <h4>{L("UCN")}</h4>
              <Controller
                name="ucn"
                control={control}
                rules={{ required: stayInCamp, pattern: /^[0-9]*$/ }}
                render={({ field }) => <InputText {...field} />}
              />
              <span style={{ color: "red" }}>
                {errors.ucn && L("ThisFieldIsRequired")}
              </span>
            </div>
            <div className="step-input">
              <h4>{L("Address")}</h4>
              <Controller
                control={control}
                name="address"
                rules={{ required: stayInCamp, minLength: stayInCamp ? 3 : 0 }}
                render={({ field }) => <InputText {...field} />}
              />
              <span style={{ color: "red" }}>
                {errors.address && L("ThisFieldIsRequired")}
              </span>
            </div>
          </React.Fragment>
        )}
        <br />
        {!abp.auth.hasPermission("Pages.Registration.GroupLeader") &&
          !abp.auth.hasPermission("Pages.Payments.Pay") &&
          !isInGroup && (
            <ProtectedAuthLink auth={true}>
              <div className={"flex"} style={{ gap: 15 }}>
                <InputSwitch
                  checked={checkedGroup}
                  onChange={(e) => setCheckedGroup(e.target.value)}
                />
                <p>{L("WannaBeGroupLeader")}</p>
              </div>
            </ProtectedAuthLink>
          )}
        <br />
        <div className={"text-left"}>
          <p>
            {L("AgreeAll")}
            <br />
            <Link to="/conditions">{L("Conditions")}</Link>,
            <Link to="/camp-rules"> {L("CampRules")}</Link> &
            <Link to="/data-security"> {L("DataSecurity")}</Link>
          </p>
        </div>
        <br />
        <br />
        <Button type="submit" label={L("NextButton")} className="next-button" />
      </form>
      {popup && (
        <div className="previous-user-data">
          <div className="popup-header">
            <div className="left-side">
              <i className="fa fa-user"></i>
              <h5>{L("SignInPreviousData")}</h5>
            </div>
            <div className="right-side">
              <i className="fa fa-times" onClick={() => setPopup(false)}></i>
            </div>
          </div>
          <hr />
          <div className="popup-body">
            <div className="mainpart">
              <h5>
                {previousUserData?.firstName} {previousUserData?.lastName}
              </h5>
            </div>
            <div className="popup-btn">
              <Button
                label={L("ContinueAs") + ` ` + `${previousUserData?.firstName}`}
                onClick={onContinue}
                className="p-button p-button-info"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default StepTwo;
