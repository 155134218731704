import { Button } from "primereact/button";
import { L } from "../../lib/abpUtility";

const editableFields = [
  {
    name: "firstName",
    type: "string",
  },
  { name: "lastName", type: "string" },
  { name: "city", type: "string" },
  { name: "birthDate", type: "date" },
];

var showFields = [
  {
    field: "firstName",
    header: L("Name"),
    sortable: true,
    type: "string",
    body: (rowData) => (
      <p>
        {rowData.firstName}
        {rowData.isGroupLeader && (
          <span style={{ paddingLeft: "15px", fontSize: "1.4rem" }}>👑</span>
        )}
      </p>
    ),
  },
  {
    field: "lastName",
    header: L("Surname"),
    sortable: true,
    type: "string",
  },
  {
    field: "city",
    header: L("City"),
    sortable: true,
    type: "string",
  },
  {
    field: "days",
    header: L("Presence"),
    sortable: true,
    otherProps: {
      hidden: true,
    },
    type: "string",
  },
  {
    field: "church",
    header: L("Church"),
    sortable: true,
    type: "string",
  },
  {
    field: "address",
    header: L("Address"),
    sortable: true,
    type: "string",
    hidden: true,
  },
  {
    field: "isDraft",
    header: L("IsDraft"),
    sortable: true,
    type: "string",
    hidden: true,
    body: (rowData) => rowData.isDraft && <Button className="p-button p-button-rounded" icon={"fa fa-check"} />
  },
  {
    field: "licensePlate",
    header: L("LicensePlate"),
    sortable: true,
    type: "string",
    hidden: true,
  },
  {
    field: "phoneNumber",
    header: L("PhoneNumber"),
    sortable: true,
    type: "string",
    hidden: true,
  },
  {
    field: "ucn",
    header: L("UCN"),
    sortable: true,
    type: "string",
    hidden: true,
  },
  {
    field: "birthDate",
    header: L("BirthDate"),
    sortable: true,
    type: "date",
    hidden: true,
    body: (rowData) => <p>{new Date(rowData.birthDate).toDateString()}</p>,
  },
  {
    field: "creationTime",
    header: L("CreationTime"),
    sortable: true,
    type: "date",
    hidden: true,
    body: (rowData) => <p>{new Date(rowData.creationTime).toDateString()}</p>,
  }
  // {
  //   field: "activity",
  //   header: L("Volunteering"),
  //   sortable: true,
  //   type: "string",
  //   hidden: true,
  //   body: (rowData) => <p>{rowData.personActivities.map(pa => L(pa.activity)).join(', ')}</p>
  // },
];

export { showFields, editableFields };
