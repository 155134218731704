import React from "react";

var abp = window.abp;

function HasPermission({ permission, children }) {
  const hasPermission = abp.auth.isAnyGranted(...permission);
  return (
    <React.Fragment>
      {hasPermission ? <React.Fragment>{children}</React.Fragment> : null}
    </React.Fragment>
  );
}

export default HasPermission;
