import React, { useEffect } from "react";
import SubMenu from "../../components/menus/SubMenu";
import EditorWrapper from "../../components/Editor/Editor";
import PricingTable from "../../pages/BasicContent/PricingTable";

export const FeesAndDocuments = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  },[]);

  return (
    <div className="p-grid">
      <div>
        <SubMenu></SubMenu>
      </div>

      <div className="p-col-12">
        <div className="card lg:p-6">
          <EditorWrapper name="fees-and-documents"></EditorWrapper>
          <PricingTable></PricingTable>
        </div>
      </div>
    </div>
  );
};

export default FeesAndDocuments;
