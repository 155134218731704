import React, { useState, useEffect, useContext, useRef } from "react";
import { ConfirmPopup } from "primereact/confirmpopup"; // To use <ConfirmPopup> tag
import Table from "../../components/crud/Table";
import { EventContext } from "../../context/Event/eventContext";
import { Tag } from "primereact/tag";
import dateTimeService from "../../services/dateTimeService";
import { L } from "../../lib/abpUtility";
import { Button } from "primereact/button";
import eventService from "../../services/event/eventService";

function Events() {
  const [events, setEvents] = useState();
  const [loading, setLoading] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const eventContext = useContext(EventContext);

  const editableFields = [
    { name: "name", type: "string" },
    { name: "location", type: "string" },
    { name: "dates", type: "startEndDate" },
    { name: "isShopActive", type: "bool", label: "Web Shop Active" },
  ];

  const createFields = [
    { name: "name", type: "string" },
    { name: "location", type: "string" },
    { name: "dates", type: "startEndDate" },
  ];

  useEffect(() => {
    getAllEvents();
  }, [eventContext, setEvents, setLoading]);

  const getAllEvents = () => {
    eventContext.getAll().then((res) => {
      setEvents(res.items);
      setLoading(false);
    });
  };

  const showFields = [
    { field: "name", header: L("EventName"), sortable: true, type: "string" },
    {
      field: "location",
      header: L("EventLocation"),
      sortable: true,
      type: "string",
    },
    {
      field: "startDate",
      header: L("StartDate"),
      sortable: false,
      body: (row) => customBody(row, "startDate"),
      type: "calendar",
    },
    {
      field: "endDate",
      header: L("EndDate"),
      sortable: false,
      type: "calendar",
      body: (row) => customBody(row, "endDate"),
    },
    {
      field: "isShopActive",
      header: L("IsShopActive"),
      sortable: false,
      body: (row) => (
        <Button
          severity={row.isShopActive ? "success" : "danger"}
          label={row.isShopActive ? "True" : "False"}
        />
      ),
    },
    {
      field: "eventState",
      header: L("EventState"),
      sortable: true,
      body: (rowData) => customStateBody(rowData),
    },
  ];
  const getStateClassName = (state) => {
    switch (state) {
      case "Draft":
        return "p-tag-draft";
      case "Active":
        return "p-tag-active";
      case "Finished":
        return "p-tag-finished";
      default:
        return "p-tag-draft";
    }
  };

  const customStateBody = (rowData) => {
    return (
      <>
        <Tag
          className={getStateClassName(rowData.eventState)}
          value={rowData.eventState}
          rounded
        />
      </>
    );
  };

  const customBody = (rowData, field) => {
    return (
      <div>
        <p>{new Date(rowData[field]).toDateString()}</p>
      </div>
    );
  };

  const createEvent = (data) => {
    data.startDate = dateTimeService.modifyDate(data.dates[0]);
    data.endDate = dateTimeService.modifyDate(data.dates[1]);
    eventContext.create(data).then((res) => {
      setEvents([...events, res]);
      setShowDialog(false);
    });
  };

  const editEvent = (data, selectedData) => {
    data.startDate = dateTimeService.modifyDate(data.dates[0]);
    data.endDate = dateTimeService.modifyDate(data.dates[1]);
    for (let item in data) {
      selectedData[item] = data[item];
    }
    eventContext.update(selectedData).then((res) => {
      setEvents(events.map((event) => (event.id === res.id ? res : event)));
      setShowDialog(false);
    });
  };

  const deleteEvent = (data) => {
    eventContext.deleteEvent({ id: data?.id }).then((res) => {
      setEvents(events.filter((event) => event.id !== data.id));
      setShowDialog(false);
    });
  };

  const activateEvent = async (event) => {
    await eventService.changeEventStatus({
      eventId: event.id,
      eventStateId: 2,
    });
    getAllEvents();
  };

  const finishEvent = async (event) => {
    await eventService.changeEventStatus({
      eventId: event.id,
      eventStateId: 3,
    });
    getAllEvents();
  };

  const ExtendedActionBody = ({ data }) => {
    const [visibleActivate, setVisibleActive] = useState(false);
    const [visibleFinish, setVisibleFinish] = useState(false);
    const buttonEl = useRef(null);

    return data.eventState.toLowerCase() === "draft" ? (
      <React.Fragment>
        <ConfirmPopup
          target={buttonEl.current}
          visible={visibleActivate}
          onHide={() => setVisibleActive(false)}
          message={`Are you sure you want to activate ${data.name}?`}
          icon="pi pi-exclamation-triangle"
          accept={() => activateEvent(data)}
          reject={() => setVisibleActive(false)}
        />
        <Button
          icon="fa fa-play"
          ref={buttonEl}
          onClick={() => setVisibleActive(true)}
          className="p-button-rounded p-button-active p-mr-2"
        />
      </React.Fragment>
    ) : (
      data.eventState.toLowerCase() !== "finished" && (
        <React.Fragment>
          <ConfirmPopup
            target={buttonEl.current}
            visible={visibleFinish}
            onHide={() => setVisibleFinish(false)}
            message={`Are you sure you want to finish ${data.name}?`}
            icon="pi pi-exclamation-triangle"
            accept={() => finishEvent(data)}
            reject={() => setVisibleFinish(false)}
          />
          <Button
            icon="fa fa-flag-checkered"
            ref={buttonEl}
            onClick={() => setVisibleFinish(true)}
            className="p-button-rounded p-button-help p-mr-2"
          />
        </React.Fragment>
      )
    );
  };

  return (
    <div>
      <Table
        fields={showFields}
        data={events}
        loading={loading}
        headerText={L("Manage") + L("Events")}
        toolbar={true}
        createFields={createFields}
        editableFields={editableFields}
        onCreate={createEvent}
        onEdit={editEvent}
        deleteText={L("ConfirmDeletionEvent")}
        onDelete={deleteEvent}
        setShowDialog={setShowDialog}
        extendActionsBody={(rowData) => <ExtendedActionBody data={rowData} />}
        showDialog={showDialog}
        dialogHeaderText={{
          edit: L("EditEvent"),
          create: L("CreateEvent"),
        }}
      />
    </div>
  );
}

export default Events;
