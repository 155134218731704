import React from "react";

const AppFooter = () => {
  return (
    <div className="layout-footer">
      <div className="p-grid">
        <div className="p-col-12">
          <div className="footer-bottom">
            <h6>Copyright Ⓒ New Wave</h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppFooter;
