import { Button } from "primereact/button";
import React, { useState, useEffect, useContext } from "react";
import { errorRef } from "../../App";
import Table from "../../components/crud/Table";
import { RolesContext } from "../../context/Roles/rolesContext";
import { UserContext } from "../../context/User/userContext";
import { L } from "../../lib/abpUtility";
import userService from "../../services/user/userService";

function Users() {
  const [users, setUsers] = useState();
  const [loading, setLoading] = useState(true);
  const userContext = useContext(UserContext);
  const [showDialog, setShowDialog] = useState(false);
  const [roles, setRoles] = useState([]);
  const rolesContext = useContext(RolesContext);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(5);
  const [tableLoading, setTableLoading] = useState(false);
  const [filter, setFilter] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);

  const editableFields = [
    { name: "userName", type: "string" },
    { name: "name", type: "string" },
    { name: "surname", type: "string" },
    { name: "emailAddress", type: "string" },
    { name: "isActive", type: "bool", label: L("IsActive") },
    {
      name: "roleNames",
      type: "list_box",
      options: roles,
      valueField: "normalizedName",
      labelField: "normalizedName",
    },
  ];

  const createFields = [
    { name: "userName", type: "string" },
    { name: "name", type: "string" },
    { name: "surname", type: "string" },
    { name: "emailAddress", type: "string" },
    { name: "password", type: "password" },
    { name: "isActive", type: "bool", label: L("IsActive") },
    {
      name: "roleNames",
      type: "list_box",
      options: roles,
      valueField: "normalizedName",
      labelField: "normalizedName",
    },
  ];

  const handlePageChange = (e) => {
    setTableLoading(true);
    userContext
      .getAll({ SkipCount: e.first, MaxResultCount: e.rows, Keywords: filter })
      .then((res) => {
        setTotalRecords(res.totalCount);
        setUsers(res.items);
        setTableLoading(false);
      });
    setFirst(e.first);
    setRows(e.rows);
  };

  const getAllUsers = () => {
    setTableLoading(true);
    userContext
      .getAll({ MaxResultCount: rows, Keyword: filter })
      .then((res) => {
        rolesContext.getAll().then((roles_from_server) => {
          setRoles(roles_from_server.items);
          setUsers(res.items);
          setTotalRecords(res.totalCount);
          setLoading(false);
          setTableLoading(false);
        });
      });
  };

  useEffect(() => {
    getAllUsers();
  }, [rolesContext, userContext, filter]); // eslint-disable-line react-hooks/exhaustive-deps

  let timer;

  const showFields = [
    { field: "name", header: L("Name"), sortable: true, type: "string" },
    { field: "surname", header: L("Surname"), sortable: false, type: "string" },
    {
      field: "userName",
      header: L("UserName"),
      sortable: false,
      type: "string",
    },
    {
      field: "emailAddress",
      header: L("EmailAddress"),
      sortable: false,
      type: "string",
    },
  ];

  // Create user function that is passed to the dialog
  const createUser = (data) => {
    userContext.create(data).then((res) => {
      setUsers([...users, res]);
      setShowDialog(false);
    });
  };

  const syncUsers = () => {
    userService.syncUsers().then(() => {
      errorRef.current.show({
        severity: "success",
        summary: L("Success"),
        detail: L("UserSyncSuccess"),
        life: 6000,
      });
      getAllUsers();
    });
  };

  const extendRightHeader = () => {
    return (
      <div>
        <Button label={L("Sync")} onClick={() => syncUsers()} />
      </div>
    );
  };

  // The edit user function that is passed to the dialog

  const editUser = (data, selectedData) => {
    for (let item in data) {
      selectedData[item] = data[item];
    }
    userContext.update(selectedData).then((res) => {
      setUsers(users.map((user) => (user.id === res.id ? res : user)));
      setShowDialog(false);
    });
  };

  const deleteUser = (data) => {
    userContext.deleteUser({ id: data?.id }).then((res) => {
      setUsers(users.filter((user) => user.id !== data.id));
      setShowDialog(false);
    });
  };
  const handleGlobalFilterChange = (e) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      setFilter(e);
    }, 300);
  };

  return (
    <div>
      <Table
        fields={showFields}
        data={users}
        loading={loading}
        headerText={L("Manage") + L("Users")}
        toolbar={true}
        lazy={true}
        createFields={createFields}
        editableFields={editableFields}
        onCreate={createUser}
        onEdit={editUser}
        deleteText={L("ConfirmDeletionUser")}
        extendRightHeader={extendRightHeader}
        onDelete={deleteUser}
        handleGlobalFilterChange={handleGlobalFilterChange}
        setShowDialog={setShowDialog}
        showDialog={showDialog}
        first={first}
        totalRecords={totalRecords}
        rows={rows}
        handlePageChange={handlePageChange}
        tableLoading={tableLoading}
        dialogHeaderText={{
          edit: L("EditUser"),
          create: L("CreateUser"),
        }}
      />
    </div>
  );
}

export default Users;
