import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Button } from "primereact/button";
import { Galleria } from "primereact/galleria";
import "./productDetails.scss";
import { useEffect, useMemo, useState } from "react";
import { useCart } from "../../context/shop/shopContext";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useQuery } from "react-query";
import shopService from "../../services/shop/shopService";
import { composeShopImageName } from "../Items/shopUtils";
import { InputNumber } from "primereact/inputnumber";
import registrationService from "../../services/registration/registrationService";
import { Message } from "primereact/message";
import registrationItemsService from "../../services/registrationItems/registrationItemsService";
import localizationService from "../../services/localization/localizationService";
import { L } from "../../lib/abpUtility";

function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}

const registrationItemsProps = {
  MaxResultCount: 100000,
  SkipCount: 0,
};

export const ProductDetails = () => {
  const { id } = useParams();
  const { addToCart, isLoading } = useCart();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const registrationId = searchParams.get("registrationId");
  const { data: registration } = useQuery({
    queryKey: ["registration", registrationId],
    queryFn: () => registrationService.get(registrationId),
    enabled: !!registrationId,
  });

  const { data: registrationItems, refetch: refetchItems } = useQuery({
    queryKey: ["registrationItems", registrationItemsProps],
    queryFn: () => registrationItemsService.getAll(registrationItemsProps),
  });

  const [preselectImage, setPreselectImage] = useState(null);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      productId: id,
      quantity: 1,
    },
  });

  const watch = useWatch({
    control,
    name: ["color", "gender", "size"],
  });

  const { data: product } = useQuery({
    queryKey: ["shopItem", id],
    queryFn: () => shopService.get({ id }),
    enabled: !!id,
  });

  const maxQuantity = useMemo(() => {
    if (
      !product?.specifications?.quantities ||
      !watch?.[0] ||
      !watch?.[1] ||
      !watch?.[2]
    )
      return;

    const quantities =
      product.specifications.quantities?.find(
        (i) =>
          i.color === watch?.[0] &&
          i.gender === watch?.[1] &&
          i.size === watch?.[2]
      ) || [];

    const regItems =
      registrationItems?.items?.map((i) => i.specifications) ?? [];
    const numOfOrdered = regItems
      .filter(
        (i) =>
          i.productId === id &&
          i.color === watch[0] &&
          i.gender === watch[1] &&
          i.size === watch[2]
      )
      .map((i) => i.quantity ?? 0)
      .reduce((a, b) => a + b, 0);
    let productWholeQuantity = quantities?.quantity || 0;
    let productOrderedQuantity = numOfOrdered;
    return productWholeQuantity - productOrderedQuantity;
  }, [watch, product, id, registrationItems]);

  const sizes = useMemo(() => {
    if (!product?.specifications?.quantities || !watch?.[1]) return [];
    const quantities =
      product.specifications.quantities?.filter(
        (i) => i.gender === watch?.[1]
      ) || [];
    const sizes = quantities.map((i) => i?.size) || [];
    const uniqueSizes = sizes.filter(onlyUnique);
    return uniqueSizes;
  }, [watch, product]);

  const colors = useMemo(() => {
    if (!product?.specifications?.quantities || !watch?.[1] || !watch?.[2])
      return [];
    const quantities =
      product.specifications.quantities?.filter(
        (i) => i.gender === watch?.[1] && i.size === watch?.[2]
      ) || [];
    const colors = quantities.map((i) => i?.color) || [];
    const uniqueColors = colors.filter(onlyUnique);
    return uniqueColors;
  }, [watch, product]);

  useEffect(() => {
    if (!watch || !id) return;
    const [color, gender] = watch;
    if (!color || !gender) return;

    if (!product) return;

    const imageName = composeShopImageName(id, gender, color);

    const existingImage = product.images?.find((i) =>
      i.fullName.includes(imageName)
    );
    if (existingImage) {
      setPreselectImage(existingImage);
    }
  }, [watch, id, product]);

  const productSubmit = async (data) => {
    await addToCart(data, registrationId);
    reset();
    await refetchItems();
  };

  const itemTemplate = (item) => {
    return (
      <img
        src={preselectImage ? preselectImage.url : item.url}
        alt={item}
        style={{
          height: "400px",
          width: "600px",
          objectFit: "contain",
          viewTransitionName: "product-image-" + id,
        }}
      />
    );
  };

  const indicatorTemplate = (index) => {
    let image = product.images[index];
    return (
      <div>
        <img
          src={image.url}
          onMouseOver={() => {
            setPreselectImage(image);
          }}
          alt="indicator"
          style={{
            width: "50px",
            height: "50px",
            objectFit: "contain",
          }}
        />
      </div>
    );
  };

  return (
    <form className="card" onSubmit={handleSubmit(productSubmit)}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h5
          className="ml-5"
          onClick={() => navigate(-1)}
          style={{ cursor: "pointer" }}
        >
          <i className="fa fa-arrow-left mr-2" />
         {L("Back")} 
        </h5>
        {registrationId && registration && (
          <Message
            severity="info"
            text={`${L('CurrentlyShopingFor')} ${registration?.firstName} ${registration?.lastName}`}
            style={{ width: "300px" }}
          />
        )}
      </div>

      {product ? (
        <div className="productDetails">
          <div className="productDetails">
            <Galleria
              value={product?.images}
              showThumbnails={false}
              showIndicators
              showIndicatorsOnItem={false}
              indicatorsPosition={"left"}
              indicator={indicatorTemplate}
              item={itemTemplate}
            />
          </div>
          <div className="information">
            {product.specifications.title && (
              <h1>
                {localizationService.localizePipe(product.specifications.title)}
              </h1>
            )}
            {product.price && <h3>{product.price} лв.</h3>}
            {product.specifications.description && (
              <p className="description">
                {localizationService.localizePipe(
                  product.specifications.description
                )}
              </p>
            )}

            {product.specifications.genders && (
              <>
                <h5>
                  {L("Gender")}:{" "}
                  <span style={{ color: "red" }}>
                    {errors.gender && L("ThisFieldIsRequired")}
                  </span>
                </h5>
                <div className="genders">
                  <Controller
                    name="gender"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) =>
                      product.specifications.genders.map((gender) => (
                        <div
                          key={gender}
                          onClick={() => {
                            setValue("size", null);
                            setValue("color", null);
                            field.onChange(gender);
                          }}
                          className={`gender ${
                            field.value === gender && "selected"
                          }`}
                        >
                          <span>{L(gender).toUpperCase()}</span>
                        </div>
                      ))
                    }
                  />
                </div>
              </>
            )}

            <h5>
              {L("Size")}:{" "}
              <span style={{ color: "red" }}>
                {errors.size && L("ThisFieldIsRequired")}
              </span>
            </h5>
            {sizes?.length > 0 ? (
              <div className={`sizes ${!watch?.[1] && "grayed-out"}`}>
                <Controller
                  name="size"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) =>
                    sizes.map((size) => (
                      <div
                        key={size}
                        onClick={() => {
                          setValue("color", null);
                          field.onChange(size);
                        }}
                        className={`size ${field.value === size && "selected"}`}
                      >
                        <span>{L(size).toUpperCase()}</span>
                      </div>
                    ))
                  }
                />
              </div>
            ) : (
              <p>{L("ChooseGenderFirst")}</p>
            )}

            <h5>
              {L("Color")}:{" "}
              <span style={{ color: "red" }}>
                {errors.color && L("ThisFieldIsRequired")}
              </span>
            </h5>

            {colors?.length > 0 ? (
              <div
                className={`colors ${
                  (!watch?.[1] || !watch?.[2]) && "grayed-out"
                }`}
              >
                <Controller
                  name="color"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) =>
                    colors.map((color) => (
                      <div
                        key={color}
                        className={`color ${
                          field.value === color && "selected"
                        }`}
                        style={{ backgroundColor: `#${color}` }}
                        onClick={() => field.onChange(color)}
                      >
                        <i className="fa-solid fa-check"></i>
                      </div>
                    ))
                  }
                />
              </div>
            ) : (
              <p>{L("ChooseSizeFirst")}</p>
            )}

            <h5>{L("Quantity")}</h5>
            <span style={{ color: "red" }}>
              {errors.quantity && L("ThisFieldIsRequired")}
            </span>
            {maxQuantity < 1 && <p>{L("OutOfStock")}</p>}
            <div
              className={`quantity ${
                (!watch?.[0] || !watch?.[1] || !watch?.[2]) && "grayed-out"
              }`}
            >
              <Controller
                name="quantity"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <InputNumber
                    value={field.value}
                    onValueChange={(e) => field.onChange(e.value)}
                    showButtons
                    buttonLayout="horizontal"
                    disabled={maxQuantity < 1}
                    size={2}
                    min={1}
                    max={maxQuantity}
                    decrementButtonClassName="p-button-danger p-button-outlined"
                    incrementButtonClassName="p-button-primary p-button-outlined"
                    incrementButtonIcon="pi pi-plus"
                    decrementButtonIcon="pi pi-minus"
                  />
                )}
              />
              <br />
              <br />
              {maxQuantity > 0 && <p>{L("QuantityLeft")}: <span className="font-bold">{maxQuantity}</span></p>}
            </div>
            <br></br>
            <Button
              label={
                registration
                  ? `${L("PreOrderFor")} ${registration.firstName} ${registration.lastName}`
                  : `${L("PreOrder")} ${localizationService.localizePipe(
                      product.specifications.title
                    )}`
              }
              loading={isLoading}
              disabled={isLoading || maxQuantity < 1}
              style={{ width: 250 }}
            />
          </div>
        </div>
      ) : (
        <h1>Loading...</h1>
      )}
    </form>
  );
};
