import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import registrationItems from "../../services/registrationItems/registrationItemsService";
import { useUser } from "../../hooks/user/useUser";
import { useQuery } from "react-query";
import { errorRef } from "../../App";
import { L } from "../../lib/abpUtility";

export const ShopContext = React.createContext(null);

export const ShopContextWrapper = ({ children }) => {
  const user = useUser();
  const [cart, setCart] = useState([]);

  const {
    data,
    refetch: refetchCart,
    isFetching,
  } = useQuery({
    queryKey: ["registrationItems", user?.registrationId],
    queryFn: () =>
      registrationItems.getAll({
        Keyword: user?.registrationId,
      }),
    enabled: !!user?.registrationId,
  });

  useEffect(() => {
    let items = data?.items || [];
    setCart(items);
  }, [data]);

  const updateCart = useCallback(() => {
    refetchCart();
  }, [refetchCart]);

  const addToCart = useCallback(
    async (product, overrideRegistrationId) => {
      await registrationItems.create({
        registrationId: overrideRegistrationId || user?.registrationId,
        itemId: product.productId,
        qty: product.quantity,
        specifications: JSON.stringify(product),
      });

      updateCart();
      errorRef.current.show({
        severity: "success",
        summary: L("Congratulations"),
        detail: L("AddToCartSuccess"),
        life: 3000,
      });
    },
    [updateCart, user]
  );

  const removeFromCart = useCallback(
    async (productId) => {
      await registrationItems.delete({
        id: productId,
      });
      updateCart();

      errorRef.current.show({
        severity: "success",
        summary: L("Congratulations"),
        detail: L("RemoveFromCartSuccess"),
        life: 3000,
      });
    },
    [updateCart]
  );

  const shopValues = useMemo(() => {
    return { cart, addToCart, removeFromCart, isLoading: isFetching };
  }, [cart, addToCart, removeFromCart, isFetching]);

  return (
    <ShopContext.Provider value={shopValues}>{children}</ShopContext.Provider>
  );
};

export const useCart = () => {
  const contextData = useContext(ShopContext);
  return contextData;
};
