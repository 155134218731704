import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import React from "react";
import { Controller } from "react-hook-form";
import { InputTextarea } from "primereact/inputtextarea";
import { L } from "../../../lib/abpUtility";
import { Tag } from "primereact/tag";

function RegistrationEditStepThree({
  activities,
  data,
  control,
  register,
  isView,
}) {
  return (
    <section>
      <div>
        <h5 style={{ margin: "10px 0px" }} className="mr-2">
          {L("TentNumber")}
        </h5>

        <Controller
          name="tentNumber"
          control={control}
          defaultValue=""
          render={({ field, formState: { errors } }) => (
            <InputText
              className={`text-900 ${errors.tentNumber ? "p-invalid" : ""}`}
              type="text"
              disabled={isView}
              {...field}
            />
          )}
        />
      </div>
      <div>
        <h5 style={{ margin: "10px 0px" }} className="mr-2">
          {L("LicensePlate")}
        </h5>
        <Controller
          name="licensePlate"
          control={control}
          defaultValue=""
          render={({ field, formState: { errors } }) => (
            <InputText
              className={`text-900 ${errors.licensePlate ? "p-invalid" : ""}`}
              type="text"
              disabled={isView}
              {...field}
            />
          )}
        />
      </div>
      <div>
        <h5 style={{ margin: "10px 0px" }} className="mr-2">
          {L("PhoneNumber")}
        </h5>
        <Controller
          name="phoneNumber"
          control={control}
          defaultValue=""
          render={({ field, formState: { errors } }) => (
            <InputText
              className={`text-900 ${errors.phoneNumber ? "p-invalid" : ""}`}
              type="text"
              disabled={isView}
              {...field}
            />
          )}
        />
      </div>
      <div>
        <h5 className="mb-3 mt-2">{L("Volunteering")}</h5>

        {isView
          ? data?.activities?.map((item) => (
              <span
                key={item.activity}
                className="flex mb-1"
                style={{
                  gap: "5px",
                  flexWrap: "wrap",
                }}
              >
                <Tag
                  key={item.activity}
                  style={{ fontSize: "1rem" }}
                  value={L(item.text)}
                ></Tag>
              </span>
            ))
          : activities?.map((activity, index) => (
              <div
                key={activity.id}
                style={{ display: "flex" }}
                className="p-2"
              >
                <Controller
                  name={`personActivities.${activity.id}.activityId`}
                  defaultValue={
                    Boolean(data?.activities?.find((i) => i.id === activity.id))
                      ? activity.id
                      : null
                  }
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      onChange={(selected) =>
                        field.onChange(!field.value ? activity.id : null)
                      }
                      checked={field.value ? true : false}
                    />
                  )}
                />
                <label
                  style={{ color: "rgba(41, 50, 65, 0.8)" }}
                  className="ml-2"
                >
                  {L(activity.text)}
                </label>
              </div>
            ))}
      </div>
      <div>
        <h5 className="mb-3 mt-2">{L("AdditionalInfo")}</h5>
        <div>
          <Controller
            name="additionalInfo"
            control={control}
            defaultValue=""
            render={({ field, formState: { errors } }) => (
              <InputTextarea
                rows={10}
                autoResize
                disabled={isView ? true : false}
                {...field}
              />
            )}
          />
        </div>
      </div>
    </section>
  );
}

export default RegistrationEditStepThree;
