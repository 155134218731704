import React, { useContext, useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { AuthenticationContext } from "../../context/Authentication/authenticationContext";
import { Link } from "react-router-dom";
import { L } from "../../lib/abpUtility";


function Login() {
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
const [error, setError] = useState("")
  const authenticationContext = useContext(AuthenticationContext);
  useEffect(() => {
    watch(() => {
      setError("")
    })
  }, [watch])
  const onSubmit = async (data) => {
    setLoading(true);
    await authenticationContext
      .login(data)
      .then((res) => window.location.replace("/"))
      .catch((e) => {
        setError(e.response.data.error.details)
        setLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={`service-div`}>
        <React.Fragment>
          <h3>{L("LoginHere")}:</h3>
          {error &&
            <div className="error-message">
              {error}
            </div>
          }
          <InputText
            placeholder={L("Email")}
            disabled={loading}
            style={
              errors["userNameOrEmailAddress"] && {
                border: "1px solid red",
              }
            }
            {...register("userNameOrEmailAddress", { required: true })}
          />

          <Controller
            name="password"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field }) => (
              <Password
                disabled={loading}
                inputClassName={errors["password"] && "p-invalid"}
                {...field}
                placeholder={L("Password")}
                feedback={false}
              />
            )}
          />
          <Button label={L("LogIn")} disabled={loading} type="submit"></Button>

          <Link to={"/forgot-password"}>
            <button type="button" className="p-link forget-password">
              {L("ForgotPassword")}?
            </button>
          </Link>
          <p>
            {L("DoNotYouHaveAnAccount")}?{" "}
            <button type="button" className="p-link">
              <Link to={"/register"}>{L("SignUp")}</Link>
            </button>
          </p>
        </React.Fragment>
      </div>
    </form>
  );
}

export default Login;
