import roleService from '../../services/role/roleService';
import React from 'react';

export const RolesContext = React.createContext();

async function create(createRoleInput) {
    const result = await roleService.create(createRoleInput);
    return result
  }


  async function getRolesAsync(getRoleAsyncInput) {
    let result = await roleService.getRolesAsync(getRoleAsyncInput);
    return result;
  }


  async function update(updateRoleInput) {
    let result = await roleService.update(updateRoleInput);
    return result;
  }

  async function deleteRole(entityDto) {
    let result = await roleService.delete(entityDto);
    return result;
  }


  async function getAllPermissions() {
    var result = await roleService.getAllPermissions();
    return result;
  }


  async function getRoleForEdit(entityDto) {
    let result = await roleService.getRoleForEdit(entityDto);
    return result
  }

  async function get(entityDto) {
    var result = await roleService.get(entityDto);
    return result
  }


  async function getAll(pagedFilterAndSortedRequest) {
    let result = await roleService.getAll(pagedFilterAndSortedRequest);
    return result;
  }


export const roleContextValues = {
    create,
    getRolesAsync,
    update,
    deleteRole,
    getAllPermissions,
    getRoleForEdit,
    get,
    getAll
};
