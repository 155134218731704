import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React from "react";
import { L } from "../../../lib/abpUtility";
import { Controller } from "react-hook-form";
import { Calendar } from "primereact/calendar";
import { InputSwitch } from "primereact/inputswitch";

let abp = window.abp;

function RegistrationEditStepTwo({ register, control, isView, getValues }) {
  let days = getValues("days");
  let hasAccommodation = days?.find((i) => i?.accommodation?.id > 0)
    ? true
    : false;

  function calculateAge(birthday) {
    let ageDifMs = Date.now() - birthday;
    let ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }
  const monthNavigatorTemplate = (e) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        style={{ lineHeight: 1 }}
      />
    );
  };

  const yearNavigatorTemplate = (e) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        className="p-ml-2"
        style={{ lineHeight: 1 }}
      />
    );
  };

  return (
    <>
      <div>
        {abp.auth.hasPermission("Pages.Payments.Pay") && (
          <div className={"flex"} style={{ gap: 15, marginBottom: "10px" }}>
            <Controller
              name="isDraft"
              control={control}
              defaultValue={"false"}
              render={({ field, fieldState }) => (
                <div
                  className={"flex"}
                  style={{ gap: 15, marginBottom: "10px" }}
                >
                  <InputSwitch
                    checked={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    disabled={isView}
                    {...field}
                  />
                  <p>{L("IsDraft")}</p>
                </div>
              )}
            />
          </div>
        )}
        <h5 style={{ margin: "10px 0px" }} className="mr-2">
          {L("Name")}
        </h5>
        <Controller
          name="firstName"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field, formState: { errors } }) => (
            <InputText
              className={`text-900 ${errors.firstName ? "p-invalid" : ""}`}
              type="text"
              disabled={isView}
              {...field}
            />
          )}
        />
      </div>
      <div>
        <h5 style={{ margin: "10px 0px" }} className="mr-2">
          {L("MiddleName")}
        </h5>
        <Controller
          name="middleName"
          control={control}
          defaultValue=""
          rules={{ required: hasAccommodation }}
          render={({ field, formState: { errors } }) => (
            <InputText
              className={`text-900 ${errors.middleName ? "p-invalid" : ""}`}
              type="text"
              disabled={isView}
              {...field}
            />
          )}
        />
      </div>
      <div>
        <h5 style={{ margin: "10px 0px" }} className="mr-2">
          {L("Surname")}
        </h5>
        <Controller
          name="lastName"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field, formState: { errors } }) => (
            <InputText
              className={`text-900 ${errors.lastName ? "p-invalid" : ""}`}
              type="text"
              disabled={isView}
              {...field}
            />
          )}
        />
      </div>
      <div>
        <h5 style={{ margin: "10px 0px" }} className="mr-2">
          {L("BirthDate")} {isView ? " - " + L("Age") : ""}
        </h5>
        <Controller
          name="birthDate"
          control={control}
          rules={{ required: true }}
          render={({ field, formState: { errors } }) =>
            isView ? (
              <InputText
                className={`text-900 ${errors.birthDate ? "p-invalid" : ""}`}
                type="text"
                value={
                  new Date(field.value).toDateString() +
                  " - " +
                  calculateAge(new Date(field.value))
                }
                disabled={true}
              />
            ) : (
              <Calendar
                monthNavigator
                yearNavigator
                monthNavigatorTemplate={monthNavigatorTemplate}
                yearNavigatorTemplate={yearNavigatorTemplate}
                maxDate={new Date()}
                yearRange={"1940:" + new Date().getFullYear()}
                {...field}
                value={field.value ? new Date(field.value) : null}
              />
            )
          }
        />
      </div>
      <div>
        <h5 style={{ margin: "10px 0px" }} className="mr-2">
          {L("City")}
        </h5>
        <Controller
          name="city"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field, formState: { errors } }) => (
            <InputText
              className={`text-900 ${errors.city ? "p-invalid" : ""}`}
              type="text"
              disabled={isView}
              {...field}
            />
          )}
        />
      </div>
      <div>
        <h5 style={{ margin: "10px 0px" }} className="mr-2">
          {L("Church")}
        </h5>
        <Controller
          name="church"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field, formState: { errors } }) => (
            <InputText
              className={`text-900 ${errors.church ? "p-invalid" : ""}`}
              type="text"
              disabled={isView}
              {...field}
            />
          )}
        />
      </div>

      <div>
        <h5 style={{ margin: "10px 0px" }} className="mr-2">
          {L("UCN")}
        </h5>
        <Controller
          name="ucn"
          control={control}
          defaultValue=""
          rules={{ required: hasAccommodation }}
          render={({ field, formState: { errors } }) => (
            <InputText
              className={`text-900 ${errors.ucn ? "p-invalid" : ""}`}
              type="text"
              disabled={isView}
              {...field}
            />
          )}
        />
      </div>
      <div>
        <h5 style={{ margin: "10px 0px" }} className="mr-2">
          {L("Address")}
        </h5>
        <Controller
          name="address"
          control={control}
          defaultValue=""
          rules={{ required: hasAccommodation }}
          render={({ field, formState: { errors } }) => (
            <InputText
              className={`text-900 ${errors.address ? "p-invalid" : ""}`}
              type="text"
              disabled={isView}
              {...field}
            />
          )}
        />
      </div>
    </>
  );
}

export default RegistrationEditStepTwo;
