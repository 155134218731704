import React, { useContext, useEffect, useState } from "react";
import NavBar from "../../components/NavBar/NavBar";
import AppFooter from "../../components/menus/AppFooter";
import { L } from "../../lib/abpUtility";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import ProtectedAuthLink from "../../components/routing/ProtectedAuthLink";
import { Card } from "primereact/card";
import { EventContext } from "../../context/Event/eventContext";
import EditorWrapper from "../../components/Editor/Editor";
import localizationService from "../../services/localization/localizationService";

let abp = window.abp;

const Home = () => {
  let cultureName = abp.localization.currentCulture?.name;

  const { lastEvent } = useContext(EventContext);
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const welcomeImageUrl = "./images/new-wave-background-new.jpg";

  useEffect(() => {
    const lastEventName = localizationService.localizePipe(lastEvent?.name);
    if (lastEventName) {
      setName(lastEventName);
    }

    const lastEventLocation = localizationService.localizePipe(
      lastEvent?.location
    );
    
    if (lastEventLocation) {
      setLocation(lastEventLocation);
    }
  }, [lastEvent]);

  function getDayRange(startDate, endDate) {
    const startDay = new Date(startDate).getDate();
    const endDay = new Date(endDate).getDate();

    return [startDay, endDay].join("-");
  }

  return (
    <div>
      <div className="landing-body">
        <NavBar style={{ position: "relative" }} />
        <div
          className="col-12 lg:col-12"
          style={{
            background: `url(${welcomeImageUrl}) no-repeat`,
            backgroundPosition: "center top",
            padding: "50px 10px 95px 10px",
          }}
        >
          <span
            className="p-5 h-full flex flex-column col-12 lg:col-9"
            style={{
              margin: "0 auto",
              fontFamily: "Comfortaa",
            }}
          >
            <div className="text-center text-0 mb-3 text-5xl md:text-6xl sm:text-4xl lg:text-8xl xl:text-8xl">
              <b>{name}</b>
            </div>

            <div className="text-center text-0 text-2xl sm:text-3xl lg:text-7xl md:text-5xl dates-home">
              {lastEvent &&
                getDayRange(lastEvent?.startDate, lastEvent?.endDate)}{" "}
              {lastEvent &&
                new Date(lastEvent.startDate)
                  .toLocaleString(cultureName === "bg" ? "bg" : "en-US", {
                    month: "long",
                  })
                  .toUpperCase()}{" "}
              {location}
            </div>
          </span>

          <span
            className="shadow-2 p-3 h-full flex flex-column  surface-card lg:col-10 md:col-11 xs:col-12"
            style={{
              borderRadius: "10px",
              margin: "0 auto",
            }}
          >
            <div className="flex align-items-center flex-column lg:flex-row">
              {/* TODO: Un-comment once there is a promo video */}
              {/* <Card className="lg:col-6 col-12">
                <div style={{ position: "relative", paddingBottom: "56.25%" }}>
                  <iframe
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                    }}
                    title="facebookPromo"
                    src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fnewwavebulgaria%2Fvideos%2F1089045238635744%2F&show_text=false&width=560&t=0"
                    scrolling="no"
                    frameBorder="0"
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    allowFullScreen={true}
                  ></iframe>
                </div>
              </Card> */}

              <div className="grid lg:col-6 md:col-8 col-10 justify-content-center grid-nogutter surface-section text-800 flex mt-3 mb-3 mr-5 ml-5">
                <section>
                  {/* Event states: Draft(1), Active(2), Finished(3) */}
                  {lastEvent?.eventState?.value === 1 && (
                    <React.Fragment>
                      <div className="text-4xl text-primary font-bold mb-5">
                        {L("ComingSoon")}
                      </div>
                      <ProtectedAuthLink auth={false}>
                        <p className="mt-1 text-700 line-height-3">
                          {L("ComingSoonDetails")}
                        </p>
                      </ProtectedAuthLink>{" "}
                    </React.Fragment>
                  )}
                  {lastEvent?.eventState?.value === 2 && (
                    <React.Fragment>
                      <div className="text-4xl text-primary font-bold mb-5">
                        {L("RegisterYourself")}
                      </div>
                      <ProtectedAuthLink auth={false}>
                        <p className="mt-1 text-700 line-height-3">
                          {L("RegisterLoggedOut")}
                          <u>
                            <b>{L("RegisterLoggedOutRequired")}</b>
                          </u>
                        </p>
                      </ProtectedAuthLink>{" "}
                      <ProtectedAuthLink auth={true}>
                        <p className="mt-1 mb-5 text-700 line-height-3">
                          {L("RegisterLoggedIn")} "{L("Registrations")}".
                        </p>
                      </ProtectedAuthLink>
                    </React.Fragment>
                  )}
                  {lastEvent?.eventState?.value === 3 && (
                    <React.Fragment>
                      <div className="text-4xl text-primary font-bold mb-5">
                        {L("ThankYouVisiting")}
                      </div>
                      <ProtectedAuthLink auth={false}>
                        <p className="mt-1 text-700 line-height-3">
                          {L("ThankYouVisitingDetails")}
                        </p>
                      </ProtectedAuthLink>{" "}
                    </React.Fragment>
                  )}

                  <div className="flex flex-column">
                    <div
                      className=" mb-5"
                      style={{
                        gap: "10px",
                        flexWrap: "wrap",
                      }}
                    >
                      {lastEvent?.eventState?.value === 2 && (
                        <React.Fragment>
                          <ProtectedAuthLink auth={false}>
                            <Link to="/register">
                              <Button
                                label={L("CreateProfileBtn")}
                                type="button"
                                className="mb-3 mr-3 p-button-raised"
                              ></Button>
                            </Link>
                            <Link to="/registration/step1">
                              <Button
                                label={L("RegisterWithoutProfileBtn")}
                                type="button"
                                className="mb-3 p-button-outlined"
                              ></Button>
                            </Link>
                          </ProtectedAuthLink>
                          <ProtectedAuthLink auth={true}>
                            <Link to="/registration/step1">
                              <Button
                                label={L("Registration")}
                                type="button"
                                className="mb-0 p-button-raised"
                              ></Button>
                            </Link>
                          </ProtectedAuthLink>
                        </React.Fragment>
                      )}
                    </div>
                    <div>
                      {lastEvent?.eventState?.value === 2 && (
                        <React.Fragment>
                          <Link to="/trainings" className="mt-2">
                            <h6>
                              <i className="pi pi-caret-right p-1"></i>
                              {L("ForMoreDetailsWatch")}
                              <u>
                                <b>{L("OurTrainings")}</b>
                              </u>
                              .
                            </h6>
                          </Link>
                          <Link to="/fees-and-documents" className="mt-2">
                            <h6>
                              <i className="pi pi-caret-right p-1"></i>
                              {L("ForMoreInfo")}
                              <u>
                                <b>{L("Information")}</b>
                              </u>
                              .
                            </h6>
                          </Link>
                          <Link to="/camp-program" className="mt-2">
                            <h6>
                              <i className="pi pi-caret-right p-1"></i>
                              {L("SeeOurFem")}
                              <u>
                                <b>{L("Program")}</b>
                              </u>
                              .
                            </h6>
                          </Link>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </section>
              </div>

              <Card>
                <div style={{ position: "relative" }}>
                  <iframe
                    src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fnewwavebulgaria%2F&tabs=timeline&width=420&height=420&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=true&appId"
                    title="facebook feed"
                    width="420"
                    height="420"
                    scrolling="no"
                    frameBorder="0"
                    allowFullScreen={false}
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                  ></iframe>
                </div>
              </Card>
            </div>
          </span>
        </div>
        <div className="surface-section px-4 py-8 md:px-6 lg:px-8 text-center">
          <div className="mb-5 text-2xl">
            <EditorWrapper name="camp-program"></EditorWrapper>
          </div>
          {/* <div className="mb-8 font-bold text-4xl">
            <Link to="/camp-program">
              <span
                className="p-3 shadow-2 mb-3 inline-block surface-card"
                style={{ borderRadius: "10px" }}
              >
                <i className="pi pi-clock text-4xl text-primary"></i>
              </span>
              <div className="text-900 mb-3 font-medium">{L("Program")}</div>
              <span className="text-700 text-sm line-height-3">
                <p>{L("ProgramShortDescription")}</p>
                <Button>{L("SeeMore")}</Button>
              </span>
            </Link>
          </div> */}
          <div className="mb-8 font-bold text-4xl">
            <span className="text-900">{L("SummerCamp")} - </span>
            <span className="text-primary">{L("Information")}</span>
          </div>
          <div className="grid">
            <div className="col-12 md:col-4 mb-7">
              <Link to="/mission-and-vision">
                <span
                  className="p-3 shadow-2 mb-3 inline-block surface-card"
                  style={{ borderRadius: "10px" }}
                >
                  <i className="pi pi-heart-fill text-4xl text-primary"></i>
                </span>
                <div className="text-900 mb-3 font-medium">
                  {L("MissionAndVision")}
                </div>
                <span className="text-700 text-sm line-height-3">
                  <p>
                    "{L("NewWave")}" {L("MissionAndVisionShortDescription")}
                  </p>
                  <Button>{L("SeeMore")}</Button>
                </span>
              </Link>
            </div>
            <div className="col-12 md:col-4 mb-7">
              <Link to="/conditions">
                <span
                  className="p-3 shadow-2 mb-3 inline-block surface-card"
                  style={{ borderRadius: "10px" }}
                >
                  <i className="pi pi-check-circle text-4xl text-primary"></i>
                </span>
                <div className="text-900 mb-3 font-medium">
                  {L("Conditions")}
                </div>
                <span className="text-700 text-sm line-height-3">
                  <p>{L("ConditionsShortDescription")}</p>
                  <Button>{L("SeeMore")}</Button>
                </span>
              </Link>
            </div>
            <div className="col-12 md:col-4 mb-7">
              <Link to="/camp-rules">
                <span
                  className="p-3 shadow-2 mb-3 inline-block surface-card"
                  style={{ borderRadius: "10px" }}
                >
                  <i className="pi pi-book text-4xl text-primary"></i>
                </span>
                <div className="text-900 mb-3 font-medium">
                  {L("CampRules")}
                </div>
                <span className="text-700 text-sm line-height-3">
                  <p>{L("CampRulesShortDescription")}</p>
                  <Button>{L("SeeMore")}</Button>
                </span>
              </Link>
            </div>
            <div className="col-12 md:col-4 mb-7">
              <Link to="/fees-and-documents">
                <span
                  className="p-3 shadow-2 mb-3 inline-block surface-card"
                  style={{ borderRadius: "10px" }}
                >
                  <i className="pi pi-briefcase text-4xl text-primary"></i>
                </span>
                <div className="text-900 mb-3 font-medium">
                  {L("FeesAndDocuments")}
                </div>
                <span className="text-700 text-sm line-height-3">
                  <p>{L("FeesAndDocumentsShortDescription")}</p>
                  <Button>{L("SeeMore")}</Button>
                </span>
              </Link>
            </div>
            <div className="col-12 md:col-4 mb-7">
              <Link to="/brother-support">
                <span
                  className="p-3 shadow-2 mb-3 inline-block surface-card"
                  style={{ borderRadius: "10px" }}
                >
                  <i className="pi pi-users text-4xl text-primary"></i>
                </span>
                <div className="text-900 mb-3 font-medium">
                  {L("BrotherSupport")}
                </div>
                <span className="text-700 text-sm line-height-3">
                  <p>
                    "{L("BrotherSupport")}"{" "}
                    {L("BrotherSupportShortDescription")}
                  </p>
                  <Button>{L("SeeMore")}</Button>
                </span>
              </Link>
            </div>
            <div className="col-12 md:col-4 mb-7">
              <Link to="/data-security">
                <span
                  className="p-3 shadow-2 mb-3 inline-block surface-card"
                  style={{ borderRadius: "10px" }}
                >
                  <i className="pi pi-shield text-4xl text-primary"></i>
                </span>
                <div className="text-900 mb-3 font-medium">
                  {L("DataSecurity")}
                </div>
                <span className="text-700 text-sm line-height-3">
                  <p>
                    "{L("NewWave")}" {L("DataSecurityShortDescription")}
                  </p>
                  <Button>{L("SeeMore")}</Button>
                </span>
              </Link>
            </div>
          </div>
        </div>

        <div className="px-4 py-8 md:px-6 lg:px-8 text-center">
          <div className="mb-7 font-bold text-4xl">
            <span className="text-900">{L("WhereToFindUs")}</span>
          </div>
          <div className="grid">
            <div className="col-12 md:col-6 mb-3 p-2 flex flex-column justify-content-between">
              <div className="sm:mb-5">
                <h4 className="text-left mb-5">{L("SocialNetworks")}</h4>
                <div className="grid gap-1">
                  <a
                    href="https://www.facebook.com/newwavebulgaria/"
                    target="_blank"
                  >
                    <button className="mx-2 bg-white border-none border-circle p-3 cursor-pointer hover:bg-primary transition-all transition-duration-400">
                      <i className="pi pi-facebook text-2xl" />
                    </button>{" "}
                  </a>
                  <a href="https://www.youtube.com/c/novavalna" target="_blank">
                    <button className="mx-2 bg-white border-none border-circle p-3 cursor-pointer hover:bg-primary transition-all transition-duration-400">
                      <i className="pi pi-youtube text-2xl" />
                    </button>{" "}
                  </a>
                  <a href="https://novavalna.org" target="_blank">
                    <button className="mx-2 bg-white border-none border-circle p-3 cursor-pointer hover:bg-primary transition-all transition-duration-400">
                      <i className="pi pi-globe text-2xl" />
                    </button>{" "}
                  </a>
                </div>
              </div>
              <div>
                <h4 className="text-left">{L("QuestionsEmailUs")}</h4>
                <h4 className="text-left cursor-pointer mt-2">
                  <a href="mailto:camp@novavalna.org" className="text-color">
                    camp@novavalna.org
                  </a>
                </h4>
              </div>
              <div className="mt-3">
                <h4 className="text-left">{L("ForPaymentAndGiving")}</h4>
                <h5 className="text-left mb-2">Сдружение Нова вълна</h5>
                <h5 className="text-left mt-1 mb-4">
                  IBAN BG72UNCR70001522969584
                </h5>
              </div>
            </div>
            <div className="col-12 md:col-6 mb-4 px-5">
              <iframe
                width="100%"
                height="350px"
                title="gmap_canvas"
                src="https://maps.google.com/maps?q=%D0%90%D1%85%D0%B5%D0%BB%D0%BE%D0%B9,%20%D1%81%D1%82%D0%B0%D0%B4%D0%B8%D0%BE%D0%BD&t=k&z=13&ie=UTF8&iwloc=&output=embed"
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
              ></iframe>
            </div>
          </div>
        </div>

        <div className="landing-mask">
          <AppFooter></AppFooter>
        </div>
      </div>
    </div>
  );
};

export default Home;
