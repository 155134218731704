import http from "../httpService";

class RegistrationService {
  async getAccommodations() {
    let result = await http.get(
      "api/services/app/ReferenceData/GetAccommodations"
    );
    return result.data.result;
  }
  async getPresences() {
    let result = await http.get("api/services/app/ReferenceData/GetPresences");
    return result.data.result;
  }

  async getDayTypes() {
    let result = await http.get("api/services/app/ReferenceData/GetDaysType");
    return result.data.result;
  }

  async getActivities() {
    let result = await http.get("api/services/app/ReferenceData/GetActivities");
    return result.data.result;
  }

  async getDays(lastEvent) {
    let result = await http.get(
      "/api/services/app/EventDays/GetEventDaysByEventId",
      { params: { eventId: lastEvent?.id } }
    );
    return result.data.result;
  }
  async getAll(params) {
    let result = await http.get("api/services/app/Registrations/GetAll", {
      params: params,
    });
    return result.data.result;
  }
  async get(id) {
    let result = await http.get("api/services/app/Registrations/GetById", {
      params: {
        id: id,
      },
    });
    return result.data.result;
  }
  async getPersonByUserId(userId) {
    let result = await http.get(
      "api/services/app/Registrations/GetPersonByUserId",
      {
        params: {
          id: userId,
        },
      }
    );
    return result.data.result;
  }
  async create(data) {
    let result = await http.post("api/services/app/Registrations/Create", data);
    return result.data.result;
  }
  async update(data) {
    let result = await http.put("api/services/app/Registrations/Update", data);
    return result.data;
  }

  async deleteRegistrations(data) {
    let result = await http.delete("api/services/app/Registrations/Delete", {
      params: data,
    });
    return result.data.result;
  }
  async attachUser(userId, registrationId) {
    let result = await http.post(
      "/api/services/app/RegistrationsGroups/Attach",
      { userId, registrationId }
    );
    return result.data.result;
  }

  async dettachUser(registrationId) {
    let result = await http.post(
      "/api/services/app/RegistrationsGroups/Dettach",
      { registrationId }
    );
    return result.data.result;
  }
  async getAllGroupLeaders(params) {
    let result = await http.get(
      "/api/services/app/RegistrationsGroups/GetAllGroupLeaders",
      { params: params }
    );
    return result.data.result;
  }
  async getAllRegistrationsInGroups(params) {
    let result = await http.get(
      "/api/services/app/RegistrationsGroups/GetAll",
      {
        params: {
          "UserRoleRegistrationFilter.RegistrationId": params,
        },
      }
    );
    return result.data.result;
  }
  async getPaymentTypes() {
    let result = await http.get(
      "/api/services/app/ReferenceData/GetPaymentsType"
    );
    return result.data.result;
  }
  async getBulkPaymentDetails(params) {
    let result = await http.get(
      "/api/services/app/Payments/GetBulkPaymentDetails",
      {
        params,
      }
    );
    return result.data.result;
  }

  async assignUserToRegistration(params) {
    let result = await http.post(
      "/api/services/app/Registrations/AssignUserToRegistration",
      params
    );
    return result.data.result;
  }
  async changeGroupLeader(params) {
    let result = await http.post(
      "/api/services/app/RegistrationsGroups/ChangeGroupLeader",
      {
        originalUserId: params.oldGroupLeader.userId,
        changedUserId: params.newGroupLeader.userId,
      }
    );
    return result.data.result;
  }
  async getDuesRegistration(id) {
    let result = await http.get(
      "api/services/app/Dues/GetDuesByRegistrationId",
      {
        params: {
          RegistrationId: id,
        },
      }
    );
    return result.data.result;
  }
  async chargeDueRegistration(data) {
    let result = await http.post("api/services/app/Dues/Charge", {
      ...data,
    });
    return result.data.result;
  }

  async bulkPay(data) {
    let result = await http.post("api/services/app/Payments/BulkPayment", {
      ...data,
    });
    return result.data.result;
  }
  async getGroupDetails(assignToGroup) {
    let result = await http.get("api/services/app/Registrations/GetGroupDetails", {
      params: {
        assignToGroup,
      },
    });
    return result.data.result;
  }
  async getPaymentsRegistration(id) {
    let result = await http.get(
      "api/services/app/Payments/GetByRegistrationId",
      {
        params: {
          RegistrationId: id,
        },
      }
    );
    return result.data.result;
  }
  async payRegistration(data) {
    let result = await http.post("api/services/app/Payments/Pay", {
      ...data,
    });
    return result.data.result;
  }
}

export default new RegistrationService();
