import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { Dialog } from "primereact/dialog";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";

const aspect = 3.5 / 4;

export default forwardRef(function FileUploader(
  {
    onSelect,
    multiple = false,
    useCrop = false,
    onClear = () => {},
    onRemove = () => {},
    style = {},
  },
  ref
) {
  const [originalFile, setOriginalFile] = useState(null);
  const [finalImage, setFinalImage] = useState(null);
  const [crop, setCrop] = useState(undefined);
  const [imgSrc, setImgSrc] = useState("");
  const [cropDialog, setCropDialog] = useState();
  const fileUploadRef = useRef(null);

  useImperativeHandle(
    ref,
    () => {
      return {
        clear: () => {
          fileUploadRef.current?.clear();
        },
      };
    },
    []
  );

  const headerTemplate = (options) => {
    const { className, chooseButton, cancelButton } = options;

    return (
      <div
        className={className}
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
        }}
      >
        {chooseButton}
        {cancelButton}
      </div>
    );
  };

  const itemTemplate = (file, props) => {
    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center">
          <img
            alt={file.name}
            role="presentation"
            src={finalImage ?? file.objectURL}
            width={60}
          />
          <span className="flex flex-column text-left ml-5">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Button
          type="button"
          icon="pi pi-times"
          className="p-button-outlined p-button-rounded p-button-danger ml-auto"
          onClick={props.onRemove}
        />
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i
          className="pi pi-image mt-3 p-5"
          style={{
            fontSize: "5em",
            borderRadius: "50%",
            backgroundColor: "var(--surface-b)",
            color: "var(--surface-d)",
          }}
        ></i>
        <span
          style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }}
          className="my-5"
        >
          Drag and Drop Image Here
        </span>
      </div>
    );
  };

  const chooseOptions = {
    icon: "pi pi-fw pi-images",
    iconOnly: true,
    className: "custom-choose-btn p-button-rounded p-button-outlined",
  };

  const cancelOptions = {
    icon: "pi pi-fw pi-times",
    iconOnly: true,
    className:
      "custom-cancel-btn p-button-danger p-button-rounded p-button-outlined",
  };

  const customOnSelect = (e) => {
    if (!useCrop) onSelect(e);

    setOriginalFile(e);
    const reader = new FileReader();
    reader.addEventListener("load", () =>
      setImgSrc(reader.result?.toString() || "")
    );
    reader.readAsDataURL(e.files?.[0]);

    setCropDialog(true);
  };

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 100,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }

  const renderCropFooter = () => {
    return (
      <div>
        <Button
          label="Crop Image"
          className="mt-5"
          onClick={onCropImageClicked}
        />
      </div>
    );
  };

  function onCropImageClicked() {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const image = new Image();
    image.src = imgSrc;

    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const dWidth = image.width * (crop.width / 100);
    const dHeight = image.height * (crop.height / 100);

    canvas.width = Math.floor(dWidth * scaleX * pixelRatio);
    canvas.height = Math.floor(dHeight * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = "high";
    ctx.save();

    const dCropX = crop.x * (image.width / 100);
    const dCropY = crop.y * (image.height / 100);
    const cropX = dCropX * scaleX;
    const cropY = dCropY * scaleY;

    ctx.translate(-cropX, -cropY);
    ctx.drawImage(
      image,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight
    );

    ctx.restore();

    const bast64Str = canvas.toDataURL("image/png");
    setFinalImage(bast64Str);

    canvas.toBlob((blob) => {
      setCropDialog(false);
      onSelect(blob, originalFile);
    });
  }

  return (
    <div style={style}>
      <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
      <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />

      <FileUpload
        ref={fileUploadRef}
        onSelect={customOnSelect}
        onClear={onClear}
        onRemove={onRemove}
        multiple={multiple}
        accept="*"
        maxFileSize={100000000}
        headerTemplate={headerTemplate}
        itemTemplate={itemTemplate}
        emptyTemplate={emptyTemplate}
        chooseOptions={chooseOptions}
        cancelOptions={cancelOptions}
      />

      <Dialog
        style={{ width: "50vw" }}
        visible={cropDialog}
        onHide={() => setCropDialog(false)}
        footer={renderCropFooter}
      >
        {!!imgSrc && (
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            aspect={aspect}
            minHeight={100}
          >
            <img alt="Crop me" src={imgSrc} onLoad={onImageLoad} />
          </ReactCrop>
        )}
      </Dialog>
    </div>
  );
});
