import { useQuery, useQueryClient } from "react-query";
import shopService from "../../services/shop/shopService";
import { Dialog } from "primereact/dialog";
import { BlockUI } from "primereact/blockui";
import { useEffect, useMemo, useRef, useState } from "react";
import { composeShopImageName, composeShopImageNameWithId } from "./shopUtils";
import { v4 as uuidv4 } from "uuid";
import filesService from "../../services/files/filesService";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { ColorPicker } from "primereact/colorpicker";
import { InputText } from "primereact/inputtext";
import FileUploader from "../../components/fileUpload/FileUploader";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";

export const GenderColorImagesDialog = ({
  visible,
  setVisible,
  selectedData,
  setSelectedData,
}) => {
  const close = () => {
    setVisible(false);
    setSelectedData(null);
  };

  const { data, isLoading, refetch, isFetching } = useQuery({
    queryKey: ["items", selectedData?.id],
    queryFn: () => shopService.get({ id: selectedData?.id }),
    enabled: !!selectedData,
  });

  return (
    <Dialog
      visible={visible}
      style={{ width: "50vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      onHide={() => {
        setVisible(false);
        setSelectedData(null);
      }}
      draggable={false}
    >
      {selectedData?.specifications.genders &&
      selectedData.specifications?.colors
        ? selectedData?.specifications.genders?.map((gender) => (
            <BlockUI blocked={isLoading} key={`${selectedData.id}_${gender}`}>
              <div key={gender}>
                <h4>{gender}</h4>

                {selectedData.specifications?.colors.map((color) => (
                  <RenderSingleGenderColor
                    isFetching={isFetching}
                    key={color}
                    color={color}
                    gender={gender}
                    itemId={selectedData.id}
                    sizes={selectedData.specifications?.sizes || []}
                    close={close}
                    data={data}
                    refetch={refetch}
                  />
                ))}
              </div>
            </BlockUI>
          ))
        : selectedData && <UpdateImages itemId={selectedData.id} data={data} />}
    </Dialog>
  );
};

const UpdateImages = ({ data, itemId }) => {
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const fileUploadRef = useRef(null);

  const onUpload = async (file) => {
    const imageId = uuidv4();

    setLoading(true);
    if (!file) return;

    const type = file.type.split("/").pop();
    const name = `${itemId}_${imageId}.${type}`;

    const response = await filesService.uploadFile(file, name, imageId);

    if (!response) return;

    const prev = data.images || [];

    data.images = [...prev, response];

    fileUploadRef.current.clear();
    await shopService.update(data);
    await queryClient.invalidateQueries("items");
    setLoading(false);
  };

  const onImageDelete = async (fullName) => {
    setLoading(true);
    const prev = data.images || [];

    data.images = prev.filter((image) => image.fullName !== fullName);

    await shopService.update(data);
    await filesService.delete({ fullName });
    queryClient.invalidateQueries("items");
    setLoading(false);
  };

  const confirmImageDeletion = (event, fullName) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Are you sure you want to proceed?",
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "accept",
      accept: () => onImageDelete(fullName),
      reject: () => {},
    });
  };

  return (
    <BlockUI blocked={loading}>
      <FileUploader ref={fileUploadRef} onSelect={onUpload} useCrop={true} />
      <ConfirmPopup />
      <br />
      <div className="flex" style={{ gap: "10px" }}>
        {data?.images?.map(
          (image) =>
            image.url && (
              <div key={image.id} style={{ position: "relative" }}>
                <img
                  alt=""
                  src={image.url}
                  style={{
                    width: "150px",
                    aspectRatio: "3.5 / 4",
                    objectFit: "contain",
                  }}
                />
                <Button
                  icon={"pi pi-trash"}
                  style={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                  }}
                  className="p-button-danger"
                  onClick={(event) =>
                    confirmImageDeletion(event, image.fullName)
                  }
                />
              </div>
            )
        )}
      </div>
    </BlockUI>
  );
};

const RenderSingleGenderColor = ({
  color,
  gender,
  itemId,
  data,
  sizes,
  refetch,
  isFetching,
}) => {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [quantityValues, setQuantityValues] = useState([]);
  const fileUploadRef = useRef(null);

  useEffect(() => {
    if (data?.specifications?.quantities) {
      setQuantityValues(data.specifications.quantities || []);
    }
  }, [data, isFetching]);

  const image_gen_name = useMemo(() => {
    return composeShopImageName(itemId, gender, color);
  }, [itemId, gender, color]);

  const onUpload = async (file) => {
    const imageId = uuidv4();

    setLoading(true);
    if (!file) return;

    const type = file.type.split("/").pop();
    const name = `${composeShopImageNameWithId(
      itemId,
      gender,
      color,
      imageId
    )}.${type}`;

    const response = await filesService.uploadFile(file, name, imageId);

    if (!response) return;

    const prev = data.images || [];

    data.images = [...prev, response];

    fileUploadRef.current?.clear();

    await shopService.update(data);
    await queryClient.invalidateQueries("items");
    setLoading(false);
  };

  const onImageDelete = async (fullName) => {
    setLoading(true);
    const prev = data.images || [];

    data.images = prev.filter((image) => image.fullName !== fullName);

    await shopService.update(data);
    await filesService.delete({ fullName });
    queryClient.invalidateQueries("items");
    setLoading(false);
  };

  const onSaveChangesClicked = async () => {
    setLoading(true);
    let newData = data;
    newData.specifications.quantities = quantityValues;

    await shopService.update(newData);
    await refetch();
    setLoading(false);
  };

  const confirmImageDeletion = (event, fullName) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Are you sure you want to proceed?",
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "accept",
      accept: () => onImageDelete(fullName),
      reject: () => {},
    });
  };
  return (
    <BlockUI blocked={loading}>
      <ConfirmPopup />
      <div className="mb-3">
        <ColorPicker className="mr-2" value={color} disabled />
        <InputText value={`#${color}`} disabled />
        <Button
          className="ml-3"
          type="button"
          onClick={() => navigator?.clipboard.writeText(color)}
        >
          Copy
        </Button>
      </div>
      <div>
        <h5>Sizes:</h5>
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "center",
            flexDirection: "column",
            flexWrap: "wrap",
          }}
        >
          {sizes.map((size) => (
            <SizeComponentToReduceReRenders
              size={size}
              key={size}
              quantityValues={quantityValues}
              setQuantityValues={setQuantityValues}
              gender={gender}
              refetch={refetch}
              color={color}
            />
          ))}
          <Button
            onClick={onSaveChangesClicked}
            style={{ width: "fit-content" }}
          >
            Save Changes
          </Button>
        </div>
      </div>
      <br />
      <FileUploader ref={fileUploadRef} onSelect={onUpload} useCrop={true} />
      <Divider className="mb-6 mt-5" />
      <div className="flex" style={{ gap: "10px" }}>
        {data?.images?.map(
          (image) =>
            image.url &&
            image.fullName.includes(image_gen_name) && (
              <div key={image.id} style={{ position: "relative" }}>
                <img
                  alt=""
                  src={image.url}
                  style={{
                    width: "150px",
                    aspectRatio: "3.5 / 4",
                    objectFit: "contain",
                  }}
                />
                <Button
                  icon={"pi pi-trash"}
                  style={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                  }}
                  className="p-button-danger"
                  onClick={(event) =>
                    confirmImageDeletion(event, image.fullName)
                  }
                />
              </div>
            )
        )}
      </div>
      <Divider className="mb-6 mt-5" />
    </BlockUI>
  );
};

const SizeComponentToReduceReRenders = ({
  size,
  quantityValues,
  gender,
  setQuantityValues,
  color,
}) => {
  const onInputUpdate = (quantity, size) => {
    if (isNaN(quantity)) return;

    const exists = quantityValues.find(
      (i) => i.gender === gender && i.color === color && i.size === size
    );

    let newValue = {
      gender: gender,
      color: color,
      size: size,
      quantity: quantity,
    };

    if (exists) {
      setQuantityValues((vals) =>
        vals.map((val) => {
          if (
            val.gender === gender &&
            val.color === color &&
            val.size === size
          ) {
            return newValue;
          }
          return val;
        })
      );
    } else {
      setQuantityValues((vals) => [...vals, newValue]);
    }
  };
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      <Button className="p-button-outlined p-button-help">
        {size.toUpperCase()}
      </Button>

      <InputNumber
        value={
          quantityValues.find(
            (i) => i.gender === gender && i.color === color && i.size === size
          )?.quantity || 0
        }
        onValueChange={(e) => onInputUpdate(e.value, size)}
        showButtons
        buttonLayout="horizontal"
        inputStyle={{
          width: "3rem",
          textAlign: "center",
        }}
        min={0}
        decrementButtonClassName="p-button-secondary"
        incrementButtonClassName="p-button-secondary"
        incrementButtonIcon="pi pi-plus"
        decrementButtonIcon="pi pi-minus"
      />
    </div>
  );
};
