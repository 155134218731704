import React, { useEffect, useContext, useState } from "react";
import { AuthenticationContext } from "../../context/Authentication/authenticationContext";

function ProtectedAuthLink({ children, auth }) {
  const context = useContext(AuthenticationContext);
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    const checkAuthState = () => {
      const isAuthenticated = context.isAuthenticated();
      setIsAuth(isAuthenticated);
    };

    checkAuthState();
  }, [context]);

  return (
    <React.Fragment>
      {auth ? (isAuth ? children : null) : isAuth ? null : children}
    </React.Fragment>
  );
}

export default ProtectedAuthLink;
