import React from "react";
import { AuthenticationContext } from "../../context/Authentication/authenticationContext";
import { Navigate } from "react-router-dom";
import AccessRoute from "./AccessRoute";

function ProtectedRoute({ path, permission, children, ...others }) {
  const context = React.useContext(AuthenticationContext);
  const isAuth = context.isAuthenticated();
  return (
    <div>
      {isAuth ? (
        permission ? (
          <AccessRoute path={path} permission={permission} {...others} children={children} />
        ) : (
          children
        )
      ) : (
        <Navigate to={"/login"} />
      )}
    </div>
  );
}

export default ProtectedRoute;
