import http from "../httpService";

class RegistrationItemsService {
  async create(createRegistrationsItemsInput) {
    let result = await http.post(
      "api/services/app/RegistrationsItems/Create",
      createRegistrationsItemsInput
    );
    return result.data.result;
  }

  async update(updateRegistrationsItemsInput) {
    let result = await http.put(
      "api/services/app/RegistrationsItems/Update",
      updateRegistrationsItemsInput
    );
    return result.data.result;
  }

  async delete(entityDto) {
    let result = await http.delete(
      "api/services/app/RegistrationsItems/Delete",
      {
        params: entityDto,
      }
    );
    return result.data;
  }

  async get(entityDto) {
    let result = await http.get("api/services/app/RegistrationsItems/Get", {
      params: entityDto,
    });
    return result.data.result;
  }

  async getAll(pagedFilterAndSortedRequest) {
    let result = await http.get("api/services/app/RegistrationsItems/GetAll", {
      params: pagedFilterAndSortedRequest,
    });
    return result.data.result;
  }
}

export default new RegistrationItemsService();
