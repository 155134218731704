import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Editor } from "primereact/editor";
import { ProgressSpinner } from "primereact/progressspinner";
import languageService from "../../services/language/languageService";
import { L } from "../../lib/abpUtility";

var abp = window.abp;

const EditorWrapper = ({ name }) => {
  const [showEditor, setShowEditor] = useState(false);
  const [text, setText] = useState(null);
  const [editorText, setEditorText] = useState(null);
  const [loading, setLoading] = useState(true);
  let timer;

  useEffect(() => {
    getText();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getText = () => {
    languageService
      .get({
        PageName: name,
        Localization: abp.localization.currentLanguage.name,
      })
      .then((res) => {
        if (res.result?.jsonContent.Content) {
          setText(res.result?.jsonContent.Content || "");
          setEditorText(res.result?.jsonContent.Content || "");
        }
        setLoading(false);
      });
  };

  const createText = () => {
    setLoading(true);
    const func = languageService.update;
    func({
      pageName: name,
      jsonContentTitle: name,
      jsonContentText: editorText,
      localization: abp.localization.currentLanguage.name,
    }).then(() => {
      getText();
      setShowEditor(false);
      setLoading(false);
    });
  };
  const renderFooter = () => {
    return (
      <div>
        <Button
          label={L("No")}
          icon="pi pi-times"
          onClick={() => setShowEditor(false)}
          className="p-button-text"
        />
        <Button
          label={L("Yes")}
          icon="pi pi-check"
          onClick={() => createText()}
          autoFocus
        />
      </div>
    );
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ProgressSpinner />
      </div>
    );
  }

  const onEditorTextChange = (e) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      setEditorText(e.htmlValue);
    }, 300);
  };

  return (
    <React.Fragment>
      <div dangerouslySetInnerHTML={{ __html: text }}></div>
      {abp.auth.isGranted("Pages.Contents") && (
        <div className="editor-btn mt-0">
          <Button icon="pi pi-pencil" onClick={() => setShowEditor(true)} />
        </div>
      )}
      <Dialog
        header="Header"
        visible={showEditor}
        style={{ width: "50vw" }}
        footer={renderFooter()}
        onHide={() => setShowEditor(false)}
      >
        <Editor
          value={editorText}
          onTextChange={(e) => onEditorTextChange(e)}
        />
      </Dialog>
    </React.Fragment>
  );
};

export default EditorWrapper;
