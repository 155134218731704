import React, { useContext, useMemo } from "react";
import "../../styles/Registration/register.scss";
import { Steps } from "primereact/steps";
import { RegistrationContext } from "../../context/Registration/registrationContext";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ThemeContext } from "../../context/Theme/themeContext";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import userStorageService from "../../services/storage/userStorageService";
import { L } from "../../lib/abpUtility";
import { Message } from "primereact/message";
import { useQuery } from "react-query";
import registrationService from "../../services/registration/registrationService";
import storageService from "../../services/storage/storageService";
import { useQueryValues } from "../../hooks/router/useQuery";
import { Button } from "primereact/button";

function Registration({ children, showLogoIcon = true, fullWidth = true }) {
  let { step } = React.useContext(RegistrationContext);
  const { colorScheme } = useContext(ThemeContext);
  const history = useNavigate();
  const location = useLocation();

  const query = useQueryValues();
  const groupId = query.gl;

  const assignToGroup = useMemo(() => {
    const steppingStone = step;
    if (groupId) return groupId;
    const gl = storageService.getItem("assignToGroup");
    return gl;
  }, [groupId, step]);

  const { data: groupData } = useQuery({
    queryKey: ["groupDetails", assignToGroup],
    queryFn: () => registrationService.getGroupDetails(assignToGroup),
    enabled: !!assignToGroup,
  });

  const registration_path = [
    "/registration/step1",
    "/registration/step2",
    "/registration/step3",
    "/registration/step4",
  ];

  const forbidden_paths = ["/registration/step4"];

  const items = [
    {
      label: "",
      routerLink: "step1",
      command: () => {
        if (!forbidden_paths.includes(location.pathname)) {
          history("/registration/step1");
        }
      },
    },
    {
      label: "",
      routerLink: "step2",
      command: () => {
        if (!forbidden_paths.includes(location.pathname)) {
          history("/registration/step2");
        }
      },
    },
    {
      label: "",
      routerLink: "step3",
      command: () => {
        if (!forbidden_paths.includes(location.pathname)) {
          history("/registration/step3");
        }
      },
    },
    {
      label: "",
      routerLink: "step4",
    },
  ];

  const onCloseButtonClicked = () => {
    confirmDialog({
      message: L("LoseStepDataMessage"),
      header: L("Confirmation"),
      icon: "pi pi-exclamation-triangle",
      acceptLabel: L("Yes"),
      rejectLabel: L("No"),
      accept,
    });
  };

  const accept = () => {
    userStorageService.resetUser();
    history("/");
  };

  const removeGroup = () => {
    const link = `${window.location.origin}${window.location.pathname}`;
    storageService.removeItem("assignToGroup");
    window.location.replace(link);
  };

  return (
    <div className={"registration_container"}>
      <ConfirmDialog draggable={false} focusOnShow={true} />
      <div
        className={`container ${colorScheme === "dark" && "container-dark"} ${
          fullWidth && "full-width"
        }`}
      >
        {showLogoIcon && (
          <div className="logo">
            <img alt="logo" src="/assets/layout/images/newwave-logo.png" />
          </div>
        )}
        <div className="content">
          {registration_path.includes(location.pathname) && (
            <React.Fragment>
              <i
                onClick={onCloseButtonClicked}
                className="fa fa-eraser absolute top-0 right-0 text-2xl mr-4 mt-4 cursor-pointer text-bold "
              ></i>
              <Steps
                model={items}
                readOnly={false}
                disabled={false}
                activeIndex={step}
              />
            </React.Fragment>
          )}
          {groupData && step !== 3 && (
            <div style={{ display: "flex", gap: "3px", alignItems: "center" }}>
              <Message
                severity="info"
                text={L("RegisteringInGroup", groupData.fullName)}
                style={{ width: "100%", padding: "8px 10px" }}
              />
              <Button
                onClick={() => removeGroup()}
                type="button"
                icon="pi pi-times"
                severity="secondary"
              ></Button>
            </div>
          )}
          <Outlet />
          {children}
        </div>
      </div>
    </div>
  );
}

export default Registration;
