import React, { useContext, useState, useEffect, useCallback } from "react";
import { RegistrationContext } from "../../../context/Registration/registrationContext";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import storageService from "../../../services/storage/storageService";
import { EventContext } from "../../../context/Event/eventContext";
import { L } from "../../../lib/abpUtility";
import { useQueryValues } from "../../../hooks/router/useQuery";
import DaysStep from "../Days/DaysStep";

function StepOne() {
  const { setStep, getDaysStepTwo, onStepOneSubmit } =
    React.useContext(RegistrationContext);
  const [days, setDays] = React.useState([]);
  const query = useQueryValues();
  const groupId = query.gl;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const { lastEvent } = useContext(EventContext);
  const { watch, reset, setValue, getValues } = useForm({
    defaultValues: {
      days: storageService.getItem("days")
        ? JSON.parse(storageService.getItem("days"))
        : [],
    },
  });
  const history = useNavigate();
  const [realEvening, setRealEvening] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!lastEvent) {
      return;
    }
    setStep(0);

    getDaysStepTwo(lastEvent).then((days) => {
      for (let idx in days) {
        setValue(`days.${idx}.eventDayId`, days[idx]?.id);
      }
      setDays(days);
      setLoading(false);
    });
  }, [lastEvent]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (groupId) {
      storageService.setItem("assignToGroup", groupId);
      storageService.setItem("inviteGroupURL", window.location.href);
    }
    watch((e) => {
      setError("");
      onStepOneSubmit(e);
      if (groupId) {
        storageService.setItem("assignToGroup", groupId);
      }
    });
  }, [watch, onStepOneSubmit, groupId]);

  useEffect(() => {
    setRealEvening(checkIfRealEvening());
  }, [days]);

  const onSubmit = () => {
    let data = getValues();

    if (data.days?.find((i) => i?.presence?.id !== undefined)) {
      onStepOneSubmit(data);
      history("/registration/step2");
    } else {
      window.scrollTo(0, 0);
      setError(L("PresenceRequired"));
    }
  };

  const selectAllEvenings = () => {
    days.forEach((day, index) => {
      setValue(`days.${index}.presence.id`, day.presences?.[1]?.id);
      setValue(`days.${index}.accommodation.id`, null);
    });
    refetchData();
  };

  const selectAllDays = () => {
    days.forEach((day, index) => {
      if (day.presences) {
        if (day.dayType !== "ZeroDay") {
          setValue(`days.${index}.presence.id`, day.presences?.[0]?.id);
        }
      }
    });
    refetchData();
  };

  const selectThisEvening = () => {
    days.forEach((_, index) => {
      setValue(`days.${index}.presence.id`, null);
      setValue(`days.${index}.accommodation.id`, null);
    });

    let currDate = new Date();
    let eventDate = days.find(
      (day) => new Date(day.date).toDateString() === currDate.toDateString()
    );

    let index = days.indexOf(eventDate);
    setValue(`days.${index}.presence.id`, 2);
    refetchData();
  };

  const checkIfRealEvening = () => {
    let eveningDays = days.slice(1, -1);
    let eveningDates = eveningDays.map((day) =>
      new Date(day.date).toDateString()
    );
    let currDate = new Date().toDateString();

    return eveningDates.includes(currDate);
  };

  const refetchData = () => {
    let data = getValues();
    reset(data);
  };

  return (
    <div className="step">
      {!loading ? (
        <React.Fragment>
          {error && <div className="step-two-error">{error}</div>}
          <div>
            <h4>
              {L("Presence")}
              {" & "}
              {L("Accommodation")}
            </h4>
          </div>

          <div className="flex align-items-center">
            <h5 className="mt-3">{L("QuickChoice")}</h5>
            <Button
              icon="pi pi-info-circle"
              className="p-button-text"
              tooltip={L("PresAccomInfo")}
            />
          </div>

          <div
            style={{
              display: "flex",
              gap: 15,
              marginBottom: "25px",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {realEvening && (
              <Button
                type="button"
                label={L("ThisEvening")}
                onClick={() => selectThisEvening()}
                className="p-button-outlined p-button-primary"
              />
            )}
            <Button
              type="button"
              label={L("AllEvenings")}
              onClick={() => selectAllEvenings()}
              className="p-button-outlined p-button-primary"
            />

            <Button
              type="button"
              label={L("WholeCamp")}
              onClick={() => selectAllDays()}
              className="p-button-outlined p-button-primary"
            />
          </div>

          <div>
            <h5>{L("PerDayChoice")}</h5>
          </div>

          <DaysStep
            days={days}
            setValue={setValue}
            refetchData={refetchData}
            watch={watch}
            isView={false}
          />
          <Button
            onClick={onSubmit}
            label={L("NextButton")}
            className="next-button mt-5"
          />
        </React.Fragment>
      ) : (
        <div>
          <h1>{L("Loading")}...</h1>
        </div>
      )}
    </div>
  );
}

export default StepOne;
