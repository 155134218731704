import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useCallback } from "react";
import { L } from "../../../lib/abpUtility";

const DaysStep = ({ days, setValue, watch, refetchData, isView }) => {
  const RenderDayButtonDay = React.memo(({ day }) => {
    const index = days.indexOf(day);
    const dayData = watch(`days.${index}`);

    const handleClick = useCallback(() => {
      if (dayData?.presence?.id === 1 || dayData?.presence?.id === 4) {
        setValue(`days.${index}.presence.id`, null);
        setValue(`days.${index}.accommodation.id`, null);
      } else {
        setValue(`days.${index}.presence.id`, day?.presences?.[0]?.id);
      }
      refetchData();
    }, [dayData?.presence?.id, index]);

    return (
      <Button
        onClick={handleClick}
        className={`p-button  ${
          dayData?.presence?.id !== 1 && dayData?.presence?.id !== 4
            ? "p-button-outlined p-button-secondary"
            : "p-button-success"
        }`}
        icon={`pi ${
          dayData?.presence?.id !== 1 && dayData?.presence?.id !== 4
            ? ""
            : "pi-check"
        }`}
        disabled={
          isView
            ? isView
            : day?.presences?.find((i) => i.id === 1 || i.id === 4) ===
              undefined
        }
        style={{ minWidth: "40px", minHeight: "40px" }}
      />
    );
  });

  const RenderDayButtonEvening = React.memo(({ day }) => {
    const index = days.indexOf(day);
    const dayData = watch(`days.${index}`);

    const handleClick = useCallback(() => {
      if (dayData?.presence?.id === 2) {
        setValue(`days.${index}.presence.id`, null);
      } else {
        setValue(`days.${index}.accommodation.id`, null);
        setValue(`days.${index}.presence.id`, day?.presences?.[1]?.id);
      }
      refetchData();
    }, [dayData?.presence?.id, index]);

    return (
      <Button
        onClick={handleClick}
        className={`p-button ${
          dayData?.presence?.id !== 2
            ? "p-button-outlined p-button-secondary"
            : "p-button-danger"
        }`}
        icon={`pi ${dayData?.presence?.id !== 2 ? "" : "pi-check"}`}
        disabled={
          isView
            ? isView
            : day?.presences?.find((i) => i.id === 2) === undefined
        }
        style={{ minWidth: "40px", minHeight: "40px" }}
      />
    );
  });

  const RenderDayButtonTent = React.memo(({ day }) => {
    const index = days.indexOf(day);
    const dayData = watch(`days.${index}`);

    const handleClick = useCallback(() => {
      if (dayData?.accommodation?.id) {
        setValue(`days.${index}.accommodation.id`, null);

        if (day.dayType === "ZeroDay") {
          setValue(`days.${index}.presence`, null);
        }
      } else {
        if (dayData?.presence?.id !== 2) {
          if (day.dayType === "ZeroDay") {
            setValue(`days.${index}.accommodation.id`, 1);
            setValue(`days.${index}.presence.id`, day?.presences?.[0]?.id);
          } else {
            if (dayData?.presence?.id) {
              setValue(`days.${index}.accommodation.id`, 1);
            } else {
              setValue(`days.${index}.presence.id`, day?.presences?.[0]?.id);
              setValue(`days.${index}.accommodation.id`, 1);
            }
          }
        } else {
          setValue(`days.${index}.accommodation.id`, 1);
          setValue(`days.${index}.presence.id`, day?.presences?.[0]?.id);
        }
      }

      refetchData();
    }, [dayData?.presence?.id, index]);

    return (
      <Button
        onClick={handleClick}
        className={`p-button ${
          dayData?.accommodation?.id !== 1
            ? "p-button-outlined p-button-secondary"
            : "p-button-info"
        }`}
        icon={`pi ${dayData?.accommodation?.id !== 1 ? "" : "pi-check"}`}
        disabled={
          isView
            ? isView
            : day?.accommodations?.find((i) => i.id === 1 || i.id === 4) ===
              undefined
        }
        style={{ minWidth: "40px", minHeight: "40px" }}
      />
    );
  });

  return (
    <div className="step_one_days">
      <DataTable value={days} responsiveLayout="scroll">
        <Column
          field="date"
          header={L("Date")}
          headerStyle={{ fontWeight: "800" }}
          body={({ date }) => (
            <p style={{ fontWeight: "600", fontSize: "1.1rem" }}>
              {new Date(date).toLocaleString(L("LanguageSlug"), {
                month: "long",
                day: "2-digit",
              })}
            </p>
          )}
        ></Column>

        <Column
          header={L("Evening")}
          headerStyle={{ width: "100px" }}
          body={(rowData) => <RenderDayButtonEvening day={rowData} />}
        ></Column>
        <Column
          header={L("Day")}
          headerStyle={{ width: "100px" }}
          body={(rowData) => <RenderDayButtonDay day={rowData} />}
        ></Column>
        <Column
          header={L("Tent")}
          headerStyle={{ width: "100px" }}
          body={(rowData) => <RenderDayButtonTent day={rowData} />}
        ></Column>
      </DataTable>
    </div>
  );
};

export default DaysStep;
