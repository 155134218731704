import axios from "axios";
import { errorRef } from "../App";
import { getTenantId } from "./tenant/getTenantId";
import { L } from "../lib/abpUtility.js";

const qs = require("qs");

var abp = window.abp;

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: process.env.NODE_ENV === "production" ? 30000 : undefined,
  paramsSerializer: function (params) {
    return qs.stringify(params, {
      encode: false,
    });
  },
});

http.interceptors.request.use(
  function (config) {
    if (abp.auth.getToken()) {
      config.headers.common["Authorization"] = "Bearer " + abp.auth.getToken();
    }

    config.headers.common[".AspNetCore.Culture"] = abp.utils.getCookieValue(
      "Abp.Localization.CultureName"
    );
    // config.headers.common['Abp.TenantId'] = abp.multiTenancy.getTenantIdCookie();
    config.headers.common["Abp.TenantId"] = getTenantId();

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  function (response) {
    return response;
  },
  (error) => {
    if (error.response?.data.error?.validationErrors) {
      for (let i of error.response.data.error.validationErrors) {
        errorRef.current.show({
          severity: "error",
          summary: L("Error") + " " + error.response.status,
          detail: L(i.message),
          life: 10000,
        });
      }
    }
    return Promise.reject(error);
  }
);

export default http;
