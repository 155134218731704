import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  AuthenticationContext,
  authenticationValues,
} from "./Authentication/authenticationContext";
import {
  RegistrationContext,
  registrationContextValues,
} from "./Registration/registrationContext";
import { RolesContext, roleContextValues } from "./Roles/rolesContext";
import { TenantContext, tenantsContextValues } from "./Tenants/tenantsContext";
import { UserContext, userContextValues } from "./User/userContext";
import { EventContext, eventContextValues } from "./Event/eventContext";
import { ThemeContext, themeContextValues } from "./Theme/themeContext";
import eventService from "../services/event/eventService";
import { ShopContextWrapper } from "./shop/shopContext";
import { useQuery } from "react-query";
import abpUserConfigurationService from "../services/abpUserConfigurationService";

function InitContext({ children }) {
  const [step, setStep] = React.useState(0);
  const [lastEvent, setLastEvent] = React.useState(null);
  const [user, setUser] = useState({});
  const [registrationFilters, setRegistrationFilters] = useState({});
  const [colorScheme, setColorScheme] = React.useState(
    sessionStorage.getItem("theme") ? sessionStorage.getItem("theme") : "light"
  );
  const [menuScheme, setMenuScheme] = React.useState(
    sessionStorage.getItem("theme") ? sessionStorage.getItem("theme") : "light"
  );

  useQuery({
    queryKey: "user",
    queryFn: () => abpUserConfigurationService.getUser(),
    onSuccess: (data) => setUser(data),
  });

  useEffect(() => {
    eventService.getLastEvent().then((res) => {
      setLastEvent(res);
    });
  }, []);

  const setScheme = useCallback((scheme) => {
    setColorScheme(scheme);
    sessionStorage.setItem("theme", scheme);
    setMenuScheme(scheme);
  }, []);

  const eventValues = useMemo(() => {
    return { ...eventContextValues, lastEvent };
  }, [lastEvent]);

  const themeValues = useMemo(() => {
    return {
      ...themeContextValues,
      ...{
        colorScheme: colorScheme,
        setColorScheme: setScheme,
        menuScheme: menuScheme,
      },
    };
  }, [colorScheme, setScheme, menuScheme]);

  const authValues = useMemo(() => {
    return { ...authenticationValues, user, setUser };
  }, [user, setUser]);

  const registrationValues = useMemo(() => {
    return {
      ...registrationContextValues,
      step,
      setStep,
      registrationFilters,
      setRegistrationFilters,
    };
  }, [step, setStep, registrationFilters, setRegistrationFilters]);

  return (
    <ThemeContext.Provider value={themeValues}>
      <AuthenticationContext.Provider value={authValues}>
        <TenantContext.Provider value={tenantsContextValues}>
          <RolesContext.Provider value={roleContextValues}>
            <RegistrationContext.Provider value={registrationValues}>
              <UserContext.Provider value={userContextValues}>
                <EventContext.Provider value={eventValues}>
                  <ShopContextWrapper>{children}</ShopContextWrapper>
                </EventContext.Provider>
              </UserContext.Provider>
            </RegistrationContext.Provider>
          </RolesContext.Provider>
        </TenantContext.Provider>
      </AuthenticationContext.Provider>
    </ThemeContext.Provider>
  );
}

export default InitContext;
