import React, { useState, useRef, memo, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProgressSpinner } from "primereact/progressspinner";
import { InputText } from "primereact/inputtext";
import "../../styles/CrudTable/table.scss";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Dialog } from "primereact/dialog";
import CrudDialog from "./CrudDialog";
import { MultiSelect } from "primereact/multiselect";
import { L } from "../../lib/abpUtility";

function Table({
  fields,
  extendRightHeader,
  data,
  loading,
  headerText,
  toolbar,
  editableFields,
  createFields,
  dialogHeaderText,
  onCreate,
  onEdit,
  onDelete,
  deleteText,
  showDialog,
  setShowDialog,
  selection,
  deleteAllButton,
  importButton,
  exportButton,
  onNewClick,
  multySelect,
  tableProps,
  tableLoading,
  handlePageChange,
  first,
  totalRecords,
  rows,
  handleGlobalFilterChange,
  lazy,
  extendActionsBody,
  onEditButtonClick,
  extendHeader,
  showActions = true,
  onSelection,
  onSort,
}) {
  const [globalFilter, setGlobalFilter] = useState("");
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [fieldsMap, setFieldsMap] = useState(
    fields.filter((item) => !item.hidden === true)
  );
  const [selectedData, setSelectedData] = useState(null);
  const [stateFields, setStateFields] = useState(createFields);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [state, setState] = useState("create");
  const toast = useRef(null);

  useEffect(() => {
    if (handleGlobalFilterChange) {
      handleGlobalFilterChange(globalFilter);
    }
  }, [globalFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  const onEditSubmit = (data) => {
    onEdit(data, selectedData);
  };

  const handleOnSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);
    onSort(e);
  };

  const lazyProps =
    lazy === true
      ? {
          lazy: lazy,
          totalRecords: totalRecords,
          first: first,
          onPage: (e) => handlePageChange(e, globalFilter),
          rows: rows,
        }
      : {};

  // The function that is called when the user clicks the "New" button

  const openCreateModal = () => {
    setSelectedData(null);
    setStateFields(createFields);
    setState("create");
    setShowDialog(true);
  };

  const openEditDialog = (rowData) => {
    setSelectedData(rowData);
    setState("edit");
    setStateFields(editableFields);
    setShowDialog(true);
  };

  const openDeleteDialog = (rowData) => {
    setSelectedData(null);
    setSelectedData(rowData);
    setShowDeleteDialog(true);
  };

  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = fields.filter((col) =>
      selectedColumns.some((sCol) => sCol.field === col.field)
    );
    setFieldsMap(orderedSelectedColumns);
  };

  // The action body template for the last column

  const actionBodyTemplate = (rowData) => {
    return (
      showActions && (
        <div
          className="actions"
          style={{ display: "flex", alignItems: "center", gap: "5px" }}
        >
          <Button
            icon="pi pi-pencil"
            className="p-button-rounded p-button-success p-mr-2"
            onClick={() =>
              onEditButtonClick
                ? onEditButtonClick(rowData)
                : openEditDialog(rowData)
            }
          />
          <Button
            icon="pi pi-trash"
            className="p-button-rounded p-button-danger p-mr-2"
            onClick={() => openDeleteDialog(rowData)}
          />
          {extendActionsBody && extendActionsBody(rowData)}
        </div>
      )
    );
  };

  // The right side of the table is a toolbar that contains the following items: Import and Export

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        {importButton && (
          <FileUpload
            mode="basic"
            accept="image/*"
            maxFileSize={1000000}
            label="Import"
            chooseLabel="Import"
            className="p-mr-2 p-d-inline-block"
          />
        )}
        {exportButton && (
          <Button
            label="Export"
            icon="pi pi-upload"
            className="p-button-help"
          />
        )}
        {extendRightHeader && extendRightHeader()}
      </React.Fragment>
    );
  };

  //The left side of the dialog

  const leftToolbarTemplate = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          label={L("New")}
          icon="pi pi-plus"
          onClick={() => (onNewClick ? onNewClick() : openCreateModal())}
          className="p-button-success p-mr-2"
        />
        {deleteAllButton && (
          <Button
            label="Delete"
            icon="pi pi-trash"
            disabled={!selectedData}
            onClick={() => openDeleteDialog(selectedData)}
            className="p-button-danger p-mb-2"
          />
        )}
      </div>
    );
  };

  // The create or edit dialog footer

  const deleteDialogFooter = (
    <>
      <Button
        label={L("No")}
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => setShowDeleteDialog(false)}
      />
      <Button
        label={L("Yes")}
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => {
          onDelete(selectedData);
          setShowDeleteDialog(false);
        }}
      />
    </>
  );

  // The header menu of a table is a toolbar that contains the following items: Header Text and a search field

  const header = (
    <div className="table-header">
      <h5 className="p-mx-0 p-my-1">{headerText}</h5>
      <div className="table-header-container">
        {extendHeader && extendHeader(fieldsMap)}
        {multySelect && (
          <MultiSelect
            value={fieldsMap}
            options={fields}
            optionLabel="header"
            onChange={onColumnToggle}
          />
        )}
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder={L("Search") + "..."}
          />
        </span>
      </div>
    </div>
  );

  return (
    <div
      className="datatable-crud-demo card crud-demo"
      style={loading ? { display: "flex", alignItems: "center" } : {}}
    >
      {!loading ? (
        <React.Fragment>
          {/* Toast message for success or error */}

          <Toast ref={toast} />

          {/* Toolbar */}

          {toolbar && (
            <Toolbar
              className="p-mb-4 p-toolbar"
              left={leftToolbarTemplate}
              right={rightToolbarTemplate}
            />
          )}

          {/* Table */}

          <DataTable
            header={header}
            value={data}
            dataKey="id"
            paginator
            loading={tableLoading}
            rows={5}
            rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate={L("ShowingFirstLast")}
            className="datatable-responsive"
            globalFilter={globalFilter}
            selection={selectedData}
            onSelectionChange={(e) => {
              setSelectedData(e.value);
              onSelection?.(e.value);
            }}
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={handleOnSort}
            {...lazyProps}
            {...tableProps}
          >
            {/* Columns */}

            {/* The selection column */}

            {multySelect && (
              <Column
                selectionMode="multiple"
                headerStyle={{ width: "3rem" }}
              ></Column>
            )}

            {/* Mapping throw all the columns that are given */}

            {fieldsMap.map((field) => (
              <Column
                key={field.field}
                field={field.field}
                sortable={field.sortable ? true : field.sortable}
                header={field.header}
                {...(field.body && { body: field.body })}
                {...field.otherProps}
              />
            ))}

            {/* The actions column */}

            {fieldsMap.length > 0 && showActions && (
              <Column
                body={actionBodyTemplate}
                header={L("Actions")}
                field="id"
              ></Column>
            )}
          </DataTable>

          {/* Dialog for create or edit */}

          <Dialog
            visible={showDialog}
            style={{ width: "450px" }}
            modal
            className="p-fluid"
            onHide={() => {
              setSelectedData(null);
              setShowDialog(false);
            }}
          >
            {showDialog && (
              <CrudDialog
                state={state}
                selectedData={selectedData}
                headerText={dialogHeaderText}
                onSubmit={state === "create" ? onCreate : onEditSubmit}
                stateFields={stateFields}
              />
            )}
          </Dialog>

          {/* Dialog for delete */}

          <Dialog
            visible={showDeleteDialog}
            style={{ width: "450px" }}
            header={L("Confirm")}
            modal
            footer={deleteDialogFooter}
            onHide={() => setShowDeleteDialog(false)}
          >
            <div className="confirmation-content">
              <i
                className="pi pi-exclamation-triangle p-mr-3"
                style={{ fontSize: "2rem" }}
              />

              <span>{deleteText}</span>
            </div>
          </Dialog>
        </React.Fragment>
      ) : (
        // Loading spinner
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ProgressSpinner style={{ width: "200px", height: "200px" }} />
        </div>
      )}
    </div>
  );
}

export default memo(Table);
