import React from 'react';

export const Error = (props) => {

	return (
		<div className="exception-body error">
			<div className="exception-wrapper">
				<div className="exception-content">
					<img src="/assets/layout/images/pages/asset-error.svg" alt="freya-layout" />
					<span style={{ maxWidth: "40vw", fontSize: "24px" }}>{props.title}</span>
				</div>
			</div>
		</div >
	)

}