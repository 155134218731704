import React, { useContext, useEffect, useState } from "react";
import feesService from "../../services/fees/feesService";
import { EventContext } from "../../context/Event/eventContext";
import { L } from "../../lib/abpUtility";

let abp = window.abp;

export const PricingTable = () => {
  const [fees, setFees] = useState();
  const { lastEvent } = useContext(EventContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    feesService.getFees().then((resFees) => {
      let idx = resFees.findIndex(function (element) {
        return element.name === "Discount";
      });

      delete resFees[idx];

      setFees(resFees);
    });
  }, [lastEvent]);

  return (
    <div>
      {((fees && lastEvent?.eventState?.value === 2) ||
        abp.auth.hasPermission("Pages.Payments.Pay")) && (
        <table className="tg">
          <thead>
            <tr>
              <th className="tg-i7og" colSpan="6">
                <h4 className="mb-1">{L("FeeAmounts")} *</h4>
              </th>
            </tr>
            <tr th className="tg-i7og" colSpan="6"></tr>
            <tr>
              <th className="tg-i7og" rowSpan="2">
                {L("Age")}
              </th>
              <th className="tg-i7og" colSpan="2">
                {L("FullProgram") + " (" + L("wholeCamp") + ")**"}
              </th>
              <th className="tg-i7og" colSpan="2">
                {L("AllEvenings")}
              </th>
              <th className="tg-i7og">{L("TentPerPerson")}</th>
            </tr>
            <tr>
              <th className="tg-9ji8">{L("single")}</th>
              <th className="tg-9ji8">{L("inGroup")}</th>
              <th className="tg-9ji8">{L("perEvening")}</th>
              <th className="tg-9ji8">{L("allEveningsLC")}</th>
              <th className="tg-9ji8">
                {L("perNight")}/{L("perPerson")}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="tg-xw33">{L("upTo8Years")}</td>
              <td className="tg-i7og" colSpan="5">
                {L("Free")}
              </td>
            </tr>
            <tr>
              <td className="tg-xw33">{L("9to12years")}</td>
              <td className="tg-xw33">
                {fees?.find((o) => o?.name === "PresenceWholeCamp9To12")?.amount}
              </td>
              <td className="tg-xw33">
                {
                  fees?.find((o) => o?.name === "PresenceWholeCamp9To12Group")
                    .amount
                }
              </td>
              <td className="tg-xw33">
                {fees?.find((o) => o?.name === "PresenceEvening9To12")?.amount}
              </td>
              <td className="tg-xw33">
                {
                  fees?.find((o) => o?.name === "PresenceAllEvenings9To12")
                    ?.amount
                }
              </td>
              <td className="tg-xw33">
                {fees?.find((o) => o?.name === "AccommodationTent9Plus")?.amount}
              </td>
            </tr>

            <tr>
              <td className="tg-xw33">{L("13to17years")}</td>
              <td className="tg-xw33">
                {
                  fees?.find((o) => o?.name === "PresenceWholeCamp13To17")
                    ?.amount
                }
              </td>
              <td className="tg-xw33">
                {
                  fees?.find((o) => o?.name === "PresenceWholeCamp13To17Group")
                    ?.amount
                }
              </td>
              <td className="tg-xw33">
                {fees?.find((o) => o?.name === "PresenceEvening13To17")?.amount}
              </td>
              <td className="tg-xw33">
                {
                  fees?.find((o) => o?.name === "PresenceAllEvenings13To17")
                    ?.amount
                }
              </td>
              <td className="tg-xw33">
                {fees?.find((o) => o?.name === "AccommodationTent9Plus")?.amount}
              </td>
            </tr>

            <tr>
              <td className="tg-xw33">{L("over18years")}</td>
              <td className="tg-xw33">
                {
                  fees?.find((o) => o?.name === "PresenceWholeCamp18Plus")
                    ?.amount
                }
              </td>
              <td className="tg-xw33">
                {
                  fees?.find((o) => o?.name === "PresenceWholeCamp18PlusGroup")
                    ?.amount
                }
              </td>
              <td className="tg-xw33">
                {fees?.find((o) => o?.name === "PresenceEvening18Plus")?.amount}
              </td>
              <td className="tg-xw33">
                {
                  fees?.find((o) => o?.name === "PresenceAllEvenings18Plus")
                    ?.amount
                }
              </td>
              <td className="tg-xw33">
                {fees?.find((o) => o?.name === "AccommodationTent9Plus")?.amount}
              </td>
            </tr>
            <tr>
              <td className="tg-i3ji" colSpan="7">
                {"*" +
                  L("amountsAreInBgn") +
                  ", **" +
                  L("discountForFewerDays")}
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};

export default PricingTable;
