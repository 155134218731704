import React, { useEffect } from "react";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "../../styles/freya/App.scss";
import 'react-image-crop/dist/ReactCrop.css'
import NavBar from "../NavBar/NavBar";
import { ThemeContext } from "../../context/Theme/themeContext";
import { Outlet } from "react-router-dom";

const MenuLayout = (props) => {
  const { colorScheme, setColorScheme, changeStyleSheetsColor } =
    React.useContext(ThemeContext);

  useEffect(() => {
    changeStyleSheetsColor("layout-css", "layout-" + colorScheme + ".css", 1);
    changeStyleSheetsColor("theme-css", "theme-" + colorScheme + ".css", 1);

    setColorScheme(colorScheme);
  }, [changeStyleSheetsColor, colorScheme, setColorScheme]);

  return (
    <div>
      <NavBar />
      <div
        className="layout-main"
        id="main-layout"
        style={{
          background: "url(/images/new-wave-background-new.jpg)",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="layout-content"><Outlet /></div>


        <div className="layout-mask modal-in"></div>
      </div>
    </div>
  );
};

export default MenuLayout;
