import http from "../httpService";

class FeesService {

  async getFees(registerInput) {
    let result = await http.get(
      "/api/services/app/Prices/GetAll?Sorting=id%20asc&MaxResultCount=100"
    );
    return result.data.result.items;
  }

  async getShiftReport(params) {
    let result = await http.get("api/services/app/Payments/GetUsersReport", {
      params: params,
    });
    return result.data.result;
  }

  async getAllPayments(params) {
    let result = await http.get("api/services/app/Payments/GetAll", {
      params: params,
    });
    return result.data.result;
  }
}

export default new FeesService();
