import React, { useEffect, useRef, useState, useContext } from "react";
import "../../styles/NavBar/nav.scss";
import ProtectedAuthLink from "../routing/ProtectedAuthLink";
import { Link, useLocation } from "react-router-dom";
import Logout from "../Logout/Logout";
import { ThemeContext } from "../../context/Theme/themeContext";
import LanguageSelect from "../LanguageSelect/LanguageSelect";
import { L } from "../../lib/abpUtility";
import { Badge } from "primereact/badge";
import HasPermission from "../permission/HasPermission";
import { Divider } from "primereact/divider";
import { EventContext } from "../../context/Event/eventContext";
import { useCart } from "../../context/shop/shopContext";
import { useUser } from "../../hooks/user/useUser";
import { Tooltip } from "primereact/tooltip";

function NavBar() {
  const { colorScheme } = React.useContext(ThemeContext);
  const { lastEvent } = useContext(EventContext);
  const [open, setOpen] = useState(false);
  const { cart } = useCart();
  const ref = useRef();
  const location = useLocation();
  const user = useUser();
  const handleClose = (event) => {
    if (ref && !ref.current?.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    setOpen(false);
  }, [location.pathname]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClose);
    document.addEventListener("scroll", handleClose);
    return () => {
      document.removeEventListener("mousedown", handleClose);
      document.removeEventListener("scroll", handleClose);
    };
  }, []);

  return (
    <div
      className={`navbar ${open && "navbar-open"} ${
        colorScheme === "dark" && "navbar-dark"
      } `}
      ref={ref}
      style={{ height: "60px" }}
    >
      <div className="left-side">
        <div className="logo">
          <Link to={"/"}>
            <img
              src="/assets/layout/images/newwave-logo.png"
              alt="Newwave logo"
            />
          </Link>
        </div>
        <ul className="links">
          {lastEvent?.eventState.value === 2 && (
            <React.Fragment>
              <li>
                <i className="pi pi-user-plus"></i>
                <Link to={"/registration/step1"}>{L("Register")}</Link>
              </li>
              <li>
                <i className="pi pi-info-circle"></i>
                <Link to={"/fees-and-documents"}>{L("Information")}</Link>
              </li>
              <li>
                <i className="pi pi-clock"></i>
                <Link to={"/camp-program"}>{L("Program")}</Link>
              </li>
            </React.Fragment>
          )}
          <ProtectedAuthLink auth={true}>
            <HasPermission
              permission={[
                "Pages.Registrations",
                "Pages.Registration.GroupLeader",
                "Pages.Registration.Person",
              ]}
            >
              <li>
                <i className="pi pi-id-card"></i>
                <Link to={"/registrations"}>{L("Registrations")}</Link>
              </li>
              {user?.registrationId && lastEvent?.isShopActive && (
                <li>
                  <i className="pi pi-shopping-bag"></i>
                  <Link to={"/shop"}>{L("Shop")}</Link>
                </li>
              )}
            </HasPermission>
            <HasPermission
              permission={["Pages.Users", "Pages.Roles", "Pages.Events"]}
            >
              <Divider layout="vertical" />
            </HasPermission>
            <HasPermission permission={["Pages.Users"]}>
              <li>
                <Link to={"/users"}>
                  <Tooltip
                    target=".pi-users"
                    content={L("Users")}
                    position="bottom"
                  />
                  <i className="pi pi-users"></i>
                </Link>
              </li>
            </HasPermission>
            <HasPermission permission={["Pages.Roles"]}>
              <li>
                <Link to={"/roles"}>
                  <Tooltip
                    target=".pi-lock"
                    content={L("Roles")}
                    position="bottom"
                  />
                  <i className="pi pi-lock"></i>
                </Link>
              </li>
            </HasPermission>
            <HasPermission permission={["Pages.Events"]}>
              <li>
                <Link to={"/events"}>
                  <Tooltip
                    target=".pi-calendar"
                    content={L("Events")}
                    position="bottom"
                  />
                  <i className="pi pi-calendar"></i>
                </Link>
              </li>
            </HasPermission>
            <HasPermission permission={["Pages.Items.Admin"]}>
              <li>
                <Link to={"/items"}>
                  <Tooltip
                    target=".pi-briefcase"
                    content={L("Shop")}
                    position="bottom"
                  />
                  <i className="pi pi-briefcase"></i>
                </Link>
              </li>
            </HasPermission>
            <HasPermission permission={["Pages.Payments.Pay"]}>
              <li>
                <Link to={"/participants-report"}>
                  <Tooltip
                    target=".pi-chart-bar"
                    content={L("Reports")}
                    position="bottom"
                  />
                  <i className="pi pi-chart-bar"></i>
                </Link>
              </li>
            </HasPermission>
            <HasPermission permission={["Pages.Roles"]}>
              <li>
                <Link to={"/system-logs"}>
                  <Tooltip
                    target=".pi-book"
                    content={L("SystemLogs")}
                    position="bottom"
                  />
                  <i className="pi pi-book"></i>
                </Link>
              </li>
            </HasPermission>
            {user?.registrationId && lastEvent?.isShopActive && (
              <li>
                <Divider layout="vertical" />
                <i
                  className="pi pi-shopping-cart p-overlay-badge"
                  style={{
                    fontSize: "1.5rem",
                  }}
                >
                  {cart.length > 0 && (
                    <Badge
                      value={cart.length}
                      className="small-nav-badge"
                      style={{
                        width: "2px",
                      }}
                    ></Badge>
                  )}
                </i>
                <Link to={"/cart"}>{L("ShopMyOrder")}</Link>
              </li>
            )}
          </ProtectedAuthLink>
        </ul>
      </div>
      <div className="right-side">
        <div className="languageSelect">
          <LanguageSelect />
        </div>
        <ProtectedAuthLink auth={true}>
          <div className="nav-user">
            <i className="pi pi-user mr-1"></i>
            <Link to={"/profile"}>{user?.userName?.split("@")?.[0]}</Link>
          </div>
        </ProtectedAuthLink>
        <div className="buttons">
          <ProtectedAuthLink auth={false}>
            <Link to={"/login"}>
              <button>{L("SignIn")}</button>
            </Link>
          </ProtectedAuthLink>
          <ProtectedAuthLink auth={true}>
            <Logout>
              <button>{L("Logout")}</button>
            </Logout>
          </ProtectedAuthLink>
        </div>
        <div className="burger-menu-icon">
          <i className="fa fa-bars" onClick={() => setOpen(true)}></i>
        </div>
        <div className="burger-menu">
          <i className="fa fa-xmark" onClick={() => setOpen(false)}></i>
          <ul className="burger-links">
            <li>
              <Link to={"/registration/step1"}>{L("Register")}</Link>
              <i className="pi pi-user-plus"></i>
            </li>
            <li>
              <Link to={"/fees-and-documents"}>{L("Information")}</Link>
              <i className="pi pi-info-circle"></i>
            </li>
            <li>
              <Link to={"/camp-program"}>{L("Program")}</Link>
              <i className="pi pi-clock"></i>
            </li>
            <ProtectedAuthLink auth={true}>
              <HasPermission
                permission={[
                  "Pages.Registrations",
                  "Pages.Registration.GroupLeader",
                ]}
              >
                <li>
                  <Link to={"/registrations"}>{L("Registrations")}</Link>
                  <i className="pi pi-id-card"></i>
                </li>
              </HasPermission>
              {user?.registrationId && lastEvent?.isShopActive && (
                <ProtectedAuthLink auth={true}>
                  <li>
                    <Link to={"/shop"}>{L("Shop")}</Link>
                    <i className="pi pi-shopping-bag"></i>
                  </li>
                </ProtectedAuthLink>
              )}
              <HasPermission permission={["Pages.Users"]}>
                <li>
                  <Link to={"/users"}>{L("Users")}</Link>
                  <i className="pi pi-users"></i>
                </li>
              </HasPermission>
              <HasPermission permission={["Pages.Roles"]}>
                <li>
                  <Link to={"/roles"}>{L("Roles")}</Link>
                  <i className="pi pi-lock"></i>
                </li>
              </HasPermission>
              <HasPermission permission={["Pages.Events"]}>
                <li>
                  <Link to={"/events"}>{L("Events")}</Link>
                  <i className="pi pi-calendar"></i>
                </li>
              </HasPermission>
              <HasPermission permission={["Pages.Items.Admin"]}>
                <li>
                  <Link to={"/items"}>{L("Shop")}</Link>
                  <i className="pi pi-briefcase"></i>
                </li>
              </HasPermission>
              <HasPermission permission={["Pages.Payments.Pay"]}>
                <li>
                  <Link to={"/system-logs"}>{L("SystemLogs")}</Link>
                  <i className="pi pi-book"></i>
                </li>
              </HasPermission>
            </ProtectedAuthLink>
            <HasPermission permission={["Pages.Payments.Pay"]}>
              <li>
                <Link to={"/participants-report"}>{L("Reports")}</Link>
                <i className="pi pi-chart-bar"></i>
              </li>
            </HasPermission>
            {user?.registrationId && lastEvent?.isShopActive && (
              <ProtectedAuthLink auth={true}>
                <li>
                  <Link to={"/cart"}>
                    {cart.length > 0 && (
                      <Badge value={cart.length} className="mr-2 mt-2"></Badge>
                    )}
                    {L("ShopMyOrder")}
                  </Link>
                  <i className="pi pi-shopping-cart"></i>
                </li>
              </ProtectedAuthLink>
            )}
            <ProtectedAuthLink auth={true}>
              <li>
                <Link to={"/profile"}>{user?.userName?.split("@")?.[0]}</Link>
                <i className="pi pi-user"></i>
              </li>
            </ProtectedAuthLink>
            <ProtectedAuthLink auth={false}>
              <li>
                <Link to={"/login"}>{L("SignIn")}</Link>
                <i className="pi pi-sign-in"></i>
              </li>
            </ProtectedAuthLink>
            <ProtectedAuthLink auth={true}>
              <Logout>
                <li>
                  <Link to={"/"}>{L("Logout")}</Link>
                  <i className="pi pi-sign-out"></i>
                </li>
              </Logout>
            </ProtectedAuthLink>
          </ul>
          <div className="language-select">
            <LanguageSelect />
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavBar;
