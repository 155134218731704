import http from "../httpService";

class EventService {
  async create(createEventInput) {
    let result = await http.post(
      "api/services/app/Events/Create",
      createEventInput
    );
    return result.data.result;
  }

  async update(updateEventInput) {
    let result = await http.put(
      "api/services/app/Events/Update",
      updateEventInput
    );
    return result.data.result;
  }

  async changeEventStatus(input) {
    let result = await http.post(
      "api/services/app/Events/ChangeEventStatus",
      input
    );
    return result.data.result;
  }

  async delete(entityDto) {
    let result = await http.delete("api/services/app/Events/Delete", {
      params: entityDto,
    });
    return result.data;
  }

  async get(entityDto) {
    let result = await http.get("api/services/app/Events/Get", {
      params: entityDto,
    });
    return result.data;
  }

  async getAll(pagedFilterAndSortedRequest) {
    let result = await http.get("api/services/app/Events/GetAll", {
      params: pagedFilterAndSortedRequest,
    });
    return result.data.result;
  }

  async getLastEvent() {
    let result = await http.get("api/services/app/Events/GetLastEventDetails");
    return result.data.result;
  }
}

export default new EventService();
