import React from "react";
import { Navigate } from "react-router-dom";

var abp = window.abp;
function AccessRoute({ permission, path, children, ...others }) {
  const hasPermission = abp.auth.isAnyGranted(...permission);
  return (
    <div>
      {hasPermission ? children : <Navigate to={"/access"} />}
    </div>
  );
}

export default AccessRoute;
