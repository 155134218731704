import React, { useContext, useState } from "react";
import { RegistrationContext } from "../../../context/Registration/registrationContext";
import { InputText } from "primereact/inputtext";
import { Controller, useForm } from "react-hook-form";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import storageService from "../../../services/storage/storageService";
import { EventContext } from "../../../context/Event/eventContext";
import registrationService from "../../../services/registration/registrationService";
import { L } from "../../../lib/abpUtility";
import { InputTextarea } from "primereact/inputtextarea";
import { useQueryClient } from "react-query";
import userStorageService from "../../../services/storage/userStorageService";

function StepThree() {
  const { register, handleSubmit, control, getValues } = useForm({
    defaultValues: {
      licensePlate: storageService.getItem("licensePlate"),
      phoneNumber: storageService.getItem("phoneNumber"),
      activities: storageService.getItem("activities"),
      additionalInfo: storageService.getItem("additionalInfo"),
    },
  });
  const [loading, setLoading] = useState(false);
  const { setStep, getActivitiesStepThree, onComplete } =
    React.useContext(RegistrationContext);
  const { lastEvent } = useContext(EventContext);
  const [activities, setActivities] = React.useState([]);
  const queryClient = useQueryClient();
  const history = useNavigate();

  React.useEffect(() => {
    setStep(2);
    registrationService.getActivities().then((res) => {
      setActivities(res);
    });
  }, [setStep, setActivities, getActivitiesStepThree]);

  const onSubmit = () => {
    setLoading(true);
    onComplete(lastEvent)
      .then((res) => {
        queryClient.refetchQueries("user");
        userStorageService.resetUser();
        storageService.setItem("id", res.data.result.id);
        storageService.setItem("qrCodeImage", res.data.result.qrCodeImage);
        storageService.setItem("totalDue", res.data.result.totalDue);
        if (res.data.result.canHaveGroup) {
          storageService.setItem("canHaveGroup", res.data.result.canHaveGroup);
        }
        if (res.data.result?.group) {
          storageService.setItem("group", res.data.result?.group);
        }
        if (res.data.result.canHaveGroup) {
          storageService.setItem("groupURL", res.data.result.canHaveGroup);
        }
        history("/registration/step4");
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onActivityChange = () => {
    storageService.setItem(
      "activities",
      JSON.stringify(
        getValues().activities?.filter(
          (item) =>
            item.id !== null && item.id !== undefined && item.id !== false
        )
      )
    );
  };

  return (
    <div className="step">
      <h3>{L("OtherData")}</h3>
      <form
        onChange={(e) => {
          storageService.setItem(e.target.name, e.target.value);
          if (e.target.value === "") {
            storageService.removeItem(e.target.name);
          }
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <h5>{L("LicensePlate")}</h5>
        <InputText
          {...register("licensePlate")}
          placeholder={L("LicensePlatePlaceholder")}
        />

        <h5>{L("Volunteering")}</h5>
        {activities?.map((activity) => (
          <div
            className="checkbox_item"
            key={activity.id}
            style={{ margin: "20px 0", display: "flex", alignItems: "center" }}
          >
            <Controller
              name={`activities.${activity.id}.id`}
              control={control}
              defaultValue={
                JSON.parse(storageService.getItem("activities"))?.find(
                  (item) => item.id === activity.id
                )?.id || undefined
              }
              render={({ field }) => (
                <Checkbox
                  {...field}
                  inputId={activity.text}
                  onChange={(selected) => {
                    field.onChange(!field.value ? activity.id : false);
                    onActivityChange();
                  }}
                  checked={field.value ? true : false}
                />
              )}
            />
            <label
              className="checkbox_label"
              style={{ paddingLeft: "5px" }}
              htmlFor={activity.text}
            >
              {L(activity.text)}
            </label>
          </div>
        ))}

        <h5>{L("PhoneNumber")}</h5>
        <InputText {...register("phoneNumber")}></InputText>

        <h5>{L("AdditionalInfo")}</h5>
        <InputTextarea {...register("additionalInfo")} rows={10} autoResize />

        <div>
          <br></br>
          <Button
            type="submit"
            className="next-button"
            label={L("CompleteButton")}
            disabled={loading}
          />
        </div>
      </form>
    </div>
  );
}

export default StepThree;
