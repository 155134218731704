import React, { useEffect } from "react";
import DaysStep from "../Days/DaysStep";

function RegistrationEditStepOne({
  days,
  watch,
  isView,
  refetchData,
  getValues,
  setValue,
  data,
}) {
  useEffect(() => {
  }, [days, data, getValues]);
  return (
    <>
      <DaysStep
        days={days}
        watch={watch}
        isView={isView}
        refetchData={refetchData}
        setValue={setValue}
      />
    </>
  );
}

export default RegistrationEditStepOne;
