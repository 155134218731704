import React from "react";
import { Link } from "react-router-dom";
import { L } from "../../lib/abpUtility";
import HasPermission from "../permission/HasPermission";

const ReportsMenu = () => {
  return (
    <div
      className="grid"
      style={{
        display: "flex",
        gap: "10px",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
      }}
    >
      <div>
        <HasPermission permission={["Pages.Payments.Pay"]}>
          <Link to="/participants-report">
            <span
              className="flex p-3 shadow-2 mb-3 inline-block surface-card"
              style={{ borderRadius: "10px" }}
            >
              <i
                className="pi pi-home text-3xl text-primary"
                title={L("ParticipantsReport")}
              >
                &nbsp;{L("ParticipantsReport")}
              </i>
            </span>
          </Link>
        </HasPermission>
      </div>
      <div>
        <HasPermission permission={["Pages.Payments.User.Report"]}>
          <Link to="/payments-report">
            <span
              className="p-3 shadow-2 mb-3 inline-block surface-card"
              style={{ borderRadius: "10px" }}
            >
              <i
                className="pi pi-euro text-3xl text-primary"
                title={L("PaymentsReport")}
              >
                &nbsp;{L("PaymentsReport")}
              </i>
            </span>
          </Link>
        </HasPermission>{" "}
      </div>
      <div>
        <HasPermission permission={["Pages.Registrations.Items"]}>
          <Link to="/shop-report">
            <span
              className="p-3 shadow-2 mb-3 inline-block surface-card"
              style={{ borderRadius: "10px" }}
            >
              <i
                className="pi pi-shopping-bag text-3xl text-primary"
                title={L("Shop")}
              >
                &nbsp;{L("Shop")}
              </i>
            </span>
          </Link>
        </HasPermission>{" "}
      </div>
      <div>
        <HasPermission permission={["Pages.Payments.Pay"]}>
          <Link to="/process-notes">
            <span
              className="p-3 shadow-2 mb-3 inline-block surface-card"
              style={{ borderRadius: "10px" }}
            >
              <i
                className="pi pi-info-circle text-3xl text-primary"
                title={L("ProcessNotes")}
              >
                &nbsp;{L("ProcessNotes")}
              </i>
            </span>
          </Link>
        </HasPermission>{" "}
      </div>
    </div>
  );
};

export default ReportsMenu;
