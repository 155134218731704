import { Button } from "primereact/button";
import React from "react";

const Levels = ({ level }) => {
  const mapping = {
    Verbose: "smth",
    Debug: "success",
    Information: "info",
    Warning: "warning",
    Error: "danger",
    Fatal: "help",
  };
  return (
    <div className="flex align-items-center justify-content-center">
      <Button
        label={level}
        className={`p-button-rounded p-button-${mapping[level]}`}
      />
    </div>
  );
};

export default Levels;
