import React from "react";
import tenantService from "../../services/tenant/tenantService";

export const TenantContext = React.createContext();

async function create(createTenantInput) {
  let result = await tenantService.create(createTenantInput);
  return result;
}

async function update(updateTenantInput) {
  let result = await tenantService.update(updateTenantInput);

  return result;
}

async function deleteTenant(entityDto) {
  let result = await tenantService.delete(entityDto);
  return result;
}

async function get(entityDto) {
  let result = await tenantService.get(entityDto);
  return result;
}

async function getAll(pagedFilterAndSortedRequest) {
  let result = await tenantService.getAll(pagedFilterAndSortedRequest);
  return result;
}

export const tenantsContextValues = {
  create,
  update,
  deleteTenant,
  get,
  getAll,
};
