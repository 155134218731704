import React, { useContext, useEffect, useState } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { useParams, useNavigate } from "react-router-dom";
import registrationService from "../../services/registration/registrationService";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import RegistrationEditStepOne from "../../components/registration/RegistrationEdit/RegistrationEditStepOne";
import RegistrationEditStepTwo from "../../components/registration/RegistrationEdit/RegistrationEditStepTwo";
import RegistrationEditStepThree from "../../components/registration/RegistrationEdit/RegistrationEditStepThree";
import { EventContext } from "../../context/Event/eventContext";
import "../../styles/Registration/register.scss";
import { L } from "../../lib/abpUtility";
import "../../styles/Registration/register.scss";
import { errorRef } from "../../App";
import { ThemeContext } from "../../context/Theme/themeContext";
import { Divider } from "primereact/divider";
import { useQuery, useQueryClient } from "react-query";

function RegistrationEdit() {
  const { colorScheme } = useContext(ThemeContext);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const { lastEvent } = useContext(EventContext);
  const { register, handleSubmit, reset, control, setValue, watch, getValues } =
    useForm({});

  const { data, isLoading: isRegistrationLoading } = useQuery({
    queryKey: ["registration", id],
    queryFn: () => registrationService.get(id),
    enabled: !!id,
  });

  let { data: activitiesItems, isLoading: isActivitiesLoading } = useQuery({
    queryKey: ["activities"],
    queryFn: () => registrationService.getActivities(),
  });

  let activities = activitiesItems
    ? activitiesItems?.map((a) => ({ ...a, label: L(a.text) }))
    : [];

  const { data: days, isLoading: isDaysLoading } = useQuery({
    queryKey: ["days", lastEvent],
    queryFn: () => registrationService.getDays(lastEvent),
    enabled: !!lastEvent,
  });

  const queryClient = useQueryClient();

  useEffect(() => {
    reset(data);
  }, [data, reset]);

  const navigate = useNavigate();

  const refetchData = () => {
    let values = getValues();
    reset(values);
  };

  const onSubmit = (data) => {
    queryClient.invalidateQueries({ queryKey: ["registrations"] });
    queryClient.invalidateQueries({ queryKey: ["registration", id] });
    if (data["days"].find((i) => i?.accommodation?.id > 0)) {
      if (!data.address || !data.middleName || !data.ucn) {
        errorRef.current.show({
          severity: "error",
          summary: L("Error"),
          detail: L("RegEditError"),
          sicky: true,
        });
        setLoading(false);
        return;
      }
    }

    setLoading(true);
    data.personActivities = data.personActivities.filter(
      (activity) => activity.activityId
    );
    data.personActivities = data.personActivities.map((i) => {
      return {
        id: i.activityId,
      };
    });
    data["days"] = data["days"].filter(
      (i) => i.presence?.id && i.eventDayId > 0
    );
    data["days"] = data["days"].map((i) => {
      return {
        ...i,
        presence: i?.presence?.id ? { id: i.presence?.id } : null,
        accommodation: i?.accommodation?.id
          ? { id: i.accommodation?.id }
          : null,
      };
    });
    data["days"].forEach((i) => (i.eventDayId = parseInt(i.eventDayId)));

    data["activities"] = data["personActivities"];

    registrationService
      .update(data)
      .then(() => {
        setLoading(false);

        navigate(-1);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  return (
    <div className={`container ${colorScheme === "dark" && "container-dark"}`} style={{padding: "10px 0px"}}>
      {!loading &&
      !isActivitiesLoading &&
      !isRegistrationLoading &&
      !isDaysLoading ? (
        <form className="p-3" onSubmit={handleSubmit(onSubmit)}>
          <div
            id="buttons"
            className="flex mb-3"
            style={{
              gap: "10px",
              flexWrap: "wrap",
            }}
          >
            <div>
              <Button
                label={L("GoBack")}
                type="button"
                className="p-button-help p-button-text"
                onClick={() => navigate(-1)}
              />
            </div>
            <div>
              <Button label={L("Save")} type="submit" />
            </div>
          </div>
          <Divider />
          <div
            style={{
              display: "flex",
              gap: "10px",
              justifyContent: "space-evenly",
              flexWrap: "wrap",
            }}
          >
            <div className="col-12 md:col-4 text-center md:text-left">
              {data && days && (
                <RegistrationEditStepOne
                  register={register}
                  data={data}
                  control={control}
                  days={days}
                  setValue={setValue}
                  watch={watch}
                  refetchData={refetchData}
                  getValues={getValues}
                  reset={reset}
                />
              )}
            </div>
            <div className="col-12 md:col-3 text-center md:text-left">
              {data && register && (
                <RegistrationEditStepTwo
                  register={register}
                  control={control}
                  isView={false}
                  getValues={getValues}
                />
              )}
            </div>
            <div className="col-12 md:col-3 text-center md:text-left">
              {data && activities && (
                <RegistrationEditStepThree
                  register={register}
                  data={data}
                  activities={activities}
                  control={control}
                />
              )}
            </div>
          </div>
        </form>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ProgressSpinner />
        </div>
      )}
    </div>
  );
}

export default RegistrationEdit;
