import React from "react";
import userService from "../../services/user/userService";

export const UserContext = React.createContext();

async function create(createUserInput) {
  let result = await userService.create(createUserInput);
  return result;
}

async function update(updateUserInput) {
  let result = await userService.update(updateUserInput);
  return result;
}

async function deleteUser(entityDto) {
  let result = await userService.delete(entityDto);
  return result;
}

async function getRoles() {
  let result = await userService.getRoles();
  return result
}

async function get(entityDto) {
  let result = await userService.get(entityDto);
  return result;
}

async function getAll(pagedFilterAndSortedRequest) {
  let result = await userService.getAll(pagedFilterAndSortedRequest);
  return result;
}

async function changeLanguage(languageName) {
  await userService.changeLanguage({ languageName: languageName });
}

export const userContextValues = {
  create,
  update,
  deleteUser,
  getRoles,
  get,
  getAll,
  changeLanguage,
};
