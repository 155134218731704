import http from "../httpService";
import { getTenantId } from "../tenant/getTenantId";

const tenantId = getTenantId();

class ForgotPasswordService {
  requestForgotPassword(username) {
    const result = http.post("api/services/app/User/ForgotPasswordRequest", {
      tenantId: tenantId,
      username: username,
    });
    return result;
  }
  forgotPasswordReset(resetCode, newPassword, repeatPassword) {
    const result = http.post("api/services/app/User/ForgotPasswordReset", {
      resetCode: resetCode,
      newPassword: newPassword,
      repeatPassword: repeatPassword,
      tenantId: tenantId,
    });
    return result;
  }
}

export default new ForgotPasswordService();
