import { useMemo } from "react";
import { useLocation } from "react-router-dom";

function useQueryValues() {
  const location = useLocation();
  const params = useMemo(() => {
    return new Proxy(new URLSearchParams(location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
  }, [location.search]);
  return params;
}

export { useQueryValues };
