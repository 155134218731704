import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState, useContext } from "react";
import { AuthenticationContext } from "../../context/Authentication/authenticationContext";
import { UserContext } from "../../context/User/userContext";

var abp = window.abp;

const LanguageSelect = () => {
  const [languages, setLanguages] = useState([]);
  const { changeLanguage } = useContext(UserContext);
  const context = useContext(AuthenticationContext);
  const isAuth = context.isAuthenticated();
  const [selectedLanguage, setSelectedLanguage] = useState();

  useEffect(() => {
    const languages = abp.localization.languages.filter((val) => {
      return !val.isDisabled;
    });

    setLanguages(languages);
    setSelectedLanguage(abp.localization.currentLanguage);
  }, []);

  const changeSelectedLanguage = async (lang) => {
    setSelectedLanguage(lang);
    if (isAuth) {
      await changeLanguage(lang.name);
    }
    abp.utils.setCookieValue(
      "Abp.Localization.CultureName",
      lang.name,
      new Date(new Date().getTime() + 5 * 365 * 86400000), //5 year
      abp.appPath
    );

    window.location.reload();
  };

  const optionTemplate = (option) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {option?.icon && (
          <img
            className={`flag flag-${option?.icon.replace(
              "famfamfam-flags ",
              ""
            )}`}
          />
        )}
        <div style={{ marginLeft: "10px" }}>{option?.displayName}</div>
      </div>
    );
  };

  return (
    <div>
      <Dropdown
        options={languages}
        value={selectedLanguage}
        valueTemplate={optionTemplate}
        itemTemplate={optionTemplate}
        onChange={(event) => changeSelectedLanguage(event.target.value)}
        placeholder="Select language"
        optionLabel="displayName"
      />
    </div>
  );
};

export default LanguageSelect;
