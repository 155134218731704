import React from "react";
import eventService from "../../services/event/eventService";

export const EventContext = React.createContext();

async function create(createEventInput) {
  let result = await eventService.create(createEventInput);
  return result;
}

async function update(updateEventInput) {
  let result = await eventService.update(updateEventInput);
  return result;
}

async function deleteEvent(entityDto) {
  let result = await eventService.delete(entityDto);
  return result;
}

async function get(entityDto) {
  let result = await eventService.get(entityDto);
  return result;
}

async function getAll(pagedFilterAndSortedRequest) {
  let result = await eventService.getAll(pagedFilterAndSortedRequest);
  return result;
}

async function changeLanguage(languageName) {
  await eventService.changeLanguage({ languageName: languageName });
}

async function getEventStateOptions() {
  const result = await eventService.getEventStateOptions();
  return result;
}

export const eventContextValues = {
  create,
  update,
  deleteEvent,
  get,
  getAll,
  changeLanguage,
  getEventStateOptions,
};
