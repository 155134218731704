import { Button } from "primereact/button";
import { Password } from "primereact/password";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { errorRef } from "../../App";
import userService from "../../services/user/userService";
import { L } from "../../lib/abpUtility";

function UserChangePassword() {
  const { handleSubmit, control } = useForm({
    defaultValues: {
      currentPassword: "",
      newPassword: "",
    },
  });
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const onSubmit = async (data) => {
    setLoading(true);
    if (data.newPassword === data.repeatNewPassword) {
      userService
        .changePassword(data.currentPassword, data.newPassword)
        .then((res) => {
          errorRef.current.show({
            severity: "success",
            summary: L("Success"),
            detail: L("PassChangeSuccess"),
            life: 3000,
          });
          history("/");
        });
    } else {
      setLoading(false);
      errorRef.current.show({
        severity: "error",
        summary: L("Error"),
        detail: L("PassChangeError"),
        sticky: true
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={`service-div`}>
        <h2>{L("PassChangeHeader")}</h2>
        <p style={{ margin: "0px 0px 14px 0px" }}>{L("PassChangeParagraph")}</p>
        <Controller
          control={control}
          name="currentPassword"
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <Password
              placeholder={L("CurrPass")}
              value={value}
              onChange={(e) => onChange(e.target.value)}
            ></Password>
          )}
        ></Controller>
        <Controller
          control={control}
          name="newPassword"
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <Password
              placeholder={L("NewPass")}
              value={value}
              onChange={(e) => onChange(e.target.value)}
            ></Password>
          )}
        ></Controller>
        <Controller
          control={control}
          name="repeatNewPassword"
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <Password
              placeholder={L("RepeatPass")}
              value={value}
              onChange={(e) => onChange(e.target.value)}
            ></Password>
          )}
        ></Controller>
        <Button
          label={L("Change")}
          type="submit"
          disabled={loading}
        ></Button>
      </div>
    </form>
  );
}

export default UserChangePassword;
