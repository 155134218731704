import { Dialog } from "primereact/dialog";
import { MultiSelect } from "primereact/multiselect";
import React, { useState, useEffect } from "react";
import Table from "../../components/crud/Table";
import { L } from "../../lib/abpUtility";
import systemLogsService from "../../services/systemLogs/systemLogsService";
import Levels from "./Levels";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";

var abp = window.abp;

function SystemLogs() {
  const [logs, setLogs] = useState();
  const [loading, setLoading] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(5);
  const [tableLoading, setTableLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const [keyword, setKeyword] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [availableLevels, setAvailableLevels] = useState([]);
  const [displayModal, setDisplayModal] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(1);
  const [tableSelection, setTableSelection] = useState([]);

  const handlePageChange = (e) => {
    setTableLoading(true);
    systemLogsService
      .getAll({
        SkipCount: e.first,
        MaxResultCount: e.rows,
        Keywords: keyword,
        ...filters,
      })
      .then((res) => {
        setTotalRecords(res.totalCount);
        setLogs(res.items);
        setTableLoading(false);
      });
    setFirst(e.first);
    setRows(e.rows);
  };

  const getAllSystemLogs = () => {
    setTableLoading(true);
    systemLogsService
      .getAll({
        MaxResultCount: rows,
        Keyword: keyword,
        Sorting:
          sortField &&
          sortOrder !== 0 &&
          `${sortField} ${sortOrder === -1 ? "DESC" : "ASC"}`,
        ...filters,
      })
      .then((res) => {
        setLogs(res.items);
        setTotalRecords(res.totalCount);
        systemLogsService.getAvailableLevels().then((res) => {
          const levels = res.map((i) => {
            return { ...i, label: L(i.text) };
          });
          setAvailableLevels(levels);
          setLoading(false);
          setTableLoading(false);
        });
      });
  };
  useEffect(() => {
    getAllSystemLogs();
  }, [keyword, filters, sortField, sortOrder]); // eslint-disable-line react-hooks/exhaustive-deps

  let timer;

  const showFields = [
    {
      field: "message",
      header: L("Message"),
      sortable: true,
      type: "string",
      body: (e) => {
        return (
          <p
            className="message-link"
            onClick={() => {
              setSelectedData(e);
              setDisplayModal(true);
            }}
          >
            {e.message.substring(0, 150)}...
          </p>
        );
      },
    },
    {
      field: "timeStamp",
      header: L("TimeStamp"),
      sortable: true,
      type: "date",
      body: (e) => {
        return <p>{new Date(e.timeStamp).toLocaleString()}</p>;
      },
    },
    {
      field: "level",
      header: L("Level"),
      sortable: true,
      type: "smth",
      body: (e) => {
        return <Levels level={e.level} />;
      },
    },
  ];

  const handleGlobalFilterChange = (e) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      setKeyword(e);
    }, 300);
  };

  const onChangeStartDate = (key, value) => {
    setFilters({ ...filters, [key]: value });
  };

  const onChangeEndDate = (key, value) => {
    setFilters({ ...filters, [key]: value });
  };

  const onChangeLevel = (key, value) => {
    setFilters({ ...filters, [key]: value });
  };

  const handleSort = (e) => {
    setSortOrder(e?.sortOrder);
    setSortField(e.sortOrder === 0 ? null : e?.sortField);
  };

  const onSelectionChange = (selection) => {
    setTableSelection(selection);
  };

  const onDelete = async () => {
    setLoading(true);
    const ids = tableSelection.map((i) => i.id);
    await systemLogsService.bulkDelete(ids).then(() => {
      getAllSystemLogs();
    });
    setLoading(false);
  };

  const ExtendHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        <Button
          disabled={tableSelection.length < 1}
          label={"Delete " + tableSelection?.length + " items"}
          severity="danger"
          className="m-2"
          onClick={onDelete}
        />
        <Calendar
          name="startDate"
          placeholder={L("Start")}
          style={{ width: "135px", marginRight: "15px" }}
          showTime
          hourFormat="24"
          yearRange={"2022:" + new Date().getFullYear()}
          onChange={(e) => onChangeStartDate("TimeStampFrom", e.target.value)}
        />

        <Calendar
          name="endDate"
          placeholder={L("End")}
          style={{ width: "135px", marginRight: "15px" }}
          showTime
          hourFormat="24"
          yearRange={"2022:" + new Date().getFullYear()}
          onChange={(e) => onChangeEndDate("TimeStampTo", e.target.value)}
        />

        <MultiSelect
          value={filters["Levels"]}
          options={availableLevels}
          optionLabel="label"
          placeholder={L("Levels")}
          showSelectAll={true}
          optionValue="text"
          onChange={(e) => onChangeLevel("Levels", e.target.value)}
        />
      </div>
    );
  };

  return (
    <div>
      <Dialog
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        modal
        header="More Info"
        visible={displayModal}
        onHide={() => setDisplayModal(false)}
      >
        <p className="message-extend" style={{ color: "red" }}>
          {selectedData?.exception}
        </p>
        <p className="message-extend">{selectedData?.message}</p>
      </Dialog>

      <Table
        fields={showFields}
        extendHeader={ExtendHeader}
        data={logs}
        loading={loading}
        headerText={L("Manage") + L("Logs")}
        lazy={true}
        showActions={false}
        handleGlobalFilterChange={handleGlobalFilterChange}
        setShowDialog={setShowDialog}
        showDialog={showDialog}
        first={first}
        totalRecords={totalRecords}
        rows={rows}
        handlePageChange={handlePageChange}
        multySelect={true}
        tableLoading={tableLoading}
        onSort={(e) => handleSort(e)}
        tableProps={{
          rows: rows,
          rowsPerPageOptions: [5, 10, 25, 50, 100],
        }}
        onSelection={onSelectionChange}
      />
    </div>
  );
}

export default SystemLogs;
