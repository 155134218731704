import http from "../httpService";

class SystemLogsService {
  async getAll(pagedFilterAndSortedRequest) {
    let result = await http.get("api/services/app/SystemLogs/GetOverview", {
      params: pagedFilterAndSortedRequest,
    });
    return result.data.result;
  }

  async bulkDelete(ids) {
    let result = await http.post("api/services/app/SystemLogs/BulkDelete", ids);
    return result.data.result;
  }

  async getAvailableLevels(pagedFilterAndSortedRequest) {
    let result = await http.get(
      "api/services/app/SystemLogs/GetAvailableLevels",
      {
        params: pagedFilterAndSortedRequest,
      }
    );
    return result.data.result;
  }
}

export default new SystemLogsService();
