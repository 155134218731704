import React, {useEffect} from "react";
import ReportsMenu from "../../components/menus/ReportsMenu";
import EditorWrapper from "../../components/Editor/Editor";

export const ProcessNotes = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <div className="p-grid">
      <div>
        <ReportsMenu></ReportsMenu>
      </div>
      <div className="p-col-12">
        <div className="card lg:p-6">
          <EditorWrapper name="process-notes">
          </EditorWrapper >
        </div>
      </div>
    </div >
  );
};

export default ProcessNotes;
