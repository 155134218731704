import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import InitContext from "./context/initContext";
import { Error } from "./pages/freya/Error";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { useConfigurations } from "./index-bootstraper";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function LoadConfigurations() {
  const { isLoading, error } = useConfigurations();

  if (isLoading) {
    return (
      <div className="loader">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
    );
  }

  if (error) {
    return (
      <Error
        title={
          "За съжаление сайтът ни не е достъпен в момента. Моля опитайте по-късно."
        }
      />
    );
  }

  return (
    <BrowserRouter>
      <InitContext>
        <App></App>
      </InitContext>
    </BrowserRouter>
  );
}

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools initialIsOpen={false} />
    <Suspense fallback={<div>Loading...</div>}>
      <LoadConfigurations />
    </Suspense>
  </QueryClientProvider>,
  document.getElementById("root")
);
