import http from "./httpService";

class AbpUserConfigurationService {
  async getAll() {
    const result = await http.get("/AbpUserConfiguration/GetAll");
    return result;
  }
  async getUser() {
    const result = await http.get(
      "/api/services/app/Session/GetCurrentLoginInformations"
    );
    return result.data.result.user;
  }
}

export default new AbpUserConfigurationService();
