import React from "react";
import { Navigate } from "react-router-dom";
import storageService from "../../services/storage/storageService";

function GuardedRouteStepFour({
  component,
  redirectTo,
  children,
}) {
  return (
    <div>
      {storageService.getItem("id") ? (
        children
      ) : (
        <Navigate to={redirectTo} />
      )}
    </div>
  );
}

export default GuardedRouteStepFour;
