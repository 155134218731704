import { DataTable } from "primereact/datatable";
import { useCart } from "../../context/shop/shopContext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { composeShopImageName } from "../Items/shopUtils";
import { useRef, useState } from "react";
import { ConfirmPopup } from "primereact/confirmpopup";
import "./myOrders.scss";
import { L } from "../../lib/abpUtility";
import localizationService from "../../services/localization/localizationService";

export const MyOrders = () => {
  const { cart, removeFromCart, isLoading } = useCart();

  const renderProduct = (rowData) => {
    const product = rowData?.itemDto;
    const imageName = composeShopImageName(
      product.id,
      rowData.specifications?.gender,
      rowData.specifications?.color
    );
    const image =
      product.images?.find((img) => img.fullName.includes(imageName)) ||
      product.images?.[0];
    return (
      product && (
        <div className="flex">
          {image && (
            <img
              alt=""
              src={image?.url}
              style={{
                width: "80px",
                height: "80px",
                objectFit: "contain",
              }}
            />
          )}
          <h4 className="ml-5">
            {localizationService.localizePipe(product.specifications?.title)}
          </h4>
        </div>
      )
    );
  };
  const renderHeader = () => (
    <div>
      <h3>{L("MyOrders")}</h3>
    </div>
  );

  const RenderRemoveItem = ({ rowData }) => {
    const [visible, setVisible] = useState(false);
    const buttonRef = useRef(null);
    return (
      <div>
        <ConfirmPopup
          target={buttonRef.current}
          visible={visible}
          onHide={() => {
            setVisible(false);
          }}
          message={L(
            "AreYouSureWantToDelete",
            localizationService.localizePipe(
              rowData.itemDto.specifications?.title
            )
          )}
          icon="pi pi-exclamation-triangle"
          accept={() => removeFromCart(rowData?.id)}
          reject={() => {}}
        />
        <Button
          ref={buttonRef}
          icon="fa fa-xmark"
          disabled={isLoading}
          className="p-button-secondary p-button-rounded"
          onClick={() => setVisible(true)}
        />
      </div>
    );
  };

  return (
    <div className="card my-orders">
      <DataTable value={cart} header={renderHeader} loading={isLoading}>
        <Column
          field="itemDto.id"
          header={L("ShopProduct")}
          body={renderProduct}
        />
        <Column
          field="gender"
          header={L("Gender")}
          body={(data) => (
            <Button
              className="p-button-help no-outlined"
              label={L(data.specifications?.gender)}
            />
          )}
        />
        <Column
          field="specifications.size"
          header={L("Size")}
          body={(data) => (
            <Button
              label={data.specifications?.size?.toUpperCase()}
              className="p-button-outlined p-button-danger no-outlined"
            />
          )}
        />
        <Column
          field="color"
          header={L("Color")}
          body={(data) => (
            <Button
              className="no-outlined"
              style={{
                backgroundColor: `#${data.specifications?.color}`,
                width: "50px",
              }}
            />
          )}
        />
        <Column
          field="quantity"
          header={L("Quantity")}
          body={(data) => (
            <Button
              label={data.specifications?.quantity}
              className="p-button-outlined no-outlined"
            />
          )}
        />
        <Column body={(rowData) => <RenderRemoveItem rowData={rowData} />} />
      </DataTable>
    </div>
  );
};
