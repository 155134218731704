import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import shopService from "../../services/shop/shopService";
import { useQuery } from "react-query";
import { FloatLabel } from "primereact/floatlabel";
import { Column } from "primereact/column";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { L } from "../../lib/abpUtility";
import { Dialog } from "primereact/dialog";
import { Image } from "primereact/image";
import { Controller, useForm } from "react-hook-form";
import { InputNumber } from "primereact/inputnumber";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { ColorPicker } from "primereact/colorpicker";
import { InputText } from "primereact/inputtext";
import { ListBox } from "primereact/listbox";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Checkbox } from "primereact/checkbox";
import { MultiSelect } from "primereact/multiselect";
import { GenderColorImagesDialog } from "./ItemsImagesDialog";
import HasPermission from "../../components/permission/HasPermission";

export const Items = () => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [imagesDialog, setImagesDialog] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["items"],
    queryFn: () => shopService.getAll(),
  });
  const [dialogLoading, setDialogLoading] = useState(false);

  const rightToolbarTemplate = () => {
    return <React.Fragment></React.Fragment>;
  };

  const openCreateModal = () => {
    setShowDialog(true);
  };
  const openDeleteDialog = (data) => {
    setSelectedData(data);
    setShowDeleteDialog(true);
  };
  const openEditDialog = (data) => {
    setSelectedData(data);
    setShowDialog(true);
  };

  const onCreateSubmit = async (data) => {
    setDialogLoading(true);
    await shopService.create(data);
    await refetch();
    setDialogLoading(false);
    setShowDialog(false);
    setSelectedData(null);
  };

  const onEditSubmit = async (data) => {
    setDialogLoading(true);
    await shopService.update(data);
    await refetch();
    setDialogLoading(false);
    setShowDialog(false);
    setSelectedData(null);
  };

  const onDelete = async () => {
    await shopService.delete({ id: selectedData.id });
    setSelectedData(null);
    await refetch();
  };

  const leftToolbarTemplate = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <HasPermission permission={["Pages.Item.Create"]}>
          <Button
            label={L("New")}
            icon="pi pi-plus"
            onClick={() => openCreateModal()}
            className="p-button-success p-mr-2"
          />
        </HasPermission>
      </div>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div
        className="actions"
        style={{ display: "flex", alignItems: "center", gap: "5px" }}
      >
        <HasPermission permission={["Pages.Item.Edit"]}>
          <Button
            icon="pi pi-pencil"
            className="p-button-rounded p-button-success p-mr-2"
            onClick={() => openEditDialog(rowData)}
          />
        </HasPermission>
        <HasPermission permission={["Pages.Item.Remove"]}>
          <Button
            icon="pi pi-trash"
            className="p-button-rounded p-button-danger p-mr-2"
            onClick={() => openDeleteDialog(rowData)}
          />
        </HasPermission>
        <HasPermission permission={["Pages.Item.Edit"]}>
          <Button
            icon="pi pi-images"
            className="p-button-rounded p-button-help p-mr-2"
            onClick={() => {
              setSelectedData(rowData);
              setImagesDialog(true);
            }}
          />
        </HasPermission>
      </div>
    );
  };

  const deleteDialogFooter = (
    <>
      <Button
        label={L("No")}
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => setShowDeleteDialog(false)}
      />
      <Button
        label={L("Yes")}
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => {
          onDelete(selectedData);
          setShowDeleteDialog(false);
        }}
      />
    </>
  );

  return (
    <div className="card">
      <Toolbar
        className="p-mb-4 p-toolbar"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      />
      <DataTable
        loading={isLoading}
        value={data?.items || []}
        header={"Manage Shop Items"}
      >
        <Column
          field="images"
          header="Image"
          body={(rowData) =>
            rowData.images?.length > 0 ? (
              <RenderImagePreview rowData={rowData} />
            ) : (
              <p>N/A</p>
            )
          }
        />
        <Column field="specifications.title" header="Title" />
        <Column field="type" header="Type" />
        <Column field="price" header="Price" />
        <Column field="qty" header="Quantity" />
        <Column
          body={actionBodyTemplate}
          header={L("Actions")}
          field="id"
        ></Column>
      </DataTable>

      <Dialog
        visible={showDialog}
        style={{ width: "550px" }}
        header={"Create Item"}
        draggable={false}
        onHide={() => {
          setSelectedData(null);
          setShowDialog(false);
        }}
      >
        <CrudDialog
          selectedData={selectedData}
          action={selectedData ? onEditSubmit : onCreateSubmit}
          loading={dialogLoading}
        />
      </Dialog>
      <Dialog
        visible={showDeleteDialog}
        style={{ width: "450px" }}
        header={L("Confirm")}
        modal
        footer={deleteDialogFooter}
        onHide={() => setShowDeleteDialog(false)}
      >
        <div
          className="confirmation-content"
          style={{ display: "flex", alignItems: "center", gap: "20px" }}
        >
          <i
            className="pi pi-exclamation-triangle p-mr-3"
            style={{ fontSize: "2rem" }}
          />

          <span>{"Are you sure you want to proceed?"}</span>
        </div>
      </Dialog>
      <GenderColorImagesDialog
        visible={imagesDialog}
        selectedData={selectedData}
        setVisible={setImagesDialog}
        setSelectedData={setSelectedData}
      />
    </div>
  );
};

const RenderImagePreview = ({ rowData }) => {
  const [idx, setIdx] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIdx((idx) => (idx + 1) % rowData.images.length);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Image
      src={rowData.images?.[idx]?.url || ""}
      width="80px"
      height="80px"
      imageStyle={{ objectFit: "contain" }}
      preview
    />
  );
};

const schema = z.object({
  id: z.string().uuid().optional(),
  type: z.string(),
  price: z.number().min(0),
  qty: z.number(),
  images: z
    .array(
      z.object({ id: z.string().uuid(), url: z.string(), fullName: z.string() })
    )
    .optional(),
  specifications: z.object({
    colors: z.array(z.string()).optional(),
    sizes: z.array(z.string()).optional(),
    title: z.string().optional(),
    genders: z.array(z.string()).optional(),
    description: z.string().optional(),
    quantities: z
      .array(
        z.object({
          gender: z.string(),
          color: z.string(),
          size: z.string(),
          quantity: z.number(),
        })
      )
      .optional(),
  }),
});

const specifications = [
  { label: "Title", value: "title" },
  { label: "Genders", value: "genders" },
  { label: "Sizes", value: "sizes" },
  { label: "Colors", value: "colors" },
  { label: "Description", value: "description" },
];

const typeOptions = [
  { label: "Clothing", value: "clothing" },
  { label: "Souvenirs", value: "souvenirs" },
];

const default_new_options = {
  type: "clothing",
  specifications: {},
};

const CrudDialog = ({ selectedData, action, loading }) => {
  const [specificationsOptions, setSpecificationsOptions] = useState([]);
  const {
    handleSubmit,
    control,
    setValue,
    reset,
  } = useForm({
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    if (!selectedData) {
      reset(default_new_options);
      return;
    }
    const getData = async () => {
      const data = await shopService.get({ id: selectedData.id });
      const filteredKeys = Object.keys(
        Object.fromEntries(
          Object.entries(data.specifications).filter(
            ([key, value]) => value !== null
          )
        )
      );
      setSpecificationsOptions(filteredKeys);
      reset(data);
    };
    getData();
  }, [selectedData, reset]);

  const onSubmit = async (data) => {
    await action(data);
  };

  const getOptionField = (option) => {
    const name = `specifications.${option}`;
    switch (option) {
      case "title":
        return (
          <Controller
            defaultValue={""}
            name={name}
            control={control}
            render={({ field }) => (
              <InputText
                className="w-full"
                placeholder="Enter title here..."
                {...field}
              />
            )}
          />
        );
      case "genders":
        return (
          <Controller
            defaultValue={""}
            name={name}
            control={control}
            render={({ field }) => (
              <MultiSelect
                options={["MALE", "FEMALE", "KIDS", "ALL"]}
                optionValue=""
                optionLabel=""
                onChange={(e) => field.onChange(e.value)}
                value={field.value}
                className="w-full"
                placeholder="Genders selection..."
              />
            )}
          />
        );
      case "sizes":
        return (
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <ListBox
                itemTemplate={(option) => option?.toUpperCase()}
                options={["s", "m", "l", "xl", "xxl", "one"]}
                multiple
                optionValue=""
                optionLabel=""
                {...field}
              />
            )}
          />
        );
      case "description":
        return (
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <InputTextarea
                placeholder="Enter description here"
                className="w-full"
                {...field}
                rows={5}
              />
            )}
          />
        );
      case "images":
        return (
          <Controller
            name={"images"}
            control={control}
            render={({ field }) => (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {field.value?.map(
                  (i) => i.url && <Image width="100px" key={i.id} src={i.url} />
                )}
              </div>
            )}
          />
        );
      case "colors":
        return (
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <div>
                {field.value?.map((color, idx) => (
                  <div className="flex mb-2" style={{ gap: "5px" }} key={idx}>
                    <ColorPicker
                      value={color}
                      onChange={(e) => {
                        const modified = field.value;
                        modified[idx] = e.value;
                        field.onChange(modified);
                      }}
                    />
                    <InputText
                      value={`#${color}`}
                      onChange={(e) => {
                        const modified = field.value;
                        modified[idx] = e.target.value?.replace("#", "");
                        field.onChange(modified);
                      }}
                    />
                    <Button
                      type="button"
                      icon="pi pi-times"
                      className="p-button-secondary p-button-outlined"
                      onClick={() =>
                        field.onChange(field.value.filter((i) => i !== color))
                      }
                    />
                  </div>
                ))}
                <Button
                  label="Add new color"
                  type="button"
                  onClick={() =>
                    field.onChange(
                      field.value?.[0] ? [...field.value, "ffffff"] : ["ffffff"]
                    )
                  }
                />
              </div>
            )}
          />
        );
      default:
        return null;
    }
  };

  const onOptionChange = (option, checked) => {
    if (!checked) {
      setSpecificationsOptions((prev) => prev.filter((i) => i !== option));
      const field = `specifications.${option}`;
      setValue(field, undefined);
    } else {
      setSpecificationsOptions((prev) => [...prev, option]);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ display: "flex", gap: "22px", flexDirection: "column" }}
    >
      <Controller
        name="type"
        control={control}
        render={({ field }) => (
          <Dropdown
            {...field}
            placeholder="Select a type"
            options={typeOptions}
          />
        )}
      />
      <FloatLabel>
        <Controller
          name="price"
          control={control}
          render={({ field, formState }) => (
            <InputNumber
              id="price-field"
              value={field.value}
              invalid={formState.errors.price}
              onChange={(e) => {
                const numericValue = parseFloat(e.value);
                if (!isNaN(numericValue)) {
                  field.onChange(numericValue);
                }
              }}
              mode="currency"
              style={{ width: "100%" }}
              currency="BGN"
              locale="en-US"
            />
          )}
        />
        <label htmlFor="price-field">Price</label>
      </FloatLabel>

      <FloatLabel>
        <Controller
          name="qty"
          control={control}
          render={({ field, formState }) => (
            <InputNumber
              id="quantity-field"
              invalid={formState.errors.qty}
              style={{ width: "100%" }}
              value={field.value}
              onChange={(e) => {
                const numericValue = parseInt(e.value);
                if (!isNaN(numericValue)) {
                  field.onChange(numericValue);
                }
              }}
            />
          )}
        />
        <label htmlFor="quantity-field">Quantity</label>
      </FloatLabel>
      <Divider />

      {specifications.map((option) => (
        <div className="flex align-items-center" key={option.value}>
          <Checkbox
            inputId={option.value}
            onChange={(e) => onOptionChange(option.value, e.target.checked)}
            checked={
              specificationsOptions.find((i) => i === option.value)
                ? true
                : false
            }
          />
          <label htmlFor={option.value} className="ml-2">
            {option.label}
          </label>
        </div>
      ))}

      <Divider />

      {specificationsOptions.map((option) => (
        <div key={option}>
          <p
            className="mb-2 mt-2"
            style={{ fontSize: "1.1rem", fontWeight: 500 }}
          >
            {capitalizeFirstLetter(option)}
          </p>
          {getOptionField(option)}
        </div>
      ))}

      <Button label="Save" disabled={loading} loading={loading} />
    </form>
  );
};

function capitalizeFirstLetter(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}
