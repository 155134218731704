import React, { useEffect, useRef } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ProtectedRoute from "./components/routing/ProtectedRoute";
import MenuLayout from "./components/menus/MenuLayout";
import Roles from "./pages/Roles/Roles";
import PaymentsReport from "./pages/Reports/PaymentsReport";
import ParticipantsReport from "./pages/Reports/ParticipantsReport";
import ProcessNotes from "./pages/Reports/ProcessNotes";
import Tenants from "./pages/Tenants/Tenants";
import Users from "./pages/Users/Users";
import Events from "./pages/Events/Events";
import Home from "./pages/Home/index";
import Login from "./pages/Login/Login";
import { Error } from "./pages/freya/Error";
import { Access } from "./pages/freya/Access";
import Registration from "./pages/Registration/Registration";
import StepTwo from "./components/registration/StepTwo/StepTwo";
import StepOne from "./components/registration/StepOne/StepOne";
import StepThree from "./components/registration/StepThree/StepThree";
import StepFour from "./components/registration/StepFour/StepFour";
import { Toast } from "primereact/toast";
import Profile from "./pages/Profile/Profile";
import Register from "./pages/Register/Register";
import { ThemeContext } from "./context/Theme/themeContext";
import GuardedRouteStepOne from "./components/routing/GuardedRouteStepOne";
import GuardedRouteStepTwo from "./components/routing/GuardedRouteStepTwo";
import GuardedRouteStepThree from "./components/routing/GuardedRouteStepThree";
import GuardedRouteStepFour from "./components/routing/GuardedRouteStepFour";
import { RegistrationTable } from "./pages/RegistrationTable/RegistrationTable";
import RegistrationDetails from "./pages/RegistrationDetails/RegistrationDetails";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import UserForgotPassword from "./pages/Users/UserForgotPassword";
import UserChangePassword from "./pages/Users/UserChangePassword";
import RegistrationEdit from "./pages/RegistrationEdit/RegistrationEdit";
import BrotherSupport from "./pages/BasicContent/BrotherSupport";
import Conditions from "./pages/BasicContent/Conditions";
import FeesAndDocuments from "./pages/BasicContent/FeesAndDocuments";
import MissionAndVision from "./pages/BasicContent/MissionAndVision";
import CampRules from "./pages/BasicContent/CampRules";
import CampProgram from "./pages/BasicContent/CampProgram";
import Terms from "./pages/BasicContent/DataSecurity";
import Trainings from "./pages/BasicContent/Trainings";
import SystemLogs from "./pages/SystemLogs/SystemLogs";
import { Shop } from "./pages/Shop/Shop";
import { ProductDetails } from "./pages/ProductDetails/ProductDetails";
import { MyOrders } from "./pages/MyOrders/MyOrders";
import { Items } from "./pages/Items/Items";
import { ShopReports } from "./pages/Reports/ShopReports";

export let errorRef = null;

function App() {
  const { colorScheme, setColorScheme } = React.useContext(ThemeContext);
  const ref = useRef();

  useEffect(() => {
    errorRef = ref;
  }, [ref]);

  return (
    <React.Fragment>
      <Toast ref={ref} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/"
          element={
            <MenuLayout
              setColorScheme={setColorScheme}
              colorScheme={colorScheme}
            />
          }
        >
          <Route path="/" element={<Registration />}>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/change-password" element={<UserChangePassword />} />
            <Route
              path="/users/:resetCode/forgot-password"
              element={<UserForgotPassword />}
            />
            <Route
              path="/registration/step1"
              element={
                <GuardedRouteStepOne redirectTo="/registration/step4">
                  <StepOne />
                </GuardedRouteStepOne>
              }
            />
            <Route
              path="/registration/step2"
              element={
                <GuardedRouteStepTwo redirectTo="/registration/step1">
                  <StepTwo />
                </GuardedRouteStepTwo>
              }
            />
            <Route
              path="/registration/step3"
              element={
                <GuardedRouteStepThree redirectTo="/registration/step2">
                  <StepThree />
                </GuardedRouteStepThree>
              }
            />
            <Route
              path="/registration/step4"
              element={
                <GuardedRouteStepFour redirectTo="/registration/step3">
                  <StepFour />
                </GuardedRouteStepFour>
              }
            />
          </Route>
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/cart"
            element={
              <ProtectedRoute>
                <MyOrders />
              </ProtectedRoute>
            }
          />
          <Route
            path="/products/:id"
            element={
              <ProtectedRoute>
                <ProductDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/shop"
            element={
              <ProtectedRoute>
                <Shop />
              </ProtectedRoute>
            }
          />
          <Route
            path="/items"
            element={
              <ProtectedRoute permission={["Pages.Items.Admin"]}>
                <Items />
              </ProtectedRoute>
            }
          />
          <Route
            path="/users"
            element={
              <ProtectedRoute permission={["Pages.Users"]}>
                <Users />
              </ProtectedRoute>
            }
          />
          <Route
            path="/system-logs"
            element={
              <ProtectedRoute permission={["Pages.Users"]}>
                <SystemLogs />
              </ProtectedRoute>
            }
          />
          <Route
            path="/tenants"
            element={
              <ProtectedRoute permission={["Pages.Tenants"]}>
                <Tenants />
              </ProtectedRoute>
            }
          />
          <Route
            path="/roles"
            element={
              <ProtectedRoute permission={["Pages.Roles"]}>
                <Roles />
              </ProtectedRoute>
            }
          />
          <Route
            path="/events"
            element={
              <ProtectedRoute permission={["Pages.Events"]}>
                <Events />
              </ProtectedRoute>
            }
          />
          <Route
            path="/registrations"
            element={
              <ProtectedRoute
                permission={[
                  "Pages.Registrations",
                  "Pages.Registration.GroupLeader",
                  "Pages.Registration.Person",
                ]}
              >
                <RegistrationTable />
              </ProtectedRoute>
            }
          />
          <Route
            path="/registrations/:id/details"
            element={
              <ProtectedRoute permission={["Pages.Registration.Details"]}>
                <RegistrationDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/registrations/:id/edit"
            element={
              <ProtectedRoute>
                <RegistrationEdit />
              </ProtectedRoute>
            }
          />
          <Route
            path="/payments-report"
            element={
              <ProtectedRoute permission={["Pages.Payments.User.Report"]}>
                <PaymentsReport />
              </ProtectedRoute>
            }
          />
          <Route
            path="/shop-report"
            element={
              <ProtectedRoute permission={["Pages.Registrations.Items"]}>
                <ShopReports />
              </ProtectedRoute>
            }
          />
          <Route
            path="/participants-report"
            element={
              <ProtectedRoute permission={["Pages.Payments.Pay"]}>
                <ParticipantsReport />
              </ProtectedRoute>
            }
          />
          <Route
            path="/process-notes"
            element={
              <ProtectedRoute permission={["Pages.Payments.Pay"]}>
                <ProcessNotes />
              </ProtectedRoute>
            }
          />
          <Route path="/brother-support" element={<BrotherSupport />} />
          <Route path="/conditions" element={<Conditions />} />
          <Route path="/fees-and-documents" element={<FeesAndDocuments />} />
          <Route path="/camp-program" element={<CampProgram />} />
          <Route path="/mission-and-vision" element={<MissionAndVision />} />
          <Route path="/camp-rules" element={<CampRules />} />
          <Route path="/data-security" element={<Terms />} />
          <Route path="/trainings" element={<Trainings />} />
        </Route>
        <Route path="/error" element={<Error colorScheme={colorScheme} />} />
        <Route path="/access" element={<Access colorScheme={colorScheme} />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </React.Fragment>
  );
}

export default App;
