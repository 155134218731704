import http from "../httpService";

class FilesService {
  async delete(entityDto) {
    let result = await http.delete("api/services/app/Files/Delete", {
      params: entityDto,
    });
    return result.data;
  }

  async getFile(entityDto) {
    let result = await http.get("api/services/app/Files/GetFile", {
      params: entityDto,
    });
    return result.data.result;
  }

  async getAllFiles(pagedFilterAndSortedRequest) {
    let result = await http.get("api/services/app/Files/GetFiles", {
      params: pagedFilterAndSortedRequest,
    });
    return result.data.result;
  }

  async uploadFile(file, fileName, id) {
    const formData = new FormData();
    formData.append("file", file, fileName);
    formData.append("id", id);
    let result = await http.post("api/services/app/Files/Upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return result.data.result;
  }
}

export default new FilesService();
