import storageService from "./storageService";

class UserStorageService {
  getUser() {
    const user = {
      id: storageService.getItem("id"),
      isDraft: storageService.getItem("isDraft"),
      firstName: storageService.getItem("firstName"),
      lastName: storageService.getItem("lastName"),
      birthDate: storageService.getItem("birthDate")
        ? new Date(storageService.getItem("birthDate"))
        : null,
      city: storageService.getItem("city"),
      middleName: storageService.getItem("middleName") || undefined,
      ucn: storageService.getItem("ucn") || undefined,
      address: storageService.getItem("address") || undefined,
      licensePlate: storageService.getItem("licensePlate"),
      phoneNumber: storageService.getItem("phoneNumber"),
      isGroupLeader: storageService.getItem("isGroupLeader") || false,
      assignToGroup: storageService.getItem("assignToGroup"),
      personActivities: JSON.parse(storageService.getItem("activities")),
      days: JSON.parse(storageService.getItem("days")),
      additionalInfo: storageService.getItem("additionalInfo"),
      church: storageService.getItem("church"),
      tentNumber: storageService.getItem("tentNumber"),
      canHaveGroup: storageService.getItem("canHaveGroup"),
      totalDue: storageService.getItem("totalDue"),
      qrCodeImage: storageService.getItem("qrCodeImage"),
      group: storageService.getItem("group"),
    };
    return user;
  }
  resetUser() {
    const fields = [
      "isDraft",
      "firstName",
      "id",
      "lastName",
      "birthDate",
      "city",
      "church",
      "middleName",
      "ucn",
      "address",
      "licensePlate",
      "phoneNumber",
      "isGroupLeader",
      "assignToGroup",
      "personActivities",
      "personPresences",
      "days",
      "hasAccommodation",
      "activities",
      "additionalInfo",
      "tentNumber",
      "canHaveGroup",
      "totalDue",
      "qrCodeImage",
      "group",
    ];
    fields.forEach((i) => storageService.removeItem(i));
  }
}

export default new UserStorageService();
