import React, { useState, useEffect, useContext } from "react";
import Table from "../../components/crud/Table";
import { RolesContext } from "../../context/Roles/rolesContext";
import { L } from "../../lib/abpUtility";

function Roles() {
  const [roles, setRoles] = useState();
  const [loading, setLoading] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const rolesContext = useContext(RolesContext);
  const [permissions, setPermissions] = useState([]);

  const editableFields = [
    { name: "name", type: "string" },
    { name: "displayName", type: "string" },
    { name: "normalizedName", type: "string" },
    { name: "description", type: "string" },
    {
      name: "grantedPermissions",
      type: "list_box",
      options: permissions,
      valueField: "name",
      labelField: "name",
    },
  ];

  const createFields = [
    { name: "name", type: "string" },
    { name: "displayName", type: "string" },
    { name: "normalizedName", type: "string" },
    { name: "description", type: "string" },
    {
      name: "grantedPermissions",
      type: "list_box",
      options: permissions,
      valueField: "name",
      labelField: "name",
    },
  ];

  const getAll = () => {
    setLoading(true)
    rolesContext.getAll().then((res) => {
      rolesContext.getAllPermissions().then((permissions_from_server) => {
        setPermissions(permissions_from_server);
        setRoles(res.items);
        setLoading(false);
      });
    });
  }

  useEffect(() => {
    getAll()
  }, [rolesContext]);  // eslint-disable-line react-hooks/exhaustive-deps

  const showFields = [
    { field: "name", header: L("RoleName"), sortable: true, type: "string" },
    {
      field: "displayName",
      header: L("DisplayRoleName"),
      sortable: false,
      type: "string",
    },
    {
      field: "normalizedName",
      header: L("NormalizedRoleName"),
      sortable: false,
      type: "string",
    },
  ];

  // Create user function that is passed to the dialog
  const createRole = (data) => {
    rolesContext.create(data).then((res) => {
      setRoles([...roles, res]);
      setShowDialog(false);
    });
  };

  // The edit user function that is passed to the dialog

  const editRole = (data, selectedData) => {
    for (let item in data) {
      selectedData[item] = data[item];
    }
    rolesContext.update(selectedData).then((res) => {
      setRoles(roles.map((role) => (role.id === res.id ? res : role)));
      setShowDialog(false);
      getAll()
    });
  };

  const deleteRole = (data) => {
    rolesContext.deleteRole({ id: data?.id }).then((res) => {
      setRoles(roles.filter((role) => role.id !== data.id));
      setShowDialog(false);
    });
  };

  return (
    <div>
      <Table
        fields={showFields}
        data={roles}
        loading={loading}
        headerText= {L("Manage") + L("Roles")}
        toolbar={true}
        createFields={createFields}
        editableFields={editableFields}
        onCreate={createRole}
        onEdit={editRole}
        deleteText={L("ConfirmDeletionRole")}
        onDelete={deleteRole}
        setShowDialog={setShowDialog}
        showDialog={showDialog}
        dialogHeaderText={{
          edit: L("EditRole"),
          create: L("CreateRole"),
        }}
      />
    </div>
  );
}

export default Roles;
