import React from "react";
import tokenAuthService from "../../services/tokenAuth/tokenAuthService";
import AppConsts from "../../lib/appconst";
import accountService from "../../services/account/accountService";

var abp = window.abp;

export const AuthenticationContext = React.createContext();

function isAuthenticated() {
  if (!abp.session.userId) return false;

  return true;
}

async function login(model) {
  model.rememberMe = true;
  let result = await tokenAuthService.authenticate({
    userNameOrEmailAddress: model.userNameOrEmailAddress,
    password: model.password,
    rememberClient: model.rememberMe,
  });

  var tokenExpireDate = model.rememberMe
    ? new Date(new Date().getTime() + 1000 * result.expireInSeconds)
    : undefined;
  abp.auth.setToken(result.accessToken, tokenExpireDate);
  abp.utils.setCookieValue(
    AppConsts.authorization.encrptedAuthTokenName,
    result.encryptedAccessToken,
    tokenExpireDate,
    abp.appPath
  );
  return result;
}

async function register(model) {
  model.captchaResponse = "true";
  let result = await accountService.register({
    emailAddress: model.emailAddress,
    password: model.password,
    captchaResponse: model.captchaResponse,
  });

  if (result.canLogin) {
    let login = await tokenAuthService.authenticate({
      userNameOrEmailAddress: model.emailAddress,
      password: model.password,
      rememberClient: true,
    });

    var tokenExpireDate = true
      ? new Date(new Date().getTime() + 1000 * login.expireInSeconds)
      : undefined;
    abp.auth.setToken(login.accessToken, tokenExpireDate);
    abp.utils.setCookieValue(
      AppConsts.authorization.encrptedAuthTokenName,
      login.encryptedAccessToken,
      tokenExpireDate,
      abp.appPath
    );
  }
}

function logout() {
  abp.auth.clearToken();
  window.location.reload();
}

export const authenticationValues = {
  isAuthenticated,
  login,
  logout,
  register,
};
