import { InputText } from "primereact/inputtext";
import React, { memo } from "react";
import { Password } from "primereact/password";
import { Controller, useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { ListBox } from "primereact/listbox";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";

function CrudDialog({
  state,
  stateFields,
  selectedData,
  headerText,
  onSubmit,
}) {
  const [data] = React.useState(selectedData);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  return (
    <div>
      <h2>{headerText[state]}</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        {stateFields?.map((field) => (
          <div key={field?.name} style={{ margin: "15px 0" }}>
            {getField(field, state, data, register, control, errors)}
          </div>
        ))}
        <br />
        <Button label="Save" type="submit" />
      </form>
    </div>
  );
}

// The function that returns the input component based on the field type and a dialog type

const getField = (field, state, selectedData, register, control, errors) => {
  switch (state) {
    case "edit":
      switch (field?.type) {
        case "dropdown":
          return (
            <>
              <Controller
                name={field.name}
                control={control}
                rules={{ required: true }}
                render={(props) => (
                  <Dropdown
                    optionLabel="label"
                    optionValue="value"
                    className={errors[field.name] && "p-invalid"}
                    value={props.field.value}
                    onChange={(e) => props.field.onChange(e.value)}
                    options={field.options}
                  />
                )}
              />
              <p
                style={{
                  color: "red",
                  paddingTop: "20px !important",
                  fontStyle: "italic",
                }}
              >
                {errors[field.name] &&
                  errors[field.name].type === "required" &&
                  `${field.name} is required`}
              </p>
            </>
          );
        case "string":
          return (
            <>
              <InputText
                id={field?.name}
                className={errors[field.name] && "p-invalid"}
                placeholder={field?.name}
                defaultValue={selectedData?.[field?.name]?.toString() || ""}
                {...register(field.name, { required: true })}
              />
              <p
                style={{
                  color: "red",
                  paddingTop: "20px !important",
                  fontStyle: "italic",
                }}
              >
                {errors[field.name] &&
                  errors[field.name].type === "required" &&
                  `${field.name} is required`}
              </p>
            </>
          );
        case "startEndDate":
          return (
            <>
              <Controller
                name={field.name}
                defaultValue={[
                  new Date(selectedData?.["startDate"]),
                  new Date(selectedData?.["endDate"]),
                ]}
                control={control}
                rules={{ required: true }}
                render={(props) => (
                  <Calendar
                    id="range"
                    selectionMode="range"
                    dateFormat="dd/mm/yy"
                    placeholder={field.name}
                    onChange={(e) => props.field.onChange(e.value)}
                    value={props.field.value}
                    readOnlyInput
                  />
                )}
              />
            </>
          );
        case "date":
          return (
            <>
              <Controller
                name={field.name}
                defaultValue={new Date(selectedData?.[field.name])}
                control={control}
                rules={{ required: true }}
                render={(props) => (
                  <Calendar
                    id="range"
                    dateFormat="dd/mm/yy"
                    placeholder={field.name}
                    onChange={(e) => props.field.onChange(e.value)}
                    value={props.field.value}
                    readOnlyInput
                  />
                )}
              />
            </>
          );
        case "bool":
          return (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <h5 style={{ marginRight: "10px", fontWeight: "400" }}>
                {field.label}
              </h5>
              <Controller
                name={field.name}
                defaultValue={Boolean(selectedData?.[field?.name]) || false}
                control={control}
                render={(props) => (
                  <InputSwitch
                    checked={props.field.value}
                    onChange={(e) => props.field.onChange(e.value)}
                    {...props.field}
                  />
                )}
              />
            </div>
          );
        case "list_box":
          return (
            <div>
              <Controller
                name={field.name}
                defaultValue={selectedData?.[field?.name]}
                control={control}
                render={(props) => (
                  <ListBox
                    value={props.field.value}
                    options={field.options}
                    optionLabel={field.labelField}
                    filter={true}
                    optionValue={field.valueField}
                    multiple={true}
                    onChange={(e) => props.field.onChange(e.value)}
                    {...props.field}
                  />
                )}
              />
            </div>
          );
        default:
          return <></>;
      }
    case "create":
      switch (field?.type) {
        case "string":
          return (
            <>
              <InputText
                id={field.name}
                className={errors[field.name] && "p-invalid"}
                placeholder={field.name}
                {...register(field.name, { required: true })}
              />
              <p
                style={{
                  color: "red",
                  paddingTop: "20px !important",
                  fontStyle: "italic",
                }}
              >
                {errors[field.name] &&
                  errors[field.name].type === "required" &&
                  `${field.name} is required`}
              </p>
            </>
          );
        case "startEndDate":
          return (
            <>
              <Controller
                name={field.name}
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={(props) => (
                  <Calendar
                    id="range"
                    className={errors[field.name] && "p-invalid"}
                    selectionMode="range"
                    dateFormat="dd/mm/yy"
                    placeholder={field.name}
                    onChange={(e) => props.field.onChange(e.value)}
                    value={props.field.value}
                    readOnlyInput
                  />
                )}
              />
              <p
                style={{
                  color: "red",
                  paddingTop: "20px !important",
                  fontStyle: "italic",
                }}
              >
                {errors[field.name] &&
                  errors[field.name].type === "required" &&
                  `${field.name} is required`}
              </p>
            </>
          );
        case "password":
          return (
            <Controller
              name="password"
              defaultValue=""
              control={control}
              rules={{ required: true }}
              render={(props) => (
                <Password
                  id={field.name}
                  placeholder={field.name}
                  {...props.field}
                />
              )}
            />
          );
        case "list_box":
          return (
            <div>
              <Controller
                name={field.name}
                control={control}
                render={(props) => (
                  <ListBox
                    value={props.field.value}
                    options={field.options}
                    optionLabel={field.labelField}
                    optionValue={field.valueField}
                    multiple={true}
                    filter={true}
                    onChange={(e) => props.field.onChange(e.value)}
                    {...props.field}
                  />
                )}
              />
            </div>
          );
        case "bool":
          return (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <h5 style={{ marginRight: "10px", fontWeight: "400" }}>
                {field.label}
              </h5>
              <Controller
                name={field.name}
                defaultValue={false}
                control={control}
                render={(props) => (
                  <InputSwitch
                    checked={props.field.value}
                    onChange={(e) => props.field.onChange(e.value)}
                    {...props.field}
                  />
                )}
              />
            </div>
          );
        default:
          return <></>;
      }
    default:
      return <></>;
  }
};

export default memo(CrudDialog);
