import React, { useContext, useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { AuthenticationContext } from "../../context/Authentication/authenticationContext";
import { ProgressSpinner } from "primereact/progressspinner";
import { Link } from "react-router-dom";
import { L } from "../../lib/abpUtility";

function Register() {
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    watch(() => {
      setError("");
    });
  }, [watch]);

  const authenticationContext = useContext(AuthenticationContext);
  const onSubmit = async (data) => {
    if (data.password === data.confirmPassword) {
      setLoading(true);
      await authenticationContext
        .register(data)
        .then((res) => window.location.replace("/"))
        .catch((e) => {
          setError(L("RegisterFailed"));
          setLoading(false);
        });
    } else {
      setError(L("PassChangeError"));
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={`service-div`}>
        {!loading ? (
          <React.Fragment>
            <h3>{L("SignUp")}:</h3>
            {error && <div className="error-message">{error}</div>}

            <InputText
              placeholder={L("Email")}
              style={
                errors["emailAddress"] && {
                  border: "1px solid red",
                }
              }
              {...register("emailAddress", { required: true })}
            />
            <Controller
              name="password"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <Password
                  {...field}
                  placeholder={L("Password")}
                  feedback={false}
                  inputClassName={errors["password"] && "p-invalid"}
                />
              )}
            />
            <Controller
              name="confirmPassword"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <Password
                  {...field}
                  placeholder={L("ConfirmPassword")}
                  feedback={false}
                  inputClassName={errors["confirmPassword"] && "p-invalid"}
                />
              )}
            />
            <Button label={L("Create")} type="submit"></Button>
            <p>
              {L("HaveAnAccount")}?{" "}
              <button type="button" className="p-link">
                <Link to={"/login"}>{L("SignIn")}</Link>
              </button>
            </p>
          </React.Fragment>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ProgressSpinner />
          </div>
        )}
      </div>
    </form>
  );
}

export default Register;
