import http from "../httpService";

class ReportsService {
   async getAllRegistrationsWithItems(pagedFilterAndSortedRequest) {
    let result = await http.get("api/services/app/Reports/GetRegistationsWithItems", {
      params: pagedFilterAndSortedRequest,
    });
    return result.data.result;
  }
}

export default new ReportsService();
