import React from "react";
import { useContext } from "react";
import { AuthenticationContext } from "../../context/Authentication/authenticationContext";
import userStorageService from "../../services/storage/userStorageService";

function Logout({ children }) {
  const authenticationContext = useContext(AuthenticationContext);
  const { logout, isAuthenticated } = authenticationContext;
  const logUserOut = () => {
    logout();
    userStorageService.resetUser();
    window.location.replace("/");
  };
  return isAuthenticated() && <div onClick={logUserOut}>{children}</div>;
}

export default Logout;
