/**
 * The `composeShopImageName` function takes an item ID, gender, and color as input and returns a
 * formatted string combining these values.
 * @param itemId - Thank you for providing the `composeShopImageName` function. Could you please
 * provide me with the `itemId` value so that I can assist you further in composing the shop image
 * name?
 * @param gender - Gender refers to the classification of individuals as male, female, or other
 * categories based on social and cultural differences rather than biological ones.
 * @param color - Color refers to the specific color of the item, such as "red", "blue", "green", etc.
 * @returns The function `composeShopImageName` returns a string that concatenates the `itemId`,
 * `gender` (converted to uppercase), and `color` with underscores in between.
 */
export const composeShopImageName = (itemId, gender, color) => {
  if (!itemId || !gender || !color) {
    return "";
  }

  return `${itemId}_${gender.toUpperCase()}_${color}`;
};

export const composeShopImageNameWithId = (itemId, gender, color, id) => {
  return `${composeShopImageName(itemId, gender, color)}_${id}`;
};
