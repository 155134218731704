import { useQuery } from "react-query";
import abpUserConfigurationService from "./services/abpUserConfigurationService";
import utils from "./utils/utils";

let abp = window.abp;

async function fetchDataConfigurations() {
  let configurations = await abpUserConfigurationService.getAll();

  if (!abp.utils.getCookieValue("Abp.Localization.CultureName")) {
    abp.utils.setCookieValue(
      "Abp.Localization.CultureName",
      "bg-BG",
      new Date(new Date().getTime() + 5 * 365 * 86400000),
      abp.appPath
    );
  }

  abp.clock.provider = utils.getCurrentClockProvider(
    configurations.data.result.clock.provider
  );
  utils.extend(true, abp, configurations.data.result);

  return {
    data: configurations,
  };
}

function useConfigurations() {
  return useQuery(["configurations"], fetchDataConfigurations, {
    retry: true,
    retryDelay: 1000,
  });
}

export { useConfigurations };
