import React from "react";
import { Navigate } from "react-router-dom";
import storageService from "../../services/storage/storageService";

function GuardedRouteStepThree({ redirectTo, children }) {
  return (
    <div>
      {storageService.getItem("firstName") &&
      storageService.getItem("lastName") &&
      storageService.getItem("birthDate") &&
      storageService.getItem("city") ? (
        children
      ) : (
        <Navigate to={redirectTo} />
      )}
    </div>
  );
}

export default GuardedRouteStepThree;
