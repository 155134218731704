import React, { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { EventContext } from "../../context/Event/eventContext";
import storageService from "../../services/storage/storageService";

function GuardedRouteStepOne({ redirectTo, children }) {
  const [loading, setLoading] = useState(true);
  const { lastEvent } = useContext(EventContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (lastEvent) {
      if (lastEvent?.eventState.value === 2) {
        setLoading(false);
      } else {
        navigate("/");
      }
    }
  }, [lastEvent, navigate]);

  return !loading ? (
    <div>
      {!storageService.getItem("id") ? (
        <React.Fragment>{children}</React.Fragment>
      ) : (
        <Navigate to={redirectTo} />
      )}
    </div>
  ) : (
    <></>
  );
}

export default GuardedRouteStepOne;
