import React, { useState, useEffect, useContext } from "react";
import Table from "../../components/crud/Table";
import { TenantContext } from "../../context/Tenants/tenantsContext";
import { L } from "../../lib/abpUtility";

function Tenants() {
  const [tenants, setTenants] = useState();
  const [loading, setLoading] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const tenantContext = useContext(TenantContext);

  const editableFields = [
    { name: "tenancyName", type: "string" },
    { name: "name", type: "string" },
    { name: "adminEmailAddress", type: "string" },
    { name: "isActive", type: "bool", label: L("IsActive") },
  ];

  const createFields = [
    { name: "name", type: "string" },
    { name: "tenancyName", type: "string" },
    { name: "adminEmailAddress", type: "string" },
    { name: "isActive", type: "bool", label: L("IsActive") },
  ];

  useEffect(() => {
    tenantContext.getAll().then((res) => {
      setTenants(res.items);
      setLoading(false);
    });
  }, [tenantContext]);

  const showFields = [
    { field: "name", header: L("TenantName"), sortable: true, type: "string" },
    {
      field: "tenancyName",
      header:  L("TenancyName"),
      sortable: false,
      type: "string",
    },
  ];

  // Create user function that is passed to the dialog
  const createTenant = (data) => {
    tenantContext.create(data).then((res) => {
      setTenants([...tenants, res]);
      setShowDialog(false);
    });
  };

  // The edit user function that is passed to the dialog

  const editTenant = (data, selectedData) => {
    for (let item in data) {
      selectedData[item] = data[item];
    }
    tenantContext.update(selectedData).then((res) => {
      setTenants(
        tenants.map((tenant) => (tenant.id === res.id ? res : tenant))
      );
      setShowDialog(false);
    });
  };

  const deleteTenant = (data) => {
    tenantContext.deleteTenant({ id: data?.id }).then((res) => {
      setTenants(tenants.filter((tenant) => tenant.id !== data.id));
      setShowDialog(false);
    });
  };

  return (
    <div>
      <Table
        fields={showFields}
        data={tenants}
        loading={loading}
        headerText= {L("Manage") + L("Tenants")}
        toolbar={true}
        createFields={createFields}
        editableFields={editableFields}
        onCreate={createTenant}
        onEdit={editTenant}
        deleteText={L("ConfirmDeletionTenant")}
        onDelete={deleteTenant}
        setShowDialog={setShowDialog}
        showDialog={showDialog}
        dialogHeaderText={{
          edit: L("EditTenant"),
          create: L("CreateTenant"),
        }}
      />
    </div>
  );
}

export default Tenants;
