import "./shop.scss";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Skeleton } from "primereact/skeleton";
import { useQuery } from "react-query";
import shopService from "../../services/shop/shopService";
import { useEffect, useState } from "react";
import { Message } from "primereact/message";
import registrationService from "../../services/registration/registrationService";
import { L, capitalizeFirstLetter } from "../../lib/abpUtility";
import localizationService from "../../services/localization/localizationService";

export const mappedColors = {
  male: "success",
  female: "danger",
};

export const Shop = () => {
  let [searchParams] = useSearchParams();
  const registrationId = searchParams.get("registrationId");
  const { data: registration } = useQuery({
    queryKey: ["registration", registrationId],
    queryFn: () => registrationService.get(registrationId),
    enabled: !!registrationId,
  });
  const { data } = useQuery({
    queryKey: ["shopItems"],
    queryFn: () => shopService.getAll(),
  });
  return (
    <div className="card">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1>{L("Shop")}</h1>
        {registrationId && registration && (
          <Message
            severity="info"
            text={`${L('CurrentlyShopingFor')} ${registration?.firstName} ${registration?.lastName}`}
            style={{ width: "300px" }}
          />
        )}
      </div>
      <br />
      <div className="products">
        {data?.items.map((product) => (
          <RenderSingleProduct
            key={product.id}
            product={product}
            registrationId={registrationId}
          />
        ))}
      </div>
    </div>
  );
};

const RenderSingleProduct = ({ product, registrationId }) => {
  const navigate = useNavigate();

  const onNavigateBtnClicked = () => {
    let link = "/products/" + product.id;
    if (registrationId?.length >= 5) {
      link += `?registrationId=` + registrationId;
    }
    navigate(link);
  };

  return (
    <div className="shop-product">
      <div className="top">
        <div className="category">
          <i className="pi pi-tag"></i>
          <p>{L("ShopCardType" + capitalizeFirstLetter(product.type))}</p>
        </div>
        <Tag
          value={`${product.price} лв.`}
          severity={mappedColors?.[product.gender] || "info"}
        ></Tag>
      </div>
      <div className="image">
        {product.images?.[0]?.url ? (
          <RenderProductImage images={product.images} />
        ) : (
          <Skeleton height="100%" />
        )}
      </div>
      <div className="text-info">
        <p className="title">
          {localizationService.localizePipe(product.specifications.title)}
        </p>
        <p className="description">
          {localizationService.localizePipe(product.specifications.description)}
        </p>
      </div>
      <div className="bottom">
        <Button
          label={L("ItemDetails")}
          className="p-button-info"
          onClick={() => onNavigateBtnClicked()}
        />
      </div>
    </div>
  );
};

export const RenderProductImage = ({ images, ms = 5000 }) => {
  const [idx, setIdx] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIdx((idx) => (idx + 1) % images.length);
    }, ms);

    return () => {
      clearInterval(interval);
    };
  }, [images]);
  return <img src={images?.[idx]?.url} alt="product" />;
};
