import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import forgotPasswordService from "../../services/fogot-password/forgot-password-service";
import { errorRef } from "../../App";
import { useNavigate } from "react-router-dom";
import { L } from "../../lib/abpUtility";

function ForgotPassword() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const onSubmit = async (data) => {
    setLoading(true);
    forgotPasswordService.requestForgotPassword(data.username).then((res) => {
      errorRef.current.show({
        severity: "success",
        summary: L("Success"),
        detail: L("RenewPassEmailSuccess"),
        life: 10000,
      });
      history("/");
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={`service-div`}>
        <h2>{L("ForgotPassword")}?</h2>
        <p style={{ margin: "0px 0px 14px 0px" }}>{L("ResetPassword")}!</p>
        <InputText
          placeholder={L("Email")}
          disabled={loading}
          style={
            errors["username"] && {
              border: "1px solid red",
            }
          }
          {...register("username", { required: true })}
        />

        <Button
          label={L("ForgotPassword")}
          type="submit"
          disabled={loading}
        ></Button>
      </div>
    </form>
  );
}

export default ForgotPassword;
