import React from "react";
import { Link } from "react-router-dom";
import { L } from "../../lib/abpUtility";

const SubMenu = () => {
  return (
    <div
      className="grid"
      style={{
        display: "flex",
        gap: "10px",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
      }}
    >
          <div>
        <Link to="/camp-program">
          <span
            className="p-3 shadow-2 mb-3 inline-block surface-card"
            style={{ borderRadius: "10px" }}
          >
            <i className="pi pi-clock text-4xl text-primary " title={L("Program")}></i>
            
          </span>
        </Link>
      </div>
      <div>
        <Link to="/mission-and-vision">
          <span
            className="p-3 shadow-2 mb-3 inline-block surface-card"
            style={{ borderRadius: "10px" }}
          >
            <i className="pi pi-heart-fill text-4xl text-primary" title={L("MissionAndVision")}></i>
          </span>
        </Link>
      </div>
      <div>
        <Link to="/conditions">
          <span
            className="p-3 shadow-2 mb-3 inline-block surface-card"
            style={{ borderRadius: "10px" }}
          >
            <i className="pi pi-check-circle text-4xl text-primary" title={L("Conditions")}></i>
          </span>
        </Link>
      </div>
      <div>
        <Link to="/camp-rules">
          <span
            className="p-3 shadow-2 mb-3 inline-block surface-card"
            style={{ borderRadius: "10px" }}
          >
            <i className="pi pi-book text-4xl text-primary" title={L("CampRules")}></i>
          </span>
        </Link>
      </div>
      <div>
        <Link to="/fees-and-documents">
          <span
            className="p-3 shadow-2 mb-3 inline-block surface-card"
            style={{ borderRadius: "10px" }}
          >
            <i className="pi pi-briefcase text-4xl text-primary" title={L("FeesAndDocuments")}></i>
          </span>
        </Link>
      </div>
      <div>
        <Link to="/brother-support">
          <span
            className="p-3 shadow-2 mb-3 inline-block surface-card"
            style={{ borderRadius: "10px" }}
          >
            <i className="pi pi-users text-4xl text-primary" title={L("BrotherSupport")}></i>
          </span>
        </Link>
      </div>
      <div>
        <Link to="/data-security">
          <span
            className="p-3 shadow-2 mb-3 inline-block surface-card"
            style={{ borderRadius: "10px" }}
          >
            <i className="pi pi-shield text-4xl text-primary" title={L("DataSecurity")}></i>
          </span>
        </Link>
      </div>

      <div>
        <Link to="/trainings">
          <span
            className="p-3 shadow-2 mb-3 inline-block surface-card"
            style={{ borderRadius: "10px" }}
          >
            <i className="pi pi-video text-4xl text-primary" title={L("Trainings")}></i>
          </span>
        </Link>
      </div>
    </div>
  );
};

export default SubMenu;

